import React from 'react';

import './driver-intro.scss';

type section = 'Purpose' | 'Power' | 'Practice';

interface DriverSectionIntroProps {
  title: section;
  subtitle: string;
  description: string;
}

const sections : section[] = ['Purpose', 'Power', 'Practice'];

const DriverSectionIntro = (
  { title, subtitle, description }: DriverSectionIntroProps,
) => (
  <div className="driver-intro">
    <div className={`driver-intro-title-wrapper driver-intro-title-wrapper-${
      title.toLowerCase()}`}
    >
      <div
        className={`driver-intro-propeller driver-intro-propeller-${
          title.toLowerCase()}`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 158 91"
        >
          <path fill="#0086B5" d="M1 91h157s3-50-34-91C111-2 47 22 1 67v24Z" />
        </svg>
        Driver
        {' '}
        {sections.indexOf(title) + 1}
      </div>
      <h2 className="driver-intro-title">{title}</h2>
    </div>
    <div className="driver-intro-subtitle">
      <span className="driver-intro-subtitle-inner">{subtitle}</span>
    </div>
    <div className="driver-intro-description">
      {description}
    </div>
  </div>
);

export default DriverSectionIntro;
