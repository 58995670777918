import React, { useEffect, useState } from 'react';
import MainTitle from '../main-title';

import './splash-page.scss';

const SplashPage = () => {
  const [isStarted, setIsStarted] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsStarted(true), 0);
  }, []);
  return (
    <div className={`splash-page${isStarted ? ' is-started' : ''}`}>
      <h1 className="sr-only">A New Education Story</h1>
      <MainTitle />
      <h2 className="splash-page-heading">
        Three drivers to transform education systems
      </h2>
      <div className="splash-page-blades">
        <svg
          viewBox="0 0 122.7 57.3"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#0086b5"
            d="M96.6 153.5s-31 15.4-43.1 51.9c.5 1.3 1.6 3 3.4 5h72.6z"
            transform="translate(-53.5 -153.2)"
          />
          <path
            fill="#ff8001"
            d="M138.2 157.3c-7 0-36.6 1-61.8 23.5-.7 4.2 2.3 15.6 8.7
               29.7h54.7v-53.1h-1.6z"
            transform="translate(-53.5 -153.2)"
          />
          <path
            fill="#82027e"
            d="M14.9 307c-15.3-.3-31.3-1.9-48-5.4C-41.8 291.4-51.2 254-55
               204h172.7l37.7 65.3S97.2 308.1 14.9 307z"
            transform="matrix(.35278 0 0 -.35278 67.8 129.3)"
          />
        </svg>
      </div>
    </div>
  );
};

export default SplashPage;
