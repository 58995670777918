import React from 'react';
import useIsRevealed from '../../utils/use-is-revealed';

import './meadow.scss';

const MeadowGraphic = () => {
  const [ref, isRevealed] = useIsRevealed({ threshold: 0.9 });
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 421 254.7"
      className={`meadow-graphic${isRevealed ? ' is-visible' : ''}`}
    >
      {' '}
      <g className="meadow-the-system">
        <circle cx="35.4" cy="207.6" r="24.7" className="st0" />
        <text transform="translate(27.1 204)">
          <tspan x="0" y="0" className="st1 st2 st3">The </tspan>
          <tspan x="-8.6" y="11.6" className="st1 st2 st3">System</tspan>
        </text>
      </g>
      <path d="m73.3 218-14.2 24.6c-1.3 2.3.3 5 3 5h28.4c2.5 0 4.1-2.7 2.9-5L79 218a3.3 3.3 0 0 0-5.8 0z" className="st4" />
      <circle cx="93.5" cy="205.5" r="4.1" className="st1" />
      <circle cx="111.8" cy="194.9" r="4.1" className="st1" />
      <circle cx="130.2" cy="184.3" r="4.1" className="st1" />
      <circle cx="148.6" cy="173.7" r="4.1" className="st1" />
      <circle cx="167" cy="163.1" r="4.1" className="st1" />
      <circle cx="185.3" cy="152.5" r="4.1" className="st1" />
      <circle cx="203.7" cy="141.8" r="4.1" className="st1" />
      <circle cx="222.1" cy="131.3" r="4.1" className="st1" />
      <circle cx="240.5" cy="120.6" r="4.1" className="st1" />
      <circle cx="258.8" cy="110" r="4.1" className="st1" />
      <circle cx="277.2" cy="99.4" r="4.1" className="st1" />
      <circle cx="295.6" cy="88.8" r="4.1" className="st1" />
      <g className="meadow-labels">
        <text className="st1 st5 st6" transform="translate(106.5 209.5)">Change measurable parameters</text>
        <text className="st1 st5 st6" transform="translate(124.9 199)">Change level of resources</text>
        <text className="st1 st5 st6" transform="translate(143.2 188.4)">Change flow of resources</text>
        <text className="st1 st5 st6" transform="translate(161.6 177.8)">Change speed of feedback loops</text>
        <text className="st1 st5 st6" transform="translate(180 167.2)">Change negative feedback loops</text>
        <text className="st1 st5 st6" transform="translate(198.3 156.7)">Change positive feedback loops</text>
        <text className="st1 st5 st6" transform="translate(216.7 146.1)">Change access to information</text>
        <text className="st1 st5 st6" transform="translate(235 135.5)">Change the rules governing the system</text>
        <text className="st1 st5 st6" transform="translate(253.5 125)">Change power to design the system</text>
        <text className="st1 st5 st6" transform="translate(271.8 114.4)">Change goals</text>
        <text className="st1 st5 st6" transform="translate(289.5 103.8)">Change public mindset</text>
        <text className="st1 st5 st6" transform="translate(307.7 93.2)">Change ability to transcend paradigms</text>
      </g>
      <path d="M34.4 239.6 310.5 80.2" className="st7" />
      <path d="m84.1 202.5-1.6-2.9 48-27.8 1.7 2.9M106.8 185.5l-4.8-8.3" className="st8" />
      <g className="meadow-groups">
        <text transform="rotate(-30 335.5 -22.8)">
          <tspan x="0" y="0" className="st1 st2 st9">Parameters</tspan>
          <tspan x="22" y="9.6" className="st1 st5 st10">shifts in </tspan>
          <tspan x="14" y="16.2" className="st1 st5 st10">infrastructure, </tspan>
          <tspan x="8" y="22.8" className="st1 st5 st10">metrics, materials</tspan>
        </text>
        <path d="m139.8 170.4-1.7-2.9 48.1-27.8 1.6 2.9M162.4 153.4l-4.8-8.4" className="st8" />
        <text transform="rotate(-30 300 -155.6)">
          <tspan x="4" y="0" className="st1 st2 st9">Feedback</tspan>
          <tspan x="16" y="9.6" className="st1 st5 st10">shifts in </tspan>
          <tspan x="12" y="16.2" className="st1 st5 st10">interactions </tspan>
          <tspan x="6" y="22.8" className="st1 st5 st10">inside systems</tspan>
        </text>
        <path d="m194.9 138.5-1.7-2.8 48.1-27.8 1.7 2.9M217.6 121.6l-4.9-8.4" className="st8" />
        <text transform="rotate(-30 264.6 -287.4)">
          <tspan x="0" y="0" className="st1 st2 st9">Design</tspan>
          <tspan x="8" y="9.6" className="st1 st5 st10">shifts in </tspan>
          <tspan x="4" y="16.2" className="st1 st5 st10">power and </tspan>
          <tspan x="5" y="22.8" className="st1 st5 st10">structures</tspan>
        </text>
        <path d="m250 106.7-1.7-2.9 48.1-27.7 1.7 2.8M272.7 89.8l-4.9-8.4" className="st8" />
        <text transform="rotate(-30 234.8 -398.6)">
          <tspan x="0" y="0" className="st1 st2 st9">Purpose</tspan>
          <tspan x="8.2" y="9.6" className="st1 st5 st10">shifts in </tspan>
          <tspan x="-2.8" y="16.2" className="st1 st5 st10">goals, beliefs, </tspan>
          <tspan x="-7.3" y="22.8" className="st1 st5 st10">values orienting </tspan>
          <tspan x="4" y="29.4" className="st1 st5 st10">the system</tspan>
        </text>
      </g>
      <text className="st11 st5 st6" transform="rotate(-30 299.2 -40.7)">Easier to implement</text>
      <text className="st11 st5 st6" transform="rotate(-30 268.2 -32.4)">Weaker leverage</text>
      <text className="st11 st5 st6" transform="rotate(-30 224 -321.3)">Harder to implement</text>
      <text className="st11 st5 st6" transform="rotate(-30 188.6 -326.2)">Stronger leverage</text>
      <g>
        <path d="M47.1 139.4 262.6 15" className="st8" />
        <path d="m53.2 141.8.3-1.2-6-1.4 1.8-5.9-1.2-.4-2.2 7.2zM261.6 21.5l-1.2-.3 1.8-5.9-6-1.4.3-1.2 7.3 1.6z" className="st11" />
      </g>
    </svg>
  );
};

export default MeadowGraphic;
