import { withPrefix } from 'gatsby';
import React, { useState, useEffect, useRef } from 'react';

import './modal.scss';

interface RevealModalProps {
  lead: string
  title: string
  children: React.ReactNode
}

interface ModalTextProps {
  image?: string
  children: React.ReactNode
}

export const RevealModalText = ({ image, children }: ModalTextProps) => (
  <div className={`reveal-modal-text-main${image ? ' with-image' : ''}`}>
    {image ? (
      <div className="reveal-modal-text-main-image-wrapper">
        <img
          src={withPrefix(image)}
          alt=""
          className="reveal-modal-text-main-image"
        />
      </div>
    ) : '' }
    {children}
  </div>
);

RevealModalText.defaultProps = {
  image: null,
};

const RevealModal = ({ lead, title, children } : RevealModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    document.body.style.overflow = (isOpen) ? 'hidden' : null;
    if (isOpen) {
      ref.current.scrollTop = 0;
    }
  }, [isOpen]);

  return (
    <div
      className={`reveal-modal-wrapper ${isOpen ? ' is-open' : ''}`}
      ref={ref}
    >
      <div
        className="reveal-modal-background-closer"
        onClick={() => setIsOpen(false)}
        aria-hidden
      />
      <section className="reveal-modal">
        <button
          type="button"
          className="reveal-modal-close-button"
          aria-label="Close modal"
          title="Close modal"
          onClick={() => setIsOpen(false)}
        >
          ×
        </button>
        <div className="reveal-modal-inner">
          <header className="reveal-modal-header">
            <p className="reveal-modal-header-lead">{lead}</p>
            <h3 className="reveal-modal-header-title">{title}</h3>
          </header>
          <article className="reveal-modal-content">
            <div className="reveal-modal-text">
              {children}
            </div>
          </article>
          <button
            type="button"
            className="reveal-modal-button"
            onClick={() => setIsOpen(!isOpen)}
          >
            { isOpen ? 'Close' : 'Read More' }
          </button>
        </div>
      </section>
    </div>
  );
};

export default RevealModal;
