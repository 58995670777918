import React from 'react';
import useIsRevealed from '../../utils/use-is-revealed';
import './show.scss';

interface ShowWhenRevealedProps {
  children: React.ReactNode
  threshold?: number
}

const ShowWhenRevealed = ({ children, threshold }: ShowWhenRevealedProps) => {
  const [ref, isRevealed] = useIsRevealed({ threshold });

  return (
    <div className={`revealer${isRevealed ? ' is-visible' : ''}`} ref={ref}>
      {children}
    </div>
  );
};

ShowWhenRevealed.defaultProps = {
  threshold: 0.5,
};

export default ShowWhenRevealed;
