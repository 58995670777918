import React from 'react';
import { withPrefix } from 'gatsby';

import './partners.scss';

interface PartnersDataEntry {
  image: string
  name: string
  bio: React.ReactNode
}

const PARTNERS_DATA: PartnersDataEntry[] = [
  {
    image: 'Annina.jpg',
    name: 'Annina Mattsson',
    bio:
  <>
    is an international development and humanitarian professional with
    more than 15 years of experience in education, humanitarian assistance,
    disaster risk-reduction, conflict prevention, and peace-building. She joined
    Dubai Cares in February 2016 and has since been responsible for leading the
    Programs department. Currently she is the Director of
    {' '}
    <a
      href="https://www.rewired2021.com/"
      target="_blank"
      rel="noreferrer"
    >
      RewirEd

    </a>
    {' '}
    – a Global
    Platform on Education – co-hosted by Dubai Cares and Expo2020, in close
    coordination with the UAE Ministry of Foreign Affairs and International
    Cooperation.

  </>,
  },
  {
    image: 'Andreas.jpg',
    name: 'Andreas Schleicher',
    bio:
  <>
    is Director for Education and Skills at the
    {' '}
    <a
      href="https://www.oecd.org/education/"
      target="_blank"
      rel="noreferrer"
    >
      OECD

    </a>
    . He initiated and
    oversees the Programme for International Student Assessment (PISA) and
    other international instruments that have created a global platform for
    policy-makers, researchers and educators across nations and cultures to
    innovate and transform educational policies and practices.
  </>,
  },
  {
    image: 'Alex.jpg',
    name: 'Alex Beard',
    bio:
  <>
    co-leads
    {' '}
    <a
      href="https://teachforall.org/"
      target="_blank"
      rel="noreferrer"
    >
      Teach For All
    </a>
    ’s Global Learning Lab, which seeks to advance knowledge and learning
    about collective leadership development. He is a former teacher, trustee
    of the Day One Trust, and UK Champion of GPE. Alex also wrote and presented
    The Learning Revolution, a three-part series on the future of education for
    BBC Radio 4 (2020), and is the author of Natural Born Learners (2018), a
    user’s guide to transforming learning in the twenty-first century.
  </>,
  },
  {
    image: 'Caireen.jpg',
    name: 'Caireen Goddard',
    bio:
  <>
    leads
    {' '}
    <a
      href="https://www.big-change.org/"
      target="_blank"
      rel="noreferrer"
    >
      Big Change

    </a>
    ’s global insight network and their system change strategy in the UK,
    which is centered on Subject to Change, a new national project that will
    empower young people and the public to set a new direction for learning.
    For over 20 years Caireen has worked on learning, strategy, networks, and
    innovation projects with schools, local authorities, charities, various
    national and global bodies, and central government.
  </>,
  },
  {
    image: 'Emily.jpg',
    name: 'Emily Liebtag',
    bio:
  <>
    is
    {' '}
    <a
      href="https://education-reimagined.org/"
      target="_blank"
      rel="noreferrer"
    >
      Education Reimagined

    </a>
    ’s Senior Partner for Systems Transformation. For two decades, she has
    supported the development of learner-centered practices in communities
    across the country as a leader of a global non-profit, working at the
    university level with educators, and as the Vice President of Advocacy
    for Getting Smart.
  </>,
  },
  {
    image: 'Emma.jpg',
    name: 'Emma Dorn',
    bio:
  <>
    is a Senior Knowledge Expert in Education at
    {' '}
    <a
      href="https://www.mckinsey.com/"
      target="_blank"
      rel="noreferrer"
    >
      McKinsey and Company

    </a>
    , where she leads knowledge development and dissemination for McKinsey’s
    education work globally. Emma’s areas of focus include improving access
    to, and quality of, education for historically underserved populations,
    supporting efforts to meet the sustainable development goal for education
    (SDG4), applying advanced analytics for social impact, and applying
    technology to improve teaching and learning.
  </>,
  },
  {
    image: 'Essie.jpg',
    name: 'Essie North',
    bio:
  <>
    is the CEO of
    {' '}
    <a
      href="https://www.big-change.org/"
      target="_blank"
      rel="noreferrer"
    >
      Big Change

    </a>
    {' '}
    – a charity acting as a catalyst for long term change in how to support
    all young people to thrive in life, not just in exams. Essie has led
    their work backing pioneering projects on the ground in the UK, bringing
    together a global network of leaders reimagining education and building
    a community of like-minded funders to support system change. Previously,
    she was advising a network of 500 leading global businesses on best
    practices in leadership and change.
  </>,
  },
  {
    image: 'Franco.jpg',
    name: 'Franco Mosso',
    bio:
  <>
    is the co-founder and CEO of
    {' '}
    <a
      href="https://ensenaperu.org/"
      target="_blank"
      rel="noreferrer"
    >
      Enseña Perú

    </a>
    . He holds a master’s degree in International Education Policy from
    Harvard University, and is a specialist in change management who works
    to develop collective leadership and catalyze system change in Peru.
  </>,
  },
  {
    image: 'Fred.jpg',
    name: 'Fred Swaniker',
    bio:
  <>
    is the founder and CEO of the
    {' '}
    <a
      href="https://algroup.org/"
      target="_blank"
      rel="noreferrer"
    >
      African Leadership Group

    </a>
    , an ecosystem of organizations that is catalyzing a new era of ethical,
    entrepreneurial African leaders for the African continent. Fred previously
    worked as a McKinsey consultant before earning an MBA from Stanford and
    becoming an entrepreneur. He was named one of TIME Magazine’s 100 most
    influential people of 2019.
  </>,
  },
  {
    image: 'Joann.jpg',
    name: 'Joann McPike',
    bio:
  <>
    founded
    {' '}
    <a
      href="https://thinkglobalschool.org/"
      target="_blank"
      rel="noreferrer"
    >
      THINK Global School
    </a>
    {' '}
    {' '}
    – an independent, not-for-profit traveling boarding school that allows
    students to study in 12 different countries during a four-year high
    school experience. Now on the Board of Directors for the school, she
    guides the continual success of students from over 20 countries. As
    someone passionate about education and familiar with developing and
    scaling projects, she offers valuable insights and support to Big Change
    and has been critical to the development of A New Education Story.
  </>,
  },
  {
    image: 'Kelly.jpg',
    name: 'Kelly Young',
    bio:
  <>
    is the Founder and President of
    {' '}
    <a
      href="https://education-reimagined.org/"
      target="_blank"
      rel="noreferrer"
    >
      Education Reimagined

    </a>
    . Kelly is a nationally-respected leader and advocate for the transformation
    of education in the U.S. For over two decades, her leadership has helped
    advance and support the learner-centered education movement. All of her
    work is grounded in her previous roles and experiences as an organizer,
    community engagement leader, and mother.
  </>,
  },
  {
    image: 'Michael.jpg',
    name: 'Michael Stevenson',
    bio:
  <>
    is a Senior Advisor at the Global Organization for Economic Development
    and Cooperation (
    <a
      href="https://www.oecd.org/education/"
      target="_blank"
      rel="noreferrer"
    >
      OECD

    </a>
    ), where he develops a long-term PISA roadmap and supports high-performing
    education systems. Previously, he was Vice President for Global Education
    at Cisco Systems.
  </>,
  },
  {
    image: 'Rebecca.jpg',
    name: 'Rebecca Winthrop',
    bio:
  <>
    is a senior fellow and co-director of the
    {' '}
    <a
      href="https://www.brookings.edu/center/center-for-universal-education/"
      target="_blank"
      rel="noreferrer"
    >
      Center for Universal Education

    </a>
    {' '}
    at Brookings. She works to promote quality and relevant education, including
    exploring how education innovations can leapfrog progress. Before joining the
    Center for Universal Education at Brookings in June 2009, Dr. Winthrop spent
    15 years working in education for displaced and migrant communities.
  </>,
  },
  {
    image: 'Richard.jpg',
    name: 'Richard Culatta',
    bio:
  <>
    is the CEO of the International Society for Technology in Education (
    <a
      href="https://www.iste.org/"
      target="_blank"
      rel="noreferrer"
    >
      ISTE

    </a>
    ). Prior to holding this position, he was the chief innovation officer for the
    state of Rhode Island and the director of the Office of Educational Technology
    for the U.S. Department of Education.
  </>,
  },
  {
    image: 'Saku.jpg',
    name: 'Saku Tuominen',
    bio:
  <>
    is the founder and creative director of
    {' '}
    <a
      href="https://hundred.org/en"
      target="_blank"
      rel="noreferrer"
    >
      HundrED

    </a>
    , a nonprofit improving K12 education globally by seeking and sharing inspiring
    educational innovations. He has worked in innovation and creativity for
    more than 25 years, founded several companies, and published 15 books.
  </>,
  },
  {
    image: 'Sofie.jpg',
    name: 'Sofie Yung',
    bio:
  <>
    is the Content Lead for Innovation in Education at Dubai Cares where she is part of
    {' '}
    <a
      href="https://www.rewired2021.com/"
      target="_blank"
      rel="noreferrer"
    >
      RewirEd

    </a>
    {' '}
    – a global platform with a vision to rewire education for a prosperous and
    sustainable future. Previously, she has worked with organisations in the
    fields of International development and social innovation.
  </>,
  },
  {
    image: 'Steven.jpg',
    name: 'Steven Farr',
    bio:
  <>
    co-leads
    {' '}
    <a
      href="https://teachforall.org/"
      target="_blank"
      rel="noreferrer"
    >
      Teach For All

    </a>
    ’s Global Learning Lab. For more than a decade, he has fostered learning
    among and from transformational classrooms in marginalized and oppressed
    communities across Teach For All’s network. He is currently facilitating
    the collective co-creation of a new framework of teaching and learning
    for growing students as leaders of a better future.
  </>,
  },
  {
    image: 'Todd.jpg',
    name: 'Todd Rose',
    bio:
  <>
    is the co-founder and president of
    {' '}
    <a
      href="https://populace.org/"
      target="_blank"
      rel="noreferrer"
    >
      Populace

    </a>
    , a think tank committed to ensuring that all people have the opportunity to
    pursue fulfilling lives in a thriving society. Before Populace, he was
    a faculty member at Harvard University, where he founded the Laboratory
    for the Science of Individuality and directed the Mind, Brain, and Education
    program.
  </>,
  },
  {
    image: 'Vicky.jpg',
    name: 'Vicky Colbert',
    bio:
  <>
    is the founder and director of
    {' '}
    <a
      href="https://escuelanueva.org/"
      target="_blank"
      rel="noreferrer"
    >
      Fundación Escuela Nueva

    </a>
    {' '}
    and co-author of the Escuela Nueva educational model. Previously, she
    has served as Colombia’s Vice-Minister of Education and Unicef´s Regional
    Adviser for the Latin American and Caribbean Region. She has been the
    inaugural laureate and recipient of the Yidan Development Prize and Wise
    Prize and has been awarded many distinctions in the field of social
    entrepreneurship by the Skoll, Ashoka, and Schwab Foundation.
  </>,
  },
  {
    image: 'Vishal.jpg',
    name: 'Vishal Talreja',
    bio:
  <>
    is the co-founder of
    {' '}
    <a
      href="https://dreamadream.org/"
      target="_blank"
      rel="noreferrer"
    >
      Dream a Dream

    </a>
    , a non-profit organisation empowering children and young people from
    vulnerable backgrounds to overcome adversity and thrive in a fast-changing
    world. Vishal is an Ashoka Fellow, an Eisenhower Fellow, a Salzburg Global
    Fellow, and a Board Member at Goonj and PYE Global. He has previously been
    a Founder Director of Unltd India and Board Member of India Cares Foundation.
  </>,
  },
  {
    image: 'Wendy.jpg',
    name: 'Wendy Kopp',
    bio:
  <>
    is the CEO and Co-founder of
    {' '}
    <a
      href="https://teachforall.org/"
      target="_blank"
      rel="noreferrer"
    >
      Teach For All

    </a>
    . Prior to launching Teach For All in 2007 to be responsive to the initiative
    of social entrepreneurs around the world who were determined to adapt this
    approach in their own countries, Wendy founded and led Teach For America for
    24 years. She holds honorary doctorate degrees from 15 universities and has
    been recognized as one of Time Magazine’s 100 Most Influential People.
  </>,
  },
];

const PartnersSection = () => (
  <section className="section partners-section">
    { PARTNERS_DATA.map(({ image, name, bio }) => (
      <div className="partners-section-entry" key={name}>
        <div className="partners-section-entry-image">
          <img src={withPrefix(`/headshots/${image}`)} alt="" loading="lazy" />
        </div>
        <div className="partners-section-entry-bio">
          <b>{name}</b>
          {' '}
          {bio}
        </div>
      </div>
    ))}
  </section>
);

export default PartnersSection;
