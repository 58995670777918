import React from 'react';
import { Helmet } from 'react-helmet';
import ActionBox from '../components/action';
import ActionsExplainer from '../components/actions-explainer';
import Contributors from '../components/contributors';
import DriverSectionIntro from '../components/driver-section-intro';
import DriversExplainer from '../components/drivers-explainer';
import Layout from '../components/layout';
import Logos from '../components/logos';
import MeadowGraphic from '../components/meadow';
import Navigation from '../components/navigation';
import PartnersSection from '../components/partners';
import QuestionsCarousel from '../components/questions';
import Quote from '../components/quote';
import References, { Reference } from '../components/references';
import RevealBox from '../components/reveal-box';
import RevealModal, { RevealModalText } from '../components/reveal-modal';
import ShowWhenRevealed from '../components/show-when-revealed';
import SplashPage from '../components/splash-page';
import TakeActionBox from '../components/take-action-box';
import YouTubePlayer from '../components/youtube-player';

import './main.scss';

const IndexPage = () => (
  <>
    <SplashPage />
    <Contributors />
    <Navigation />
    <Layout>
      <Helmet>
        <title>A New Education Story</title>
        <meta
          property="og:title"
          content="A New Education Story"
        />
        <meta
          name="description"
          content="Three Drivers to Transform Education Systems"
        />
        <meta
          property="og:description"
          content="Three Drivers to Transform Education Systems"
        />
        <meta property="og:locale" content="en_GB" />
        <meta
          property="og:image"
          content="https://big-change.org/new-education-story/images/og.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="A New Education Story" />
        <meta
          name="twitter:description"
          content="Three Drivers to Transform Education Systems"
        />
      </Helmet>
      <svg
        width={0}
        height={0}
        viewBox="0 0 730 423"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="bladeClip" clipPathUnits="objectBoundingBox">
          <path
            transform={`scale(${1 / 730} ${1 / 423})`}
            d="M0.349608 1.49122L728.755 0.0481339C728.755 0.0481339 743.035
            230.425 572.736 421.703C509.641 433.33 214.392 319.832 0.258317
            110.277L0.349608 1.49122Z"
          />
        </clipPath>
      </svg>
      <h2 data-section-title="Foreword">Foreword</h2>
      <section
        className="section"
      >
        <div className="content-single">
          <p className="bold-paragraph">
            Since 2017 Big Change has had the privilege of bringing together
            global partners who share a commitment to
            {' '}
            <a
              href="https://www.big-change.org/publication/reimagining-education/"
              target="_blank"
              rel="noreferrer"
            >
              reimagining education
            </a>
            {' '}
            with and for young people. Collectively, we have explored and
            challenged old assumptions about what education is and can be, who
            and what it is for, and hope to create a new education story for our
            times.
          </p>
          <p className="bold-paragraph">
            At this moment, when education leaders, policymakers, and funders in
            every context are making decisions about how to restore learning and
            education, we want to offer a provocation. To say that it is simply
            not enough to “go back”. That to set up every young person to thrive
            - to prepare them well for their futures - the old ways will not do.
            More reform that leaves the existing narrow goals and purpose of
            education untouched will not get us there.
          </p>
          <p className="bold-paragraph">
            <b>Our new education story must be one of transformation.</b>
          </p>
          <p>
            This publication is an invitation to education system leaders and
            decision makers to step back, to ask big questions, and to think
            differently about both what we are aiming for, and how to get there.
          </p>
          <p>
            This is not to say that the job of transforming education is theirs
            alone. The nature of transformation requires a new mindset and set
            of approaches that work
            {' '}
            <i>with</i>
            {' '}
            those across the system - educators, students, parents, communities
            and peers. Working with global partners and contributors, we have
            identified
            {' '}
            <b>
              three drivers to transform education systems
            </b>
            , along with
            {' '}
            <b>nine actions and real world stories</b>
            {' '}
            that show how to make progress.
          </p>
          <p>
            The interlinked drivers require us all to think differently about;
            {' '}
            <b>purpose</b>
            {' '}
            - the goals and outcomes of education;
            {' '}
            <b>power</b>
            {' '}
            - expanding who has voice and agency in education; and
            {' '}
            <b>practice</b>
            {' '}
            - unlocking innovation that has transformative potential. They
            encourage leaders and funders to act in new ways, with a greater
            focus on the wider ecosystem. While this may seem daunting, there
            are many exciting examples of people, organisations, alliances and
            places showing that a new way is possible.
          </p>
          <p>
            We hope that this publication will act as a provocation for
            conversation and action. There is no quick fix or neat solution, so we
            invite you to explore the ideas, actions and questions set out here
            with colleagues and allies. And if you have ideas for how to share and
            activate others around this work, please get in touch.
          </p>
          <p className="main-author">
            Essie North, CEO, Big Change
          </p>
        </div>
      </section>
      <section className="section">
        <ShowWhenRevealed>
          <img
            src="images/New_Education_Story_prism_v2.png"
            alt=""
            loading="lazy"
            className="image-in-section"
          />
        </ShowWhenRevealed>
        <p className="content-single caption-paragraph">
          Figure 1: Sources of insight and ideas underpinning the three drivers
          for transforming education systems, created by Big Change

        </p>
      </section>

      <h2 data-section-title="Introduction">Introduction</h2>
      <section className="section content-section">
        <div>
          <p className="bold-paragraph">
            Education systems around the world are at a crossroads; a moment of
            huge challenge and opportunity when, perhaps more than at any other
            time in living memory,
            <Reference id={1} />
            decisions made now about what happens next
            for learning, will have deep and lasting effects for the future.

          </p>

          <p>
            In 2021, education systems
            <Reference id={3} />
            {' '}
            around the world began to emerge from a
            global pandemic that created the worst crisis in learning in a century.
            <Reference id={4} />
          </p>

          <p>
            The individual, societal and economic impacts of this mass
            interruption to learning will take years to make themselves fully
            felt. In many parts of the world the pandemic resulted in impacts
            for children way beyond education, such as increases in early
            marriage and child labour, trafficking and displacement.
            <Reference id={5} />
            {' '}
            In the short term, though some children, families and educators
            discovered new ways of learning, many children lost months of
            learning progress and fewer completed secondary education.
            <Reference id={6} />
            {' '}
            Many children’s mental health and wellbeing suffered under lockdown
            <Reference id={7} />
            {' '}
            and in response to adversity, personal grief and economic hardship
            brought about by the pandemic.
          </p>
          <p>
            The drive to restore and reinstate what was there before the
            pandemic is understandable, when trauma and uncertainty have been
            such a widely shared experience.
          </p>
          <p>
            The education workforce, celebrated and appreciated during the
            pandemic, were nevertheless depleted and exhausted by its demands.
            <Reference id={9} />
            {' '}
            Parents greeted school reopenings with relief and enthusiasm as a
            chance to return to work and society. Children were happy to be back
            amongst their friends and learning again.
          </p>
          <p>
            However, undeniable inequalities that existed in learning were also
            massively accelerated
            <Reference id={10} />
            {' '}
            by the pandemic. In many places, gaps widened;
            <Reference id={11} />
            {' '}
            progress was halted or reversed;
            <Reference id={12} />
            {' '}
            and the impact of poverty amplified,
            <Reference id={13} />
            {' '}
            adversely affecting millions of children - and laid bare for
            everyone to see.
          </p>
          <p className="bold-paragraph">How can we simply go back?</p>
        </div>
        <div>
          <YouTubePlayer id="KaqyQfoTO5A" />
          <Quote credit={(
            <>
              Vishal Talreja, Dream a Dream, 2020
              <Reference id={8} />
            </>
          )}
          >
            How do we ensure that we don’t go back to the old world, old us
            when this crisis is over? If we go down that path, it would be
            such a waste of this deeply transformative experience that life
            has thrown our way.
          </Quote>
          <Quote credit={(
            <>
              Andreas Schleicher, OECD, 2020
              <Reference id={14} />
            </>
          )}
          >
            There may be a key distinction between education systems that will
            master the crisis and move forward, and those that will not. The
            distinction may be between those education systems that feel
            threatened by alternative ways of thinking and those that are open
            to the world and ready to learn from and with the world’s education
            leaders.
          </Quote>
        </div>
      </section>
      <h3 className="section">
        Learning reimagined
      </h3>
      <section className="section content-section">
        <div>
          <p className="bold-paragraph">
            Although it undoubtedly sent shockwaves across education systems,
            <Reference id={15} />
            many of the challenges the pandemic raised - and the creative solutions that
            arose to meet them - were far from new or unknown.
          </p>

          <p>
            There have been calls to reimagine learning
            <Reference id={16} />
            <Reference id={17} />
            <Reference id={18} />
            {' '}
            for many
            years and these have grown in urgency as the long-anticipated impacts of
            major social and economic trends, technological advances, shifting
            geopolitical landscapes, climate change and increasing inequality,
            <Reference id={19} />
            {' '}
            have begun to take effect.
          </p>

          <p>
            At the heart of calls for change are shared questions about what the
            future holds: What purposes do learning and education systems need
            to serve if humanity and our planet are to flourish? What should
            learning and education systems look like, if we are to live lives
            that have meaning for the individual and significance in the world,
            and to work together to solve our greatest problems?
            <Reference id={20} />
          </p>
          <p>
            There is also a growing global consensus about the shifts that are
            needed in how learning happens, if education is to meet these purposes.
          </p>

          <p>
            Learning that meets these goals needs to be more diverse, more
            individualised and more learner-centred.
            <Reference id={22} />
            {' '}
            It needs to be more
            multi-disciplinary and connected to life beyond schools: to family and
            community; to meaningful work and to the digital world. Learning needs to
            attend more to the social and emotional development of children and young
            people, their empathy, their compassion and their ability to form positive
            relationships.
            <Reference id={23} />
            {' '}
            Post-pandemic, where learners are struggling to come to
            terms with their experience and loss.
            <Reference id={24} />
          </p>

          <p>
            Critically, there is also agreement that learning and education need to
            offer every child - not just some children - the chance to reach their
            unique potential and further, that learning and education should become
            solutions to tackling inequality, rather than part of the problem.
          </p>

          <p>
            It is perhaps this purpose that will prompt the most urgent and
            fundamental shifts.
          </p>
        </div>
        <div>
          <Quote credit="Sir Ken Robinson 2006 “Do Schools Kill
            Creativity?“ Ted Talk viewed nearly 72 million times via YouTube"
          >
            Our education system has mined our minds in the way
            that we strip-mine the earth for a particular commodity. And for the future,
            that won’t serve us. We have to rethink the fundamental principles on which
            we are educating our children.
          </Quote>
          <Quote credit={(
            <>
              Michael Stevenson, OECD
              <Reference id={21} />
            </>
          )}
          >
            Countries are coming together to ask big questions
            about the future, questions like: In a world of machine learning, how should
            human intelligence diversify, and what would that mean for education?
            Thinking prompted by questions like this point to new purposes for education
            in the future, for example, developing competencies that are unique to
            humans, which enrich human lives and which complement technology, not
            compete with it; competencies such as adaptive problem solving, ethical
            decision-making and aesthetic perception.
          </Quote>
        </div>
      </section>
      <section className="section">
        <RevealBox title="Learning and education should…">
          <p><b>Set up all young people to thrive in life</b></p>
          <ul>
            <li>
              Focus on developing a sense of personal agency alongside the
              attitudes, knowledge and skills young people need to fulfil their
              potential and contribute to the well-being of others and the planet.
            </li>
          </ul>
          <p>
            <b>Create a story of success for every child</b>
          </p>
          <ul>
            <li>
              Start with the needs of each child and be grounded in an expansive
              view of what they can achieve, placing value on much more than
              academic outcomes and measuring only what really matters.
            </li>
          </ul>
          <p>
            <b>
              Help every child fall in love with learning so they keep on learning
              for life

            </b>
          </p>
          <ul>
            <li>
              Ignite the joy of learning in every child, encouraging them to
              develop their sense of passion and purpose, tapping into their
              intrinsic motivation and emphasising that learning is a lifelong
              skill.
            </li>
          </ul>
          <p>
            Big Change, 2019
          </p>
        </RevealBox>
      </section>
      <h3 className="section">System reform or system transformation?</h3>
      <section className="section content-section">
        <div>
          <p className="bold-paragraph">
            To reimagine learning - sustainably and at scale - the education
            systems within which learning takes place need to be not just
            reformed, but transformed: not just improved but redesigned.
          </p>

          <p>
            Many education systems are in the process of continuous reform, in
            which efforts are made to improve efficiency, effectiveness and
            equity, but which largely keep the existing system and goals in
            place. Transformation of the kind witnessed during the pandemic, by
            contrast, involves both a shift in the dominant logic of a system
            and for the effects of that shift to be experienced at scale.
          </p>

          <p>
            Transformation is required if education systems are to adapt to the
            new challenges and opportunities facing young people and society;
            the third horizon in an emerging - and uncertain - future.
            <Reference id={26} />
          </p>
        </div>
        <div>
          <Quote credit={(
            <>
              Education Reimagined, 2020
              <Reference id={25} />
            </>
          )}
          >
            Education systems produce the results they were designed to produce.
          </Quote>
          <Quote credit="Vishal Talreja, Dream a Dream">
            Let’s give young people a chance to shape our world. That’s why this
            transformation is needed.
          </Quote>
        </div>
      </section>

      <h3>A glimpse of the future</h3>
      <section className="section content-section">
        <div>
          <p className="bold-paragraph">
            While it has been a source of major disruption to learning, the
            Covid-19 pandemic has also been a stimulus for innovation in
            education systems and has grown demand for mainstreaming new ideas
            and practice, which were previously found only at the margins.
            <Reference id={27} />
            {' '}
            Adaptations made by
            educators, parents and communities during school closures have demonstrated
            the possibility and the benefits of reimagining learning.
          </p>

          <p>
            Nowhere is this more apparent than in the rapid expansion in the use of
            digital resources and platforms in education systems which, before the
            pandemic, were simply not ready to embrace digital learning.
            <Reference id={28} />
          </p>

          <p>
            Perhaps even more important, the pandemic has prompted recognition of
            the value of relationships, critical for engaging learners effectively
            <Reference id={30} />
            and for building resilience in the face of adversity.
            <Reference id={31} />
            {' '}
            The relationships
            between educators, learners, their families and communities - the people who
            are at the heart of learning
            <Reference id={32} />
            {' '}
            will be fundamental in any deliberations
            about where education systems should go next.
            <Reference id={33} />
          </p>

        </div>
        <div>
          <Quote credit={(
            <>
              Richard Culatta, International Society for Technology in Education, 2021
              <Reference id={29} />
            </>
          )}
          >
            The pandemic has broken through a barrier that has
            been persistent in education: the belief that we can’t change the existing
            model because it is somehow set in stone or enshrined in law. COVID blew
            that away and showed us that everything could change, and as a result we
            have made huge, accelerated progress in closing basic connectivity gaps in
            some places.
          </Quote>
          <Quote credit={(
            <>
              Eileen McGivney and Rebecca Winthrop, 2016
              <Reference id={39} />
            </>
          )}
          >
            Now is the time to chart a vision for how education
            systems can emerge stronger from this global crisis than ever before and
            propose a path for capitalising on education’s newfound support in virtually
            every community across the globe.
          </Quote>
        </div>
      </section>
      <h3>A system perspective</h3>
      <section className="section content-section">
        <div>
          <p className="bold-paragraph">
            Education systems are inherently complex, deeply connected to - and
            interdependent with - people systems (family and community) and other
            systems (business and the economy, politics). They loom large in the public
            consciousness and carry huge historical and cultural significance.
            <Reference id={34} />
          </p>

          <p>
            It is perhaps unsurprising that even relatively small changes can be
            hard to implement, and the realisation that education systems can be - must
            be - transformed for learning to be reimagined, is daunting to say the
            least.
          </p>

          <p>
            However, viewed another way complexity and interdependence can be seen as
            advantages, as assets, in education transformation.

          </p>

          <p>
            Recent work, inspired by the late Donella Meadows,
            <Reference id={35} />
            {' '}
            an American environmental scientist and pioneer in the field of
            system dynamics, has revealed fresh insight into how complex systems
            like education resist transformation by accommodating and adjusting
            to change at a superficial level without making any fundamental
            shifts.
            <Reference id={36} />
            <Reference id={37} />
            <Reference id={38} />
            {' '}
            This helps to explain how it can feel for people in education
            systems that everything is changing all the time, but that nothing
            really important ever does.
          </p>

          <p>
            Meadows identified 12 levers - places to intervene in a system - that can
            bring about system transformation. Education reform efforts on the whole
            concern themselves with the practical levers, which can be helpful for
            improvement but are ultimately weak and leave the system itself largely
            unchanged.
          </p>

          <p className="bold-paragraph">
            If system transformation is the goal then work needs to happen at
            the points of stronger leverage, which concern culture, beliefs and
            values, and which require a very different approach.
          </p>
        </div>
        <div>
          <YouTubePlayer id="4cPWB5IARxk" />
          <Quote credit={(
            <>
              RewirED Systems Map, 2021
            </>
          )}
          >
            We have seen education become everyone’s business, whether you
            are a corporate CEO, a parent, or a minister. How do we motivate and
            catalyse these connections to accelerate collaborations between
            likely and unlikely allies? What if there was a way to map new
            narratives and showcase new connections, so we can ask new questions
            and unearth new thinking in education?
          </Quote>
        </div>
      </section>
      <section className="section meadow-section">
        <div className="image-in-section">
          <MeadowGraphic />
        </div>
        <p className="content-single caption-paragraph">
          Figure 2: Meadows’ Places to Intervene in a System adapted by
          Winthrop, et al. from Meadows (1999) and from conversations with
          Populace
        </p>
      </section>
      <section className="section" data-section-title="Three Drivers">
        <h3 className="explainer-headline">
          Three drivers to transform education systems

        </h3>
      </section>
      <section className="section content-section">
        <div>

          <p>
            <b>
              Transformation requires work deep within the system.
              <Reference id={40} />
              {' '}
              It is a deeply human endeavor.
              <Reference id={41} />
              {' '}
              It requires understanding the forces at the heart of education
              systems, to name and challenge those that cause systems to resist
              change, despite frequent, well planned and richly resourced
              attempts to deliver it.
            </b>
          </p>
          <p>
            Together these three drivers begin to indicate a theory of action,
            directing leaders, funders and decision makers to the places to
            intervene in education systems at sufficient depth to bring about
            fundamental and lasting changes.
          </p>
        </div>
        <div>
          <Quote credit={(
            <>
              OECD, 2020
              <Reference id={42} />
            </>
          )}
          >
            The laws, regulations, structures and institutions on which
            educational reformers tend to focus are just the tip of the iceberg.
            The much larger part, lying beneath the surface, concerns the
            interests, beliefs, motivations and fears of those involved.
          </Quote>
        </div>
      </section>
      <section className="section">
        <DriversExplainer />
      </section>
      <h3>Beyond theory to action</h3>
      <section className="section content-section">
        <div>
          <p>
            It is axiomatic that action should be at the heart of a theory of action,
            but this is not always the case. Theoretical models, helpful though they may
            be, are often just that - theoretical - and the work to make the big ideas
            and big challenges they advance actionable is left for another day, another
            report.
          </p>

          <p>
            Challenged by partners to go further on this occasion - to bring the
            theory of action to life - each driver comprises a handful of actions that
            might be taken to bring about transformation, along with stories from the
            real world; places and organisations where progress towards transformation
            is being made.
          </p>

          <p>
            We have been selective. There are many actions that could and are being
            taken, in service of transformation in education systems around the world.
            In the spirit of this work, we have focused on points of high leverage and
            deep impact, indicated by the research and evidence shared by partners and
            in the real world stories of transformation.
          </p>
          <p>
            On the pages that follow, we examine how these three drivers work,
            together and separately, to bring about education system transformation, and
            we pose challenging questions for reflection and discussion in your
            context.
          </p>
        </div>
        <div>
          <RevealBox title="Note to readers" small>
            For the purposes of this publication we are using
            the term education system(s) to mean everything that goes into
            providing education free to young people at the federal, state, or
            community levels for instance: policies and laws; funding and
            resources; facilities, equipment and materials; people - their
            employment, training and management.

          </RevealBox>
        </div>
      </section>

      <section className="section">
        <ActionsExplainer />
      </section>
      <section
        className="section content-section"
        data-section-title="Driver 1: Purpose"
      >
        <DriverSectionIntro
          title="Purpose"
          subtitle="Redefine Goals And Outcomes"
          description="Redefine the goals and outcomes for the education system
          to reflect the challenges and opportunities of the future and the
          values and priorities of stakeholders"
        />

        <ActionBox type="purpose">
          Engage stakeholders in defining the purpose of education

        </ActionBox>
        <div>
          <p>
            Step into almost any school in almost any country and you will find
            skilled, caring and committed educators creating a welcoming and
            stimulating learning environment that nurtures and challenges
            children and young people who are full of talent, energy and
            curiosity.
          </p>

          <p>
            Stick around for long enough and you will also notice how often this
            happens despite the pressures and incentives of the education system
            within which the school sits, and not because of it.
          </p>

          <p>
            Wait a while longer and you might see those pressures begin to
            constrain how, when and where learning happens, to damage learning
            relationships and the culture of the school community, to frustrate
            parents, exhaust educators and to disengage learners.
          </p>

          <p>
            Education systems are designed to deliver very specific outcomes - basic
            skills for many, exam success and higher education for some; safe
            supervision of children and young people while enabling their parents to
            work; and the transmission of cultural norms and values that govern how
            children and young people should behave in the world now, and as
            adults.
          </p>

          <p>
            Opportunities to learn and practise sports, developing artistic
            appreciation and expression and enjoying the society of their peers are also
            important functions of education. Its purpose, however, is to use
            examination and accreditation to determine what roles children and young
            people will take in their country’s future, and whether and how they will
            contribute to - and benefit from - its wealth and progress.
          </p>

          <p>
            Stakeholders in learning and education - in particular educators, parents
            and learners - are rarely if ever asked if they see the purpose of education
            this way.
            <Reference id={44} />
          </p>
          <p>
            If education systems are to truly transform then different outcomes - new
            purposes - need to be agreed by a wider group of stakeholders, in the
            context of a frank appraisal of the challenges of the future and
            transparency about the shortcomings of current systems.
          </p>
        </div>
        <div>
          <YouTubePlayer id="iOV0722Fyic" />
          <Quote credit={(
            <>
              Fred Swaniker, African Leadership Group, 2021
              <Reference id={43} />
            </>
          )}
          >
            Education is the vehicle for creating empathetic,
            creative, resilient, ethical leaders who can take people to new places and
            solve grand challenges that face society.
          </Quote>
          <Quote credit={(
            <>
              Todd Rose, 2021
              <Reference id={45} />
            </>
          )}
          >
            Left solely in the hands of those who are invested in and who
            currently control education systems, any questions surrounding its
            current purpose will almost certainly be answered in ways that align
            to the outputs and outcomes that system is geared to delivering.
          </Quote>
          <Quote credit={(
            <>
              BIG CHANGE FOCUS GROUP WITH RESTLESS DEVELOPMENT YOUTH RESEARCHERS, 2021
            </>
          )}
          >
            Education systems are too focused on preparation for work and not
            enough on preparation for life. You need to learn how to live in the
            world.
          </Quote>
          <Quote credit="Aliyah Irabor-York, Pupil Power">
            I think that is what the purpose of education is to, to create a
            space where we are developing skills that allow us to be great human
            beings.
          </Quote>
        </div>
      </section>
      <section className="section">

        <QuestionsCarousel questions={[
          'What is the purpose of education in your context? Is it clearly defined?',
          'Who determines the purpose of education, in your context?',
          'What different purposes emerge as desirable when different groups are asked ?',
          'How do parents, educators and learners feel about the current purposes of education ?',
        ]}
        />
      </section>
      <section className="section content-section">
        <ActionBox type="purpose">
          Reveal and challenge the shared assumptions blocking transformation
        </ActionBox>
        <div>
          <p>
            To change the purpose of education means challenging the collective
            assumptions that inform and reinforce the current purpose and making
            explicit new assumptions that do the same for the new purpose.
          </p>

          <p>
            Education systems are held in place by a vast array of collective
            assumptions - widely held beliefs - about how the world works;
            assumptions that often go, not just unquestioned, but entirely
            unnoticed.
            <Reference id={46} />
          </p>

          <p>
            To challenge collective assumptions requires first to reveal them,
            then to question their legitimacy and challenge their power, for
            instance to reinforce inequality or prevent progress.
          </p>

          <p>
            For instance, the collective assumption that only a select few
            children are capable of high achievement
            <Reference id={47} />
            {' '}
            persists in education systems around the world, despite overwhelming
            evidence to the contrary.
            <Reference id={48} />
            {' '}
            In the context of this assumption, one purpose of education systems
            - finding out who the “smart” children are, as defined by existing,
            often narrow measures, and preparing them for higher education and
            professional careers and leadership thereafter - makes perfect
            sense.
            <Reference id={49} />
          </p>

          <p>
            However this is just one of many myths that persist in education systems.
            Other assumptions - that poverty is an insurmountable barrier to learning
            for instance, or that some countries have better cultures for learning than
            others - are equally false.
            <Reference id={50} />
          </p>

          <p>
            Challenging - and changing - collective assumptions is a developmental
            journey, not a technical one. This is hard because it requires people to
            confront fears, biases, or hidden commitments that they didn’t even know
            they had, and that might be serving their interests in ways that make them
            uncomfortable.
            <Reference id={51} />
          </p>

          <p>
            However, myths and assumptions like this have to be debunked
            <Reference id={2} />
            {' '}
            because they underpin and justify a whole set of arrangements in
            education systems - standardised testing, ‘ability’ groupings,
            timetabling and the subject choices available to children to name a
            few. These are the very arrangements that reinforce inequalities.
            They are the forces buried deep in education systems that ensure
            systems deliver on their purpose: to filter the best performers
            based on their intake of standardized material.
            <Reference id={53} />
          </p>

          <p>
            What would an education system look like that was underpinned by
            different assumptions, for instance, that not just a select few
            children, but all children, were capable of high achievement? How
            would that change people’s view of the purpose of learning?
          </p>
        </div>
        <div>
          <YouTubePlayer id="WMQpeBejB68" />
          <Quote credit="Education Reimagined, 2020">
            In a learner-centred paradigm, we value each child as unique and
            believe all children have boundless potential to contribute in
            countless ways. In this paradigm, the purpose of education is to
            maximise exploration and learning so that each child can discover
            who they are, their unique gifts and how to contribute those gifts
            meaningfully in the world.
          </Quote>
          <Quote credit={(
            <>
              BIG CHANGE FOCUS GROUP WITH RESTLESS DEVELOPMENT YOUTH RESEARCHERS, 2021
            </>
          )}
          >
            Education should help young people to find out what they want from
            life, what they love.
          </Quote>
          <Quote credit={(
            <>
              BIG CHANGE FOCUS GROUP WITH RESTLESS DEVELOPMENT YOUTH RESEARCHERS, 2021

            </>
          )}
          >
            The people in charge should be thinking, who really has the power to
            make things happen ? And what learning do they need?
          </Quote>
        </div>
      </section>

      <section className="section">
        <QuestionsCarousel questions={[
          'What myths and assumptions might create barriers to transforming education in your context? How pervasive are they?',
          'How could these be revealed and challenged in order to make transforming education possible?',
          'How would you ensure any new assumptions that underpin a new purpose are made explicit and widely shared?',
        ]}
        />
      </section>
      <section className="section content-section">
        <ActionBox type="purpose">
          Discover, connect and amplify new alliances
        </ActionBox>
        <div>
          <p>
            It is striking how often people feel themselves to be in the
            minority - that they believe they feel or want x and everyone else
            wants y.
            <Reference id={54} />
            <Reference id={55} />
            {' '}
            It can make people stay silent on even passionately held desires and
            opinions.
          </p>

          <p>
            This matters because a parent’s or educator’s belief that their view
            about learning and education differs from others’ prevents them from
            finding and connecting with people who might share their beliefs. A
            misalignment or a lack of communication of beliefs and values around
            the education purpose is a barrier to successful system
            transformation.
            <Reference id={56} />
          </p>

          <p>
            In a recent survey of parents and teachers in 10 countries, The
            Center for Universal Education at Brookings found that the majority
            of both parents and educators think that children’s social and
            emotional development is the most important purpose of education,
            but that each group believes the other is more focused on academics
            than they actually are.
          </p>

          <p>
            The same is also true of how and where learning happens. Both
            educators and parents would, for the most part, prefer interactive,
            experiential, and social pedagogical methods. Yet, parents often
            overestimate educators’ focus on academics.
            <Reference id={57} />
          </p>
        </div>
        <div>

          <Quote credit="Winthrop et al., 2021">
            Between May 2020, and March 2021 The Center for Universal Education
            at Brookings conducted a survey of more than 24,000 parents and
            6,000 educators in 10 countries. Their questions explored beliefs
            about the most important purpose of school, what a quality education
            looks like, what types of teaching and learning experiences they
            thought were most important for their children and students, and how
            their educational beliefs were formed.

          </Quote>

          <Quote credit="OECD, 2020">
            Success in education today is about identity,
            it’s about agency and about purpose. It is about building curiosity -
            opening minds, it is about compassion - opening hearts, and it is about
            courage - mobilizing our cognitive, social and emotional resources to take
            action.
          </Quote>
        </div>
      </section>
      <section className="section">
        <QuestionsCarousel questions={[
          'How could you discover the gaps between privately held and publicly declared views about learning and education in your context?',
          'How could parents’ and educators’ privately held views about the purpose of learning and education be made public? What strategies or approaches might be used?',
          'What connections might be made and alliances forged to agree a new purpose for learning and to drive change in education systems?',
        ]}
        />
      </section>
      <section className="section content-section">
        <RevealModal
          title="How Enseña Perú engaged an entire community to collectively
            define a purpose of education"
          lead="Real World Story"
        >
          <p>
            The Ancash region in the Central Highlands of Peru generates income
            through mining and isn’t considered a poor region. Yet, 73% of people work
            as subsistence farmers on plots of less than two hectares and young people
            tend to migrate away from the region.
            <Reference id={60} />
            {' '}
            In 2016, when Enseña Perú started
            working in the region, Ancash’s education quality compared unfavourably to
            other regions in Peru.
          </p>
          <RevealModalText image="/images/ensena-peru.jpg">
            <p className="context">
              <a
                href="https://teachforall.org/news/working-together-change-ecosystem-community-impact-%C3%A1ncash-peru"
                target="_blank"
                rel="noreferrer"
              >
                Enseña Perú

              </a>
              {' '}
              (Teach for Peru), as part of the Teach for All network,
              is a non-profit organisation founded in 2009, with the mission to
              organise a movement of collective leadership that catalyses
              educational transformations across Peru that aim to close gaps in
              educational opportunity.
              <Reference id={59} />
            </p>
            <p>
              Enseña Perú took a collective action approach and built deep partnerships
              with the local government, the local mining company, other citizens’ sector
              organizations, parents, teachers, students, and other community
              members.
            </p>

            <p>
              Together, they created systems and structures for shared goal setting,
              strategy setting, and monitoring. They launched ¡Qué Maestro!, a public
              teacher training program, and ‘Ayni’, a grassroots community mobilization
              meeting.
              <Reference id={61} />
              {' '}
              In 2019, 450 local people joined the Ayni event to build shared
              momentum around improving education in the region, and strengthen
              participation.
            </p>

            <p>
              As a result of long-term trust-building and community events, Ancash is
              the first region in Peru to have a regional unit of educational evaluation,
              a key policy, and is also leading in the creation of their 2036 vision with
              a participative process - the whole region’s collective vision for
              education. This includes a shared vision for student success and its own
              measures for student outcomes and a renewed, collaboration-based, teacher
              professional development system. A student named Rómulo explains the
              connection he feels to the entire ecosystem: “We’re working together to
              improve education in our region and in our country.”
              <Reference id={62} />
            </p>

            <p>
              Enseña Perú’s collective leadership approach has also improved students’
              academic outcomes. An independent, quasi-experimental study by the
              Universidad del Pacífico demonstrates that Enseña Perú fellows had positive
              effects on student academic learning, as measured by national exams in
              reading and mathematics from 2012 to 2018.
              <Reference id={63} />
              {' '}
              According to a 2020 Center
              for Global Development paper, these results would rank Enseña Perú among the
              90th percentile of the most effective programs in supporting student
              learning.
              <Reference id={64} />
              {' '}
              Enseña Perú powerfully demonstrates how collaborative
              leadership can lead towards a new understanding of education and its role in
              society.
            </p>
          </RevealModalText>
        </RevealModal>

        <RevealModal
          title="How Dream a Dream redefines and acts upon a new purpose for
          education in India"
          lead="Real World Story"
        >
          <p>
            Dream a Dream regularly hosts ‘Change the Script’ gatherings where
            local charities, corporates, volunteers, governments, expert
            consultants, and strategic partners join forces with people who have
            the power to change education at scale. So far, in six of these
            conferences decision-makers and young people reimagined learning and
            the purpose of education in the 21st century.
          </p>
          <RevealModalText image="/images/dream-a-dream.jpg">
            <p className="context">
              One third of India’s children grow up in poverty and
              experience adversity (abuse, violence, malnutrition, etc.).
              <Reference id={65} />
              For more than two decades,
              {' '}
              <a
                href="https://dreamadream.org/"
                target="_blank"
                rel="noreferrer"
              >
                Dream a Dream

              </a>
              {' '}
              has been empowering
              children and young people from vulnerable backgrounds to thrive in
              the 21st century.
              <Reference id={66} />
            </p>
            <p>
              With the help of discussions, keynotes, stories from young people,
              interactive learning platforms and experiential activities, Change
              the Script united a diverse set of stakeholders to share their
              experiences and learnings, and to take their discussions one step
              further towards action.
              <Reference id={67} />
            </p>

            <p>
              A new purpose was identified: children develop capacities that lead
              to a life of purpose, meaning, contentment and wellbeing,
              <Reference id={68} />
              {' '}
              to counteract the pressure placed on young people by conventional
              education focused on academic excellence which they beleived might
              even contribute to high suicide rates among them.
              <Reference id={69} />
            </p>

            <p>
              In July 2018, the Delhi government launched the Happiness Curriculum
              in 1,030 government schools to focus on the holistic development of
              all learners.
              <Reference id={70} />
              {' '}
              The objective of this program is informed by the redefined
              purpose of education: to improve the mental wellbeing of students.
            </p>

            <p>
              The Curriculum was developed with the help of professionals which
              includes teachers from Government of Delhi schools, psychologists,
              education consultants, volunteers and the State Council of
              Educational Research and Training. This team also included senior
              officials from the Directorate of Education, Government of Delhi and
              people from various NGO’s.
            </p>

            <p>
              Dream a Dream anchored the curriculum in collaboration with other
              NGOs like Abhibhavak Vidyalaya, Circle of Life, Blue Orb Foundation
              and Labhya Foundation. The curriculum develops mindfulness,
              social-emotional learning, critical thinking, problem-solving and
              relationship-building skills intending to equip students with the
              necessary skills and environment to become purpose-driven and
              explore a nuanced idea of happiness.
            </p>

            <p>
              The Happiness Curriculum has since also been implemented in states
              of Andhra Pradesh and Uttarakhand reaching out to nearly 2 Million
              students.
            </p>
          </RevealModalText>
        </RevealModal>
        <RevealModal
          title="How Teach For All’s Teaching As Collective Leadership starts with
          purpose"
          lead="Real World Story"
        >
          <p>
            As part of Teach For All, the Global Learning Lab has hosted dozens
            of learning loops and workshops with thousands of people to ask what
            is happening in and around classrooms where students are growing as
            leaders of a better future for themselves, their community, and all
            of us.
          </p>
          <RevealModalText image="/images/teach-for-all.jpg">
            <p className="context">
              <a href="https://teachforall.org/" target="_blank" rel="noreferrer">
                Teach For All

              </a>
              {' '}
              is a growing network of independent organizations in
              more than sixty countries worldwide. Network partners recruit their
              nations’ diverse, promising leaders to teach in under-resourced
              schools and communities for at least two years, and with this
              foundation, to work throughout their lives, from inside and outside
              of education, towards the systemic changes necessary to ensure all
              children thrive.
            </p>
            <p>
              The result is the Teaching As Collective Leadership framework - a
              model crowd-sourced from transformational classrooms and the
              frontiers of education research that starts with the purpose of
              education.
            </p>

            <p>
              Many existing models for teacher training focus on actions or
              outcomes. In contrast, the Teaching As Collective Leadership starts
              with a purpose. Instead of imposing visions of student success and
              teaching practices on schools and communities, the model’s core is
              conversations about purpose with teachers, students, and families.
            </p>

            <p>
              “When these “purpose conversations” are prioritized, we see teachers
              shifting away from defining success in terms of closing gaps in
              standardized achievement scores. Instead, we see students, families,
              and educators envisioning better lives and futures with healthy,
              educated families, with meaningful careers, and with the skills and
              agency to make their communities, countries, and society better.”
              - Care et al., 2020
              <Reference id={71} />
            </p>

            <p>
              The Teaching As Collective Leadership framework does not come up
              with answers but with questions. The new teacher training is not a
              model, but a mirror to look into.
              <Reference id={72} />
              {' '}
              It’s an actionable, locally customizable framework of teaching that
              enables students to fulfill their potential as leaders of a better
              future.
            </p>
          </RevealModalText>
        </RevealModal>
      </section>
      <section
        className="section content-section"
        data-section-title="Driver 2: Power"
      >
        <DriverSectionIntro
          title="Power"
          subtitle="Expand voice and agency"
          description="Expand voice and agency by investing in stakeholders so
            they can make confident decisions about learning and education"
        />

        <ActionBox type="power">
          Liberate learner agency and co-agency
        </ActionBox>
        <div>
          <p>
            During the pandemic learners and educators had autonomy and agency
            to take charge of their learning to explore new ideas and
            experiences like never before. New interests and passions emerged
            for many students and teachers.
            <Reference id={75} />
            {' '}
            Learning that connects to learners’ interests and passions is
            engaging and motivating, with implications for achievement within
            the current system.
            <Reference id={76} />
          </p>

          <p>
            Agency is also at the heart of processes to transform learning and
            education. Agency - for learners and educators - matters, because it
            is their greatest opportunity to influence transformation.
          </p>

          <p>
            Further, whereas agency alone might lead to fragmentation and
            idiosyncrasy, collaborative agency, or co-agency,
            <Reference id={78} />
            {' '}
            connects learners and educators in a shared endeavour. Co-agency
            implies the effects of the learning environment on learners’ sense
            of agency, noticing the importance of a collaborative culture and
            the capacity and willingness of individuals and institutions to see
            the world from different perspectives.
            <Reference id={79} />
          </p>

          <p>
            Learners, in the learning they design and choose for themselves,
            point the way to learning that is relevant and engaging for them.
            Educators, innovating in their practice, demonstrate the art of the
            possible in new forms of pedagogy and assessment.
          </p>

          <p>
            While it might be hard to envisage transforming education systems
            one classroom at a time, inspiring educators and engaged and
            talented learners are nevertheless, together, reimagining learning
            every day.
          </p>

          <p>
            Whereas transforming education systems in all their complexity might
            feel overwhelming and appear unachievable, nurturing this kind of
            local innovation does not. And local innovation, at scale, can
            become system transformation.
          </p>
          <p>
            For innovations to move beyond the level of the individual and the
            classroom, people throughout the system - policy makers, system
            leaders, school leaders, as well as educators and learners - need to
            be encouraged and supported in developing the mindsets and skill
            sets to evolve system culture that honours the agency and co-agency
            of learners and educators.
            <Reference id={81} />
          </p>
        </div>
        <div>
          <YouTubePlayer id="6d9AS4mxD50" />
          <YouTubePlayer id="m-JNaTt5wa8" />
          <YouTubePlayer id="rtCzpCd1ceE" />
          <Quote credit={(
            <>
              OECD, 2019
              <Reference id={74} />
            </>
          )}
          >
            Agency is defined
            <Reference id={73} />
            {' '}
            as the capacity to set a goal, reflect and act responsibly to effect
            change. It is about acting rather than being acted upon; shaping
            rather than being shaped; and making responsible decisions and
            choices rather than accepting those determined by others.
          </Quote>
          <Quote credit={(
            <>
              Nadiem Makarim, 2021
              <Reference id={77} />
            </>
          )}
          >
            It is imperative to allow autonomy at every level in the education
            system to change it from an administrative institution to a learning
            institution.
          </Quote>
          <Quote credit={(
            <>
              Saku Tuominen, HundrED, 2021
              <Reference id={80} />
            </>
          )}
          >
            We need to remember that the system is us. In the words of the late
            Sir Ken Robinson, you don’t need to change the system, you just need
            to change yourself. If everyone were to act with integrity, as
            decent human beings, then the challenge of complexity - the need for
            complex things like transparency - would fall away. If we are to
            transform education then we just need to be excited about doing
            interesting things - and to see where that takes us.
          </Quote>
          <Quote credit={(
            <>
              BIG CHANGE FOCUS GROUP WITH RESTLESS DEVELOPMENT YOUTH
              RESEARCHERS, 2021
            </>
          )}
          >
            Mostly, all education decisions are taken by governments.
          </Quote>
        </div>
      </section>
      <section className="section">
        <QuestionsCarousel questions={[
          'How well understood are the concepts of agency and co-agency in your context?',
          'What tangible opportunities exist for educators and learners to influence the education system? ',
          'How do learners shape their own learning and how are educators supported to innovate?',
          'How does your education system support, learn from and share local innovations?',
        ]}
        />
      </section>

      <section className="section content-section">

        <ActionBox type="power">
          Support a wide range of stakeholders to take part in decision making
        </ActionBox>
        <div>
          <p>
            Learning is essentially a lifelong endeavor, where employers,
            community members, as well as young people and educators shape an
            ever-expanding curriculum.
            <Reference id={82} />
          </p>

          <p>
            The pandemic shattered the illusion that schools and colleges are
            the only places where learning happens, and politicians,
            administrators and experts are the only people who can make
            decisions about learning.
          </p>

          <p>
            Of course this was never actually the case, but it took Covid to
            break formal education’s near monopoly on access to learning
            expertise and resources, which had previously served in many places
            to marginalise home, peer and community learning and other informal
            learning opportunities.
            <Reference id={83} />
          </p>

          <p>
            When schools closed, some system leaders scrambled to make digital
            technology and remote learning resources universally available, many for the
            very first time.
            <Reference id={85} />
          </p>

          <p>
            Meanwhile parents - frequently overlooked protagonists in education
            <Reference id={86} />
            {' '}
            -
            and educators began to share information and to innovate together, not just
            schooling, but how to work, parent, and practise physical and mental
            well-being, as well as support their children’s learning.
            <Reference id={87} />
          </p>

          <p>
            In communities where learners did not have access to remote learning,
            parents and educators worked together to ensure learners’ fundamental
            safety, wellbeing and access to basic nutrition.
            <Reference id={88} />
          </p>

          <p>
            Elsewhere, institutions, community groups, tech companies and civic
            organisations mobilised resources as part of a broad and impressive
            humanitarian effort which, as well as providing laptops and data for
            online schooling, also created a plethora of new content and
            learning opportunities far beyond core curriculum.
            <Reference id={89} />
          </p>

          <p>
            Communities coming together demonstrated what happens when a wider
            group of people with new ideas, resources, opportunity and need are
            given the chance to reimagine learning, and when power is divested
            from the highest levels of government to the local level, where
            there is better understanding of what the needs are and what
            solutions can be workable.
            <Reference id={90} />
          </p>

          <p>
            It is striking how diverse learning became in communities where
            parents, learners and educators had the scope, conditions and
            resources to co-design learning outside of the constraints of their
            formal education systems. In addition to remote schooling there
            appeared powerful, complementary learning opportunities that
            connected children and communities through film-making, storytelling
            and exhibitions of artwork in house and apartment windows; in online
            choirs, bands and orchestras; in no-contact treasure hunts and other
            outdoor activities; in yoga, mindfulness and health and fitness
            classes; in bake-offs and craft clubs and so on.
            <Reference id={91} />
          </p>

          <p>
            However, just as with access to remote schooling, these rich and
            engaging opportunities were really only available to the more
            privileged learners, those for example with access to outside space
            and with parents or family working from home or furloughed.
          </p>

          <p>
            This serves as a critical reminder that developments that seek to
            engage parents and communities in education must be cognisant of the
            need to grow parents’ opportunities and confidence to help their
            child learn, to avoid exacerbating further global learning
            inequities to the detriment of the most vulnerable.
            <Reference id={92} />
          </p>

          <p>
            If new and different people are to be involved in learning design
            and decision making, new and different structures and tools are also
            required, which enable, for instance, learners and parents to share
            their ideas, views and experiences as part of a broader, more
            inclusive debate about education transformation. For example, young
            people who have dropped out of formal education have a very specific
            perspective and experience to offer and might helpfully be included
            in an expanded group of stakeholders to enrich decision making,
            <Reference id={94} />
            {' '}
            but rarely are.
          </p>

          <p>
            Globally, strategies that go beyond engaging parents and communities
            in improving existing education systems are few and far between.
            However there are successful examples, for instance parent-focused
            policy development in Juazeiro do Norte, Brazil; community working
            groups for educational planning in British Columbia, Canada; and
            Remake Learning Days, regular parent-focused learning festivals in
            the USA.
            <Reference id={95} />
            <Reference id={96} />
          </p>

          <p>
            Successful engagement strategies consider both context - parents’
            attitudes to engaging with learning and education vary according to
            culture and opportunity
            <Reference id={97} />
            {' '}
            - and gender, ethnic, socio-economic class, and other divisions of
            power and privilege, to continually check that everyone who wants to
            can take part, and to avoid reinforcing existing inequalities.
          </p>
        </div>
        <div>
          <YouTubePlayer id="cEO8YrHaWHA" />
          <YouTubePlayer id="9vXVWOCTGOw" />
          <Quote credit={(
            <>
              Populace, 2019
              <Reference id={84} />
            </>
          )}
          >
            In the face of a challenge on the scale of COVID-19, “robust systems
            don’t sequester solution-making power; they share it.”
          </Quote>
          <Quote credit={(
            <>
              Rebecca Winthrop et al, 2021
              <Reference id={95} />
            </>
          )}
          >
            Family-school engagement strategies that seek to redefine the
            purpose of school require both school personnel and families to come
            to the table in a designing role where each set of actors can
            provide their perspectives and views. It will be difficult to
            redefine the purpose of school without ensuring that families have
            the voice and agency needed to join school professionals in a
            co-creation process.
          </Quote>
          <Quote credit={(
            <>
              BIG CHANGE FOCUS GROUP WITH RESTLESS DEVELOPMENT YOUTH
              RESEARCHERS, 2021
            </>
          )}
          >
            Students should also be asked to be involved in decision making
            since the decision is being made about them.
          </Quote>
          <Quote credit={(
            <>
              BIG CHANGE FOCUS GROUP WITH RESTLESS DEVELOPMENT YOUTH
              RESEARCHERS, 2021
            </>
          )}
          >
            Education as a whole does not often get the priority it needs among
            government departments, in terms of resources that are allocated.
            Thus, teachers are often poorly paid in comparison to other
            professionals and schools do not have the learning resources they
            need.
          </Quote>
          <Quote credit="Jesse Williams, Rekindle">
            So we made sure that everything we did, young people remain at the
            heart of it - we invert the current power structures.
          </Quote>
        </div>
      </section>
      <section className="section">
        <QuestionsCarousel questions={[
          'What opportunities and support exist for parents and communities to take a role in making decisions about learning and education?',
          'How inclusive are these opportunities? Who typically takes part and who does not?',
          'Who might need to share or relinquish power to enable more inclusive decision making in your education system?',
        ]}
        />
      </section>
      <section className="section content-section">

        <ActionBox type="power">
          Make data and information more accessible, useful and interesting to
          stakeholders
        </ActionBox>
        <div>
          <p>
            It is impossible to make well-informed decisions without the full
            range of relevant data and information available, which is what
            happens when only people and organisations invested in the system’s
            status quo commission and accumulate data and have access to it.
            <Reference id={99} />
          </p>

          <p>
            Making data and information more widely available is an excellent
            first step; however to become useful and actionable, these have to
            be interpreted, explained and integrated in decision making
            processes in ways that drive learning and that are meaningful and
            accessible for those taking part. Most education data are not
            usually made public, are not disaggregated, and are not in formats
            that are user friendly.
            <Reference id={100} />
          </p>

          <p>
            Further, if we are to empower stakeholders, we need to expand the
            range of data and information that is available to them. Parents may
            receive information about their own children’s attainment using a
            very limited range of indicators, most often abstracted grades or
            levels in unrelated subjects and performance in unseen tests. In
            some countries, communities may be able to find data about the
            performance of their local school, again using test results as the
            main indicator of success or failure in any given reporting period.
          </p>

          <p>
            To make informed and confident decisions, stakeholders might
            reasonably ask questions that data like these cannot possibly
            answer; questions that relate to their values and priorities. How
            happy/safe/&#8203;confident are our children at school? How
            resilient/&#8203;creative/adaptive/&#8203;entrepreneurial
            etc will they become? How is our school preparing our children for
            the challenges and opportunities of the future? Are they learning
            what they need to thrive? Is our school fair? Are all our children
            able to fulfil their individual and collective potential? And so on.
          </p>

          <p>
            We also need to expand the horizons of those making decisions by
            connecting with people and places where learning is being reimagined
            and education systems transformed. It is hard to imagine a radically
            different future without being able to see and understand what
            transformed learning and education look like.
          </p>

          <p>
            So it is unsurprising that, on the rare occasions when they are
            invited to imagine a different future for learning and education,
            parents and communities; learners and educators can struggle to see
            beyond a better, more effective, more technologically enabled
            version of the learning and education they currently experience.
          </p>
        </div>
        <div>
          <YouTubePlayer id="JVNbzvF5idE" />
          <Quote credit={(
            <>
              Winthrop et al., 2021
              <Reference id={100} />
            </>
          )}
          >
            Using digital technology to shape data collection
            and data sharing can place more power in the hands of more people.
          </Quote>
          <Quote credit={(
            <>
              Richard Culatta, International Society for Technology in
              Education, 2021
              <Reference id={101} />
            </>
          )}
          >
            Educators, parents and learners don’t have the same privileged
            access as researchers and policy makers to the huge range of
            learning innovations and brilliant uses of technology that are out
            there.  They need opportunities to see what amazing looks like - and
            then let’s talk about what the future of education should be.
          </Quote>
        </div>
      </section>
      <section className="section">
        <QuestionsCarousel questions={[
          'Who decides what data and information are collected, shared and used?',
          'What role do data and information play in accountability and in maintaining the existing system?',
          'What more could be done in your context to make data and information necessary for decision making available to a wider group of stakeholders?',
        ]}
        />
      </section>
      <section className="section content-section">
        <RevealModal
          title="How Escuela Nueva from Colombia enables students, educators, and
          communities to exercise power"
          lead="Real World Story"
        >

          <p>
            Escuela Nueva’s approach empowers teachers and students to be the
            key actors of change. The program is built on a bottom-up approach,
            with teachers, parents, and students as decision-makers to shape the
            learning process. A cornerstone of Escuela Nueva schools is
            authentic student governance: the incorporation of student’s voices
            into the administration and operation of schools.
          </p>

          <RevealModalText image="/images/escuela-nueva.jpg">
            <p className="context">
              <a
                href="https://escuelanueva.org/"
                target="_blank"
                rel="noreferrer"
              >
                Escuela Nueva

              </a>
              {' '}
              (New School) was designed in the mid-1970s to improve
              education in rural Colombia. At that time, more than 50 percent of
              rural children never attended school.
              <Reference id={103} />
              {' '}
              What started as a small-scale initiative to create a new model of
              teaching and learning, expanded to 8,000 schools in the 1980s,
              20,000 schools in the 1990s and Escuala Nueva’s student-empowering
              model is now used in 19 countries from Latin America to Asia.
            </p>
            <p>
              The underlying premise of student agency existed in Escuela
              Nueva’s early days. Vicky Colbert, coauthor and initiator of the
              program, explains: &quot;The basic idea was to transform the
              conventional teacher-centered schooling practices into a
              learning-centered model that would integrate curriculum, teacher
              training, community involvement and administrative strategies in a
              systemic and cost-effective way.&quot;
              <Reference id={104} />
            </p>

            <p>
              The program developed and implemented a range of methods to create
              space for students, and communities to exert their power.
              <Reference id={105} />
              {' '}
              Teachers act as learning guides and facilitators rather than a
              unique source of knowledge. They use learning guides to facilitate
              a student-centered classroom environment and operationalize a
              child-centered, participatory learning approach including
              self-paced student learning, self-assessment, and flexible
              promotion. Escuela Nueva also encourages high community
              involvement and engagement through a holistic, and collaborative
              approach.
            </p>

            <p>
              “Rather than being run as a mini dictatorship, with the principal
              as its unquestioned leader, the school operates as a self
              governing community, where teachers, parents, and students have a
              real say in how it is run.”
              <Reference id={106} />
              {' '}
              David L. Kirp, 2015
            </p>

            <p>
              In 2002, a Unesco study found Colombia to be the only country
              where rural schools outperformed urban schools.
              <Reference id={107} />
            </p>
          </RevealModalText>
        </RevealModal>

        <RevealModal
          title="How Kids Education Revolution gives control and decision making
            power to young people in India"
          lead="Real World Story"
        >

          <p>
            Kids Education Revolution believes that education partnership is
            created when educators and students work at ‘eye level with each
            other’.
            <Reference id={110} />
            Control and decision-making power are shifted from adults to
            students, enabling student involvement in key questions such as
            purpose (why education matters), practice (how education should be
            delivered), and school governance (how schools should be run).
          </p>

          <RevealModalText image="/images/ker.jpg">
            <p className="context">
              When children voiced their discontent about
              India’s education system, Shaheen Mistri founded
              {' '}
              <a
                href="https://www.kidseducationrevolution.org/"
                target="_blank"
                rel="noreferrer"
              >
                Kids Education Revolution

              </a>
              (KER).
              <Reference id={108} />
              {' '}
              A collective of students and educators formed, united by the
              vision to reimagine education to create a better world. What
              started 2017 in India is now active in 11 cities across 9
              countries with more than 37,000 student revolutionaries and
              educators.
              <Reference id={109} />
            </p>

            <p>
              The KER movement drives change through student leadership. KER
              offers a platform where students and educators explore three
              principles:
            </p>

            <p><b>Safe Space for Voice</b></p>
            <p>
              Kids can safely express their identity and beliefs. Teachers build
              safe spaces in classrooms through learning circles, active
              listening, including literature and theatre for
              {' '}
              <b>potential</b>
              {' '}
              discovery, and learn to learn from students’ projects.
            </p>

            <ol>
              <li>
                <b>Kids and Educators as Partners</b>
                <br />
                On the basis of respect, reciprocity and shared responsibility,
                students and teachers groups learn from each other. They build
                partnerships through student advisory committees, student
                involvement in class planning and execution, student-as-teacher
                buddy systems, and inviting students as contributors to regional
                and national platforms.
              </li>
              <li>
                <b>Kids as Changemakers</b>
                <br />
                Students operate among the “I CAN mindset” and find solutions
                for self-identified problems. Some approaches to support
                students in changemaking projects include design for change
                models, problem-solving platforms, and scalable creative outlets
                such as slam poetry events.
              </li>
            </ol>

            <p>
              More KER formats bring these principles to life, such as
              peer-learning, global KER summits, national student-led education
              conferences, and student advisory councils. A student says: “It’s
              about equal contribution between adults and us as kids. It should
              be in our hands what we learn.”
              <Reference id={111} />
            </p>

          </RevealModalText>

        </RevealModal>

        <RevealModal
          title="How African Leadership Group is empowering 3 million young
            adults to become ethical leaders who can solve society's challenges
            with creativity"
          lead="Real World Story"
        >

          <p>
            To equip and harness the potential of the young African workforce as
            leaders and innovators, the African Leadership Group utilizes an
            experiential, technology-enabled, peer-to-peer learning model.
            Rooted in the purpose of education to develop ethical, creative, and
            courageous leaders who can solve grand challenges the world faces,
            the African Leadership Group shifts focus to the learner. For
            example, learners actively shape the learning and include support
            from the broader community.
          </p>
          <RevealModalText image="/images/african-leadership.jpg">
            <p className="context">
              The
              {' '}
              <a
                href="https://algroup.org/"
                target="_blank"
                rel="noreferrer"
              >
                African Leadership Group

              </a>
              {' '}
              (ALG) is an ecosystem of legally independent entities with a
              shared vision for transforming Africa by developing 3 million
              ethical, entrepreneurial leaders by 2035. For over 15 years, the
              African Leadership Group has been working with a model that
              empowers Africa’s youth through focus on potential, effective
              practices, and a strong network of leaders and amplifiers.
            </p>
            <p>
              Specifically, learner ownership means, enabling them to design
              their own courses of study, with a focus on learning in the
              classroom (10%), learning from peers (20%), and learning from
              experience (70%), i.e. learning by doing. They connect these
              learners with a broader community of coaches and mentors who
              provide funding, resources, and connections which help learners
              learn lessons helpful to the challenges they have chosen to
              tackle. ALG acknowledges that these are not the usual foci of
              traditional education systems that invest more heavily in
              academics and classroom learning, and less so on practice and
              mentoring.
            </p>

            <p>
              ALG has overturned the assumption that universities or learning
              organizations need to be built around access to knowledge. They
              have embraced the reality that “A child in Africa with a phone has
              more access to information than someone at a university doing
              their phd 30 years ago” and have given more agency to learners to
              drive their own learning, including learning from each other. The
              results speak for themselves; graduates of ALG have earned
              internships and jobs in the world’s most prestigious universities
              and workplaces, such as Harvard, Yale, Oxford, Google, L’Oreal,
              and fellowships such as the Rhodes and Schwartzman scholarships.
              In fact, the newest evolved model for the African Leadership
              University has done this at a fraction of the cost of a
              traditional university, and has expanded access to millions of
              young people.
            </p>
          </RevealModalText>
        </RevealModal>
      </section>
      <section
        className="section content-section"
        data-section-title="Driver 3: Practice"
      >
        <DriverSectionIntro
          title="Practice"
          description="Unlock innovation by enabling those working in education to create and share learning innovations with the greatest potential to transform the system"
          subtitle="Unlock innovation"
        />
        <ActionBox type="practice">
          Make learning more learner centred
        </ActionBox>
        <div>

          <p>
            When the learner is at the centre, it is their strengths and needs,
            passions and interests that become the focus for transformation,
            wherever that learner comes from and whatever system they are in.
          </p>

          <p>
            This vision for learner-centredness goes far beyond traditional
            practices of differentiation or personalisation. It works at the
            level of the individual classroom, school and whole education
            system. It both empowers ambitious and creative educators to make
            their practice more learner centred in ways that are rewarding for
            learners and educators, and it challenges decision makers at the
            system level to transform system structures and processes to serve
            the needs of all learners.
          </p>
          <p>
            Learner-centredness is disruptive and has the potential to be
            transformative because it turns the standardised model of schooling
            on its head, challenges the assumptions that sit at the heart of
            education systems and shines a light on the inequality that is baked
            into them.
          </p>
        </div>
        <div>
          <Quote credit={(
            <>
              Education Reimagined, 2015
              <Reference id={112} />
            </>
          )}
          >
            Learners are wondrous, curious individuals with vast capabilities
            and limitless potential. They are active participants in their
            learning as they gradually become owners of it, and learning itself
            becomes an engaging and exciting process. Each child’s interests,
            passions, dreams, skills and needs shape their learning experience
            and drive the commitments and actions of the adults and communities
            supporting them.

          </Quote>
          <Quote credit={(
            <>
              Global Learning Lab, 2021
              <Reference id={113} />
            </>
          )}
          >
            Our education systems were built to serve a relatively small group
            of privileged children. They have been used to perpetuate divisions
            of power and privilege, through a history of colonisation and
            assimilation. They embrace the values and practices of a
            standardised, factory model of efficient production.
          </Quote>
        </div>
      </section>
      <section className="section">
        <QuestionsCarousel questions={[
          'Who or what is the current system centring on?',
          'What would change in your context if learning was truly organised around the needs of learners?',
          'In your community, what does/could learner centredness look like?',
          'What are the barriers to and/or opportunities for making learning more learner centred?',
        ]}
        />

      </section>
      <section className="section content-section">
        <ActionBox type="practice">
          Integrate new ways to assess and recognise learning
        </ActionBox>
        <div>
          <p>
            External assessment and accreditation are high leverage points of
            intervention in transforming education systems because they
            disproportionately affect the learning opportunities that are
            available to young people in school. In a phenomenon that has been
            called ‘teaching to the test’ curricula can quickly become
            unbalanced as externally examined subjects take precedence in
            timetables and private study, particularly where these feature as
            indicators on which school performance is judged. The outcomes of
            assessment and accreditation also have the potential to limit access
            to future learning and career opportunities for young people who do
            not ‘pass the test.’
          </p>
          <p>
            Since external assessment and accreditation are powerful drivers in
            education systems, they are also a force that can be harnessed to
            ensure that they drive the system in the ‘right’ direction; serving
            the purposes of learning and education determined by stakeholders.
          </p>

          <p>
            New forms of assessment are in widespread use that privilege learner
            self assessment, involve evaluation by external e.g. industry
            experts or community leaders, and result in portfolios that evidence
            both development of competencies over time and diverse knowledge and
            skills.
            <Reference id={115} />
          </p>
          <p>
            Technology is also available that can do quick “how are you doing?”
            checks; individualised micro assessments that provide rapid feedback
            to help the learning process along.
            <Reference id={116} />
          </p>

          <p>
            Innovation in standardised tests means that it will be possible, in
            the next few years, to look way beyond core competencies to
            evaluate, evidence and benchmark a wider range of skills:
            collaborative problem solving; creative thinking; global competence;
            and learning in the digital world.
            <Reference id={117} />
          </p>
          <p>
            By integrating a wider range of assessments and ways to recognise
            learning into the learning process, assessment and accreditation can
            be used to help close equity gaps and to accelerate transformation.
          </p>
        </div>
        <div>
          <YouTubePlayer id="VzGkQQLzSqY" />
          <Quote credit={(
            <>
              Rethinking Assessment, 2020
              <Reference id={114} />
            </>
          )}
          >
            High-stakes tests require a high degree of validity and reliability.
            This limits the kind of content that can be assessed because many
            kinds of valuable capabilities are difficult to assess reliably at
            scale. If we allow the curriculum to be determined by the design of
            these tests, we end up with an excessively narrow education.
          </Quote>
          <Quote credit={(
            <>
              BIG CHANGE FOCUS GROUP WITH RESTLESS DEVELOPMENT YOUTH
              RESEARCHERS, 2021
            </>
          )}
          >
            We need to learn to ‘do,’ not just ‘know’ and test well.
          </Quote>
          <Quote credit={(
            <>
              BIG CHANGE FOCUS GROUP WITH RESTLESS DEVELOPMENT YOUTH
              RESEARCHERS, 2021
            </>
          )}
          >
            Why not think about the actual skills kids and young people need for
            their future? We need knowledge that we can apply in our daily
            lives.
          </Quote>
        </div>
      </section>
      <section className="section">
        <QuestionsCarousel questions={[
          'What is the purpose of assessment in your context and who benefits from it?',
          'How wide is the range of assessments and forms of accreditation used in your context?',
          'How well is assessment integrated into learning to empower learners and educators?',
          'What role might technology play in integrating assessment and learning?',
        ]}
        />
      </section>
      <section className="section content-section">
        <ActionBox type="practice">
          Strengthen the link between learning science and teaching practice
        </ActionBox>
        <div>
          <p>
            What if we knew, with evidence, not just with belief, that all
            children can learn and thrive?
          </p>

          <p>
            What if educators were supported to learn from science to design new
            learning opportunities that attend to up-to-date insight into how
            young people learn, develop, grow, and master complex skills?
          </p>

          <p>
            The science of learning builds on cognitive psychology,
            neuroscience, brain research and social psychology and offers new
            opportunities for transforming learning and education systems.
            <Reference id={119} />
          </p>
        </div>
        <div>
          <Quote credit={(
            <>
              BIG CHANGE FOCUS GROUP WITH RESTLESS DEVELOPMENT YOUTH
              RESEARCHERS, 2021
            </>
          )}
          >
            The educational curriculum for most countries is old and needs to be
            worked on to fit into the developments in the 21st century.
          </Quote>
        </div>
      </section>
      <section className="section">
        <RevealBox
          title="In recent years, learning science has shown that:"
        >
          <ol>
            <li>
              Every child, no matter their background, has the potential to
              succeed in school and life.
            </li>
            <li>
              No two young people learn in precisely the same ways.
            </li>
            <li>
              Children’s ability to learn is strongly linked to their social,
              emotional, cognitive, and physical needs.
            </li>
            <li>
              The environments, experiences, and cultures of a young person’s
              life are more influential than their genes.
            </li>
            <li>
              The human brain is remarkably malleable and can be changed by
              strong, supportive relationships and conditions they create.
            </li>
          </ol>
          <p className="paragraph-right">
            Science of Learning and Development Alliance, 2020
          </p>
        </RevealBox>
      </section>
      <section className="section content-section">
        <div>
          <p>
            There is also growing evidence that learning is enhanced when
            learners are given opportunity to build on existing knowledge,
            <Reference id={121} />
            {' '}
            that learner mindset is associated with academic success,
            <Reference id={122} />
            {' '}
            that learners need to feel seen, safe, and valued to learn,
            <Reference id={123} />
            {' '}
            and that learners benefit from meaningful, challenging tasks.
            <Reference id={124} />
          </p>

          <p>
            Elsewhere, research has shown that social emotional skills such as
            achievement, motivation, responsibility, stress resistance and
            resilience, curiosity, and meta-cognition are predictive not only of
            academic outcomes, but life outcomes.
            <Reference id={125} />
          </p>

          <p>
            COVID-19 reinforced the scientific link between the ability to learn
            and mental and emotional wellness. In some G20 countries, the number
            of young people experiencing symptoms of mental ill-health more than
            doubled during the COVID-10 crisis.
            <Reference id={126} />
            {' '}
            prompting some jurisdictions to respond by increasing investments in
            social emotional learning.
          </p>

          <p>
            Conversely some popular ideas in education, for example, have been
            found to have little to no evidence. For example, learning styles is
            a mainstream idea, but there is no evidence that matching learning
            activities to one’s so-called learning style improves learning.
            <Reference id={127} />
            {' '}
            What if learning practice was informed by evidence?
          </p>
        </div>
        <div>
          <Quote credit={(
            <>
              Andreas Schleicher, 2021
              <Reference id={118} />
            </>
          )}
          >
            One of the important domains of scientific research into human
            learning is that of the interplay between cognitive development,
            achievement motivation, growth mindset and well-being…. Wellbeing is
            now seen as shaping the social and emotional conditions for learning
            to be effective and sustainable. When the social and psychological
            climate is not optimal, learning will suffer.
          </Quote>
          <Quote credit={(
            <>
              BIG CHANGE FOCUS GROUP WITH RESTLESS DEVELOPMENT YOUTH
              RESEARCHERS, 2021
            </>
          )}
          >
            Learning is mostly exam driven and it is not practical nor engaging.
          </Quote>
          <Quote credit={(
            <>
              BIG CHANGE FOCUS GROUP WITH RESTLESS DEVELOPMENT YOUTH
              RESEARCHERS, 2021
            </>
          )}
          >
            We need more opportunities to undertake inquiry in different
            subjects through fieldwork, experimentation, and art.
          </Quote>
        </div>
      </section>
      <section className="section">
        <QuestionsCarousel
          questions={[
            'What opportunities exist for educators, learners and families to learn about the latest developments in learning science?',
            'To what extent does evidence from learning science inform education policy and practice?',
            'What incentives and support could enable educators to work together to use evidence from learning science to develop their practice?',
          ]}
        />
      </section>
      <section className="section content-section">
        <RevealModal
          title="How Agora Schools enable young people to lead learning in the
          Netherlands"
          lead="Real World Story"
        >

          <p>
            Agora is a school focused on learning, not teaching. Students have
            freedom and self-ownership about their learning process - a skill
            useful far beyond their time at school. Students say what they like
            best about Agora is &quot;the freedom to explore and learn whatever
            they want.&quot;
            <Reference id={133} />
          </p>

          <RevealModalText image="/images/agora.jpg">
            <p className="context">
              <a
                href="https://hundred.org/en/innovations/agora#719c11e4"
                target="_blank"
                rel="noreferrer"
              >
                Agora’s
              </a>
              {' '}
              practice breaks with conventional education systems. In 2014,
              Agora started to reimagine education practices with 34 students
              within an existing school.
              <Reference id={129} />
              {' '}
              In the creation process, the students, rather than adults,
              determined the learning environment and practices.
              <Reference id={130} />
              {' '}
              Scaled by four education innovators, 15 Agora schools exist in the
              Netherlands and Belgium.
              <Reference id={131} />
              <Reference id={132} />
            </p>
            <p>
              A day at Agora is different from traditional brick-and-mortar
              schools. Classrooms feel like co-working spaces, kids aren’t
              badged by age groups but mixed through ages and backgrounds, and
              there’s no hour-to-hour subject change. Unlike fixed curriculums
              and learning objectives set by teachers, students at Agora set
              their own learning objectives.
            </p>

            <p>
              A student’s day starts with answering the question “What do you
              want to learn today?” The body of students will then help
              determine whether this learning goal is achievable in the set time
              span. After this initial 30-minute start of the day, students
              follow their individual agenda. Personal coaches support and
              supervise the student’s learning process.
            </p>

            <p>
              When a student researches geography and stops her research at the
              Flat Earth Society, a coach will ask questions about how to know
              where opinions come from and encourage her to look for more
              sources and find citations.
              <Reference id={134} />
              {' '}
              Coaches, not teachers, challenge and guide learning processes.
            </p>

            <p>
              Yet, there is still a structure behind learning. Students need to
              encode learning goals in achievable tasks with a tangible outcome
              such as a report, video, or wiki. The learning outcomes are
              assessed by coaches and presented to the student body, so
              everybody else can learn from it. Agora schools demonstrate how a
              children-led education design can drastically transform education
              practices.
            </p>
          </RevealModalText>
        </RevealModal>
        <RevealModal
          title="How MUSE Schools from California diversify assessment around
          learners’ needs"
          lead="Real World Story"
        >

          <p>
            MUSE schools break with traditional assessment and instead, use
            evaluation as a tool for goal-setting, self-reflection, and
            motivation. Jeff King, the school’s director, said in an interview:
            “The primary objective is to give the students an educational
            platform that is holistic.’ This is achieved through a focus on
            self-efficacy, communication tools, passion-based learning, and
            ‘BLUEPRINTS.”
            <Reference id={136} />
          </p>
          <RevealModalText image="/images/muse.jpg">
            <p className="context">
              <a
                href="https://hundred.org/en/innovations/blueprints"
                target="_blank"
                rel="noreferrer"
              >
                MUSE

              </a>
              {' '}
              schools innovate traditional assessment practices by placing the
              student at its center. Founded in 2006, MUSE Global School is a
              PK–12 co-educational private school in California. More recently,
              MUSE school franchised its concept for a global expansion of
              student-centered, sustainability-focused learning.
              <Reference id={135} />
            </p>
            <p>
              At the beginning of each semester, students set individual
              learning goals. Advisors and administrators assist in the process,
              so that young people derive a learning path that matches their
              plans for the future. Throughout the semester, students set a
              short term goal for each day, and review it at the end of the day.
            </p>

            <p>
              At the end of the semester, student progress and milestones are
              narrated in the BLUEPRINTS. Instead of grades and percentages,
              they show each learner’s individual learning journey. Moreover,
              these records provide a clear learning trajectory for the student
              to explore their passions while developing socially, emotionally
              and academically.
              <Reference id={137} />
            </p>

            <p>
              King explains the assesment’s effect for students: “They’re
              engaged and respected and honored for who they are, they are
              taught to believe in themselves and not to compare themselves to
              other people but only to themselves. It’s OK to be who they are
              and they don’t have to fit in a box.”
              <Reference id={138} />
              {' '}
              A student says: “I always felt supported in who I am, and what I
              wanted to do, and where I wanted to go. Without the guidance of
              the faculty-both in and out of class-I would not be where I am,
              nor nearly as happy.”
              <Reference id={139} />
            </p>

            <p>
              MUSE’s assessment approach puts the individual learner’s
              capabilities and aspirations at its center and illustrates the
              upsides of going beyond a narrow, standardized snapshot of a
              student’s abilities.
            </p>
          </RevealModalText>
        </RevealModal>
      </section>
      <div data-section-title="Take Action">
        <TakeActionBox />
      </div>
      <div data-section-title="Contributors" className="partners">
        <h2>Partners and Contributors</h2>
        <PartnersSection />
      </div>
      <section className="section">
        { References }
      </section>
      <section className="section bottom-section">
        <Logos />
      </section>
      <div className="bottom-page-blades">
        <svg
          viewBox="0 0 122.7 57.3"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#0086b5"
            d="M96.6 153.5s-31 15.4-43.1 51.9c.5 1.3 1.6 3 3.4 5h72.6z"
            transform="translate(-53.5 -153.2)"
          />
          <path
            fill="#ff8001"
            d="M138.2 157.3c-7 0-36.6 1-61.8 23.5-.7 4.2 2.3 15.6 8.7
               29.7h54.7v-53.1h-1.6z"
            transform="translate(-53.5 -153.2)"
          />
          <path
            fill="#82027e"
            d="M14.9 307c-15.3-.3-31.3-1.9-48-5.4C-41.8 291.4-51.2 254-55
               204h172.7l37.7 65.3S97.2 308.1 14.9 307z"
            transform="matrix(.35278 0 0 -.35278 67.8 129.3)"
          />
        </svg>
      </div>
    </Layout>
  </>
);

export default IndexPage;
