import React, { useState } from 'react';

import './reveal-box.scss';

interface RevealBoxProps {
  title: string
  small?: boolean
  children: React.ReactNode
}

const RevealBox = ({ title, small, children }: RevealBoxProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`reveal-box-wrapper ${isOpen ? ' is-open' : ''}`}
    >
      <section className={`reveal-box${small ? ' reveal-box-small' : ''}`}>
        <div className="reveal-box-inner">
          <header className="reveal-box-header">
            <h3 className="reveal-box-header-title">{title}</h3>
          </header>
          <article className="reveal-box-content">
            <div className="reveal-box-text">
              {children}
            </div>
          </article>
          <button
            type="button"
            className="reveal-box-button"
            onClick={() => setIsOpen(!isOpen)}
          >
            { isOpen ? 'Close' : 'Read More' }
          </button>
        </div>
      </section>
    </div>
  );
};

RevealBox.defaultProps = {
  small: false,
};

export default RevealBox;
