import React from 'react';
import ShareWidget from '../share-widget';
import ShowWhenRevealed from '../show-when-revealed';

import './quote.scss';

const CHARACTER_LIMIT = 280 - 24;
interface QuoteProps {
  children: React.ReactNode
  credit: string | React.ReactNode
  showThreshold?: number
}

const Quote = ({ children, credit, showThreshold } : QuoteProps) => (
  <ShowWhenRevealed threshold={showThreshold}>
    <figure className="quote">
      <blockquote className="quote-blockquote">
        {children}
      </blockquote>
      <figcaption className="quote-figcaption">{credit}</figcaption>
      { children.toString().length < CHARACTER_LIMIT ? (
        <ShareWidget text={children.toString()} />
      ) : '' }
    </figure>
  </ShowWhenRevealed>
);

Quote.defaultProps = {
  showThreshold: 0.333,
};

export default Quote;
