import React from 'react';

import './hexagon.scss';

interface HexagonProps {
  fill?: string
  stroke?: string
  className?: string
}

const Hexagon = ({ fill, stroke, className } : HexagonProps) => (
  <svg
    className={`hexagon${className ? ` ${className}` : ''}`}
    fill={fill}
    style={{ color: stroke }}
    viewBox="0 0 40 46"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m29.4 6.6 9.5 5.5V34l-9.5 5.4-9.4 5.4-9.4-5.4L1 33.9V12l9.5-5.4L20
         1.2l9.4 5.4Z"
      stroke={stroke}
      strokeWidth={2}
    />

  </svg>
);

Hexagon.defaultProps = {
  fill: 'transparent',
  stroke: '#fff',
  className: '',
};

export default Hexagon;
