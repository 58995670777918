import React from 'react';
import useIsRevealed from '../../utils/use-is-revealed';

import './propeller-with-labels.scss';

const transformMatrix = 'matrix(.35278 0 0 .35278 -103.17 -96.05)';

const PropellerWithLabels = () => {
  const [ref, isRevealed] = useIsRevealed({ threshold: 0.5 });
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 138.85 139.5"
      className={`propeller-with-labels${isRevealed ? ' is-visible' : ''}`}
      ref={ref}
    >
      <defs>
        <clipPath id="a">
          <path d="M0 0h1190.55v372H0Zm0 0" />
        </clipPath>
      </defs>
      <use
        xlinkHref="#b"
        width="100%"
        height="100%"
        x="996.21"
        y="489.7"
        fill="#0085b3"
        transform={transformMatrix}
      />
      <use
        xlinkHref="#c"
        width="100%"
        height="100%"
        x="994.53"
        y="712.68"
        fill="#ff7f00"
        transform={transformMatrix}
      />
      <use
        xlinkHref="#d"
        width="100%"
        height="100%"
        x="328.7"
        y="70.62"
        fill="#ff7f00"
        transform={transformMatrix}
      />
      <use
        xlinkHref="#e"
        width="100%"
        height="100%"
        x="554.53"
        y="260.01"
        fill="#fff"
        transform={transformMatrix}
      />
      <use
        xlinkHref="#f"
        width="100%"
        height="100%"
        x="285.12"
        y="218.23"
        fill="#fff"
        transform={transformMatrix}
      />
      <use
        xlinkHref="#f"
        width="100%"
        height="100%"
        x="74.77"
        y="250.63"
        fill="#fff"
        transform={transformMatrix}
      />
      <use
        xlinkHref="#f"
        width="100%"
        height="100%"
        x="273.6"
        y="250.63"
        fill="#fff"
        transform={transformMatrix}
      />
      <use
        xlinkHref="#f"
        width="100%"
        height="100%"
        x="85.03"
        y="272.23"
        fill="#fff"
        transform={transformMatrix}
      />
      <use
        xlinkHref="#f"
        width="100%"
        height="100%"
        x="143.99"
        y="302.33"
        fill="#fff"
        transform={transformMatrix}
      />
      <use
        xlinkHref="#e"
        width="100%"
        height="100%"
        x="477.59"
        y="512.81"
        fill="#1e3045"
        transform={transformMatrix}
      />
      <use
        xlinkHref="#d"
        width="100%"
        height="100%"
        x="947.57"
        y="118.8"
        fill="#ff7f00"
        transform={transformMatrix}
      />
      <use
        xlinkHref="#f"
        width="100%"
        height="100%"
        x="871.84"
        y="207.43"
        fill="#fff"
        transform={transformMatrix}
      />
      <use
        xlinkHref="#f"
        width="100%"
        height="100%"
        x="800.27"
        y="269.93"
        fill="#fff"
        transform={transformMatrix}
      />
      <use
        xlinkHref="#f"
        width="100%"
        height="100%"
        x="883.38"
        y="269.93"
        fill="#fff"
        transform={transformMatrix}
      />
      <use
        xlinkHref="#f"
        width="100%"
        height="100%"
        x="859.93"
        y="354.03"
        fill="#fff"
        transform={transformMatrix}
      />
      <use
        xlinkHref="#f"
        width="100%"
        height="100%"
        x="998.31"
        y="237.53"
        fill="#fff"
        transform={transformMatrix}
      />
      <use
        xlinkHref="#f"
        width="100%"
        height="100%"
        x="1138.96"
        y="269.93"
        fill="#fff"
        transform={transformMatrix}
      />
      <path
        fill="#0085b3"
        d="M68.73 74.8V.03s-23.6-1.51-43.15 15.95c-1.19 6.47 10.5 36.8 32 58.82zm0
      0"
      />
      <path
        fill="#ff7f00"
        d="m65.04 77 64.76 37.4s13.1-19.68
      7.77-45.35c-5.02-4.26-37.13-9.3-66.95-1.7l-5.58 9.66"
      />
      <path
        fill="#7f007d"
        d="M64.75 72.7 0 110.1s10.49 21.18 35.39 29.4c6.2-2.22 26.62-27.5
      34.94-57.14l-5.58-9.65"
      />
      <g fill="#fff">
        <path d="m15.4 112.93.48-.13v-5.04l-.47-.12v-.12h2.2c.88 0 1.5.15
      1.88.43.38.3.57.71.57 1.27s-.19.98-.57 1.26c-.38.3-1
      .43-1.87.43h-.44v1.9l.48.12v.11H15.4zm2.22-2.21c.33 0
      .6-.13.8-.38.2-.25.3-.63.3-1.12 0-.5-.1-.86-.3-1.12a.97.97 0 0
      0-.8-.38h-.44v3zM20.65 112.93l.48-.13v-3.46l-.48-.12v-.12h.71c.31 0
      .55.1.74.3.06.08.12.18.17.3a1.39 1.39 0 0 1 1.17-.63c.28 0
      .49.06.63.19.14.12.2.3.2.55 0
      .18-.05.32-.16.43-.1.1-.25.16-.42.16l-.22-.01-.1-.03v-1.1c-.26
      0-.5.1-.73.31-.1.1-.2.21-.25.32v2.91l.47.13v.11h-2.2Zm0 0"
        />

      </g>
      <g fill="#fff">
        <path d="M26.01 113.16c-.47 0-.83-.1-1.08-.3a.93.93 0 0
      1-.38-.77c0-.2.05-.4.14-.6.03-.1.08-.18.14-.26a11.3 11.3 0 0 1
      2.2-.32v-.23c0-.59-.07-.99-.2-1.21-.15-.22-.37-.33-.66-.33-.1
      0-.2.01-.29.04l-.1.04v1.18l-.1.02-.22.02a.58.58 0 0 1-.43-.16.59.59 0 0
      1-.16-.43c0-.24.12-.45.36-.61.25-.17.61-.25 1.1-.25.4 0 .76.07
      1.05.21.3.14.53.34.68.6.16.25.24.55.24.88v2.12l.47.13v.11h-.7c-.33
      0-.57-.1-.74-.29a.83.83 0 0 1-.18-.3c-.07.13-.16.25-.26.35a1.2 1.2 0 0
      1-.88.36zm.32-.28c.19 0 .37-.1.52-.31.06-.08.12-.18.19-.32v-1.14a6.2 6.2 0
      0 0-1.03.08 2.2 2.2 0 0 0-.16.83c0 .3.05.5.13.65.1.14.2.21.35.21zM31.37
      113.16c-.67 0-1.2-.19-1.57-.55a2.07 2.07 0 0
      1-.56-1.54c0-.65.19-1.16.56-1.53.37-.37.9-.55 1.57-.55.48 0 .85.08
      1.09.25.25.16.37.37.37.61 0 .18-.06.32-.17.43-.1.1-.24.16-.42.16-.08 0-.15
      0-.22-.02l-.1-.02v-1.18l-.11-.04a.92.92 0 0 0-.28-.04c-.3
      0-.54.14-.7.43-.17.28-.25.78-.25 1.5 0 .7.08 1.2.25
      1.48.16.28.4.41.7.41a1.18 1.18 0 0 0 .99-.53c.1-.16.15-.32.15-.49h.2c0
      .2-.07.41-.2.6-.12.19-.3.34-.52.45-.23.11-.49.17-.78.17zM35.47 113.16c-.35
      0-.65-.07-.9-.19a1.38 1.38 0 0
      1-.8-1.27v-2.4h-.55v-.2h.56v-.4c0-.46.23-.7.7-.7h.55v1.1h1.11v.2h-1.1v2.4c0
      .48.04.82.14 1 .1.18.25.26.45.26.19 0
      .35-.09.49-.27.14-.18.21-.43.21-.75h.2c0 .39-.1.7-.29.9a1 1 0 0
      1-.77.32zM36.96 112.93l.48-.13v-3.46l-.48-.12v-.12H38c.47 0
      .71.24.71.71v3l.47.12v.11h-2.2Zm1.03-4.3a.55.55 0 0 1-.4-.16.55.55 0 0
      1-.15-.4c0-.15.05-.28.15-.39.1-.1.24-.16.4-.16.16 0
      .29.06.39.16.1.1.16.24.16.4a.54.54 0 0 1-.55.55zM41.77 113.16c-.67
      0-1.2-.19-1.57-.55a2.07 2.07 0 0
      1-.56-1.54c0-.65.19-1.16.56-1.53.37-.37.9-.55 1.57-.55.49 0 .85.08
      1.1.25.24.16.36.37.36.61 0 .18-.05.32-.16.43-.11.1-.25.16-.43.16-.07 0-.15
      0-.22-.02l-.1-.02v-1.18l-.1-.04a.92.92 0 0 0-.29-.04c-.3
      0-.53.14-.7.43-.17.28-.25.78-.25 1.5 0 .7.08 1.2.25
      1.48.17.28.4.41.7.41a1.18 1.18 0 0 0 .99-.53c.1-.16.15-.32.15-.49h.2c0
      .2-.06.41-.2.6-.12.19-.3.34-.52.45-.23.11-.49.17-.78.17zM45.95 113.16c-.68
      0-1.2-.19-1.57-.55a2.07 2.07 0 0
      1-.56-1.54c0-.42.08-.8.24-1.1.17-.32.4-.57.69-.73a2 2 0 0 1 1-.25c.38 0
      .71.07 1 .21.28.14.5.33.66.57a1.42 1.42 0 0 1 .2 1.13l-.05.13a12.03 12.03
      0 0 1-2.4.24c0 .6.08 1.04.25 1.3.17.27.4.4.7.4.2 0
      .4-.05.56-.15.18-.1.32-.23.42-.38.1-.17.16-.33.16-.5h.2c0
      .2-.07.41-.2.6-.12.19-.3.34-.53.45-.22.11-.48.17-.77.17zm-.8-2.09a6 6 0 0
      0 1.11-.08l.05-.17c.02-.13.03-.27.03-.42a2 2 0 0
      0-.17-.96c-.12-.2-.25-.3-.42-.3-.4 0-.6.64-.6 1.93zm0 0"
        />

      </g>
      <g fill="#fff">
        <path d="M24.75 120.37c-.17 0-.3-.03-.42-.08a.87.87 0 0 1-.47-.52 1.14
      1.14 0 0
      1-.05-.36v-1.51h.33v1.5l.03.24c.02.08.06.15.1.22.05.06.11.11.19.15.08.04.17.05.29.05.1
      0 .2-.01.28-.05.07-.04.13-.1.18-.15a.57.57 0 0 0 .1-.22.91.91 0 0 0
      .03-.24v-1.5h.34v1.51c0 .13-.02.25-.06.36a.86.86 0 0
      1-.46.52c-.12.05-.26.08-.41.08ZM28.28
      119.82v-1.92h.34v2.4h-.42l-1.27-1.96v1.97h-.34v-2.41h.44zM29.89
      120.01h1.07v.3h-1.41v-2.41h.34Zm0 0"
        />

      </g>
      <g fill="#fff">
        <path d="M33.95 119.1a1.29 1.29 0 0 1-.36.92 1.35 1.35 0 0 1-.91.35c-.19
      0-.36-.03-.52-.1a1.18 1.18 0 0
      1-.67-.66c-.06-.15-.09-.32-.09-.51s.03-.36.1-.51a1.13 1.13 0 0 1 .66-.66
      1.38 1.38 0 0 1 1.02
      0c.16.06.3.14.4.26.12.1.2.24.28.4.06.15.1.32.1.51zm-.36 0a1.02 1.02 0 0
      0-.25-.68.88.88 0 0 0-.66-.29.91.91 0 0 0-.38.08.81.81 0 0 0-.3.21 1 1 0 0
      0-.23.68c0
      .13.02.26.06.38.04.12.1.22.18.3.08.1.18.16.3.21.1.05.23.07.37.07a.85.85 0
      0 0 .66-.28.95.95 0 0 0 .18-.3c.05-.12.07-.25.07-.38ZM36.82
      119.93c-.1.13-.22.24-.38.32a1.38 1.38 0 0 1-1.06.03 1.22 1.22 0 0
      1-.4-.26c-.1-.12-.2-.25-.26-.4-.06-.16-.1-.33-.1-.52s.04-.36.1-.51a1.17
      1.17 0 0 1 .67-.66 1.38 1.38 0 0 1 1 0 .9.9 0 0 1 .38.28l-.26.2a.66.66 0 0
      0-.26-.2.75.75 0 0 0-.34-.07.91.91 0 0 0-.38.07.81.81 0 0 0-.29.21 1.02
      1.02 0 0 0-.25.68.98.98 0 0 0 .24.69c.08.09.18.16.3.2a.96.96 0 0 0 .74
      0c.11-.06.2-.14.28-.24zM37.83 118.96l1.08-1.06h.47l-1.17 1.1 1.24
      1.3h-.48l-1.13-1.24v1.25h-.34v-2.41h.33Zm0 0"
        />

      </g>
      <g fill="#fff">
        <path d="M19.73 124.39h-.34v-2.41h.34ZM22.35 123.9v-1.92h.34v2.4h-.42L21
      122.43h-.01v1.97h-.34v-2.41h.44zM25.32
      123.9v-1.92h.34v2.4h-.43l-1.27-1.96v1.97h-.34v-2.41h.44zM29.01 123.18a1.29
      1.29 0 0 1-.37.91c-.1.11-.24.2-.4.26a1.38 1.38 0 0 1-1.02 0 1.18 1.18 0 0
      1-.67-.66c-.06-.15-.09-.32-.09-.51s.03-.36.1-.51a1.13 1.13 0 0 1 .66-.66
      1.38 1.38 0 0 1 1.02 0c.16.06.3.14.4.25a1.29 1.29 0 0 1 .37.92zm-.36
      0a1.02 1.02 0 0 0-.25-.68.88.88 0 0 0-.66-.29.91.91 0 0 0-.39.08.81.81 0 0
      0-.28.21 1 1 0 0 0-.25.68c0
      .13.02.26.06.38.05.12.11.22.2.3.07.1.17.16.28.21.1.05.23.07.37.07a.85.85 0
      0 0 .66-.28.95.95 0 0 0 .2-.3c.04-.12.06-.25.06-.38Zm0 0"
        />

      </g>
      <path
        fill="#fff"
        d="m30.57 123.95.73-1.97h.36l-.94 2.4h-.31l-.94-2.4h.37zM32.29
      124.39h-.38l1.05-2.41h.3l1.04
      2.4h-.38l-.25-.58h-1.14Zm.35-.9h.9l-.44-1.12Zm0 0"
      />
      <g fill="#fff">
        <path d="M35.58 124.39h-.33v-2.12h-.78v-.3h1.88v.3h-.77zM37.35
      124.39H37v-2.41h.34ZM40.7 123.18a1.29 1.29 0 0
      1-.36.91c-.11.11-.25.2-.4.26a1.38 1.38 0 0 1-1.02 0 1.18 1.18 0 0
      1-.67-.66c-.07-.15-.1-.32-.1-.51s.03-.36.1-.51a1.13 1.13 0 0 1 .66-.66
      1.38 1.38 0 0 1 1.02
      0c.16.06.3.14.4.25.12.11.21.25.28.4.06.16.1.33.1.52zm-.36 0a1.02 1.02 0 0
      0-.25-.68.88.88 0 0 0-.66-.29.91.91 0 0 0-.38.08.81.81 0 0 0-.3.21 1 1 0 0
      0-.23.68c0
      .13.02.26.06.38.04.12.1.22.18.3.08.1.18.16.3.21.1.05.23.07.37.07a.85.85 0
      0 0 .66-.28.95.95 0 0 0 .19-.3c.04-.12.06-.25.06-.38ZM43.2
      123.9v-1.92h.34v2.4h-.43l-1.27-1.96v1.97h-.34v-2.41h.44zm0 0"
        />

      </g>
      <g fill="#fff">
        <path d="m32.72 21.5.47-.12v-5.04l-.47-.12v-.12h2.2c.88 0 1.5.15
      1.88.43.38.3.57.71.57 1.27s-.19.98-.57 1.26c-.38.3-1
      .43-1.87.43h-.44v1.9l.48.12v.11h-2.25zm2.2-2.2c.34 0
      .6-.13.8-.38.2-.25.31-.63.31-1.12 0-.5-.1-.86-.3-1.12a.97.97 0 0
      0-.8-.38h-.44v3zM39.85 21.74c-.3 0-.56-.06-.8-.18a1.48 1.48 0 0
      1-.54-.49c-.13-.2-.2-.4-.2-.63v-2.52l-.47-.12v-.12h1.03c.47 0
      .7.24.7.71v2.05c0 .33.06.59.18.76.12.18.26.26.42.26.2 0
      .37-.1.52-.31.06-.08.12-.18.19-.32v-2.91l-.48-.12v-.12h2.21v.12l-.47.12v3.74h-.08c-.38
      0-.68-.1-.87-.31a.82.82 0 0 1-.2-.32 1.21 1.21 0 0 1-1.15.7zM43.08
      21.5l.48-.12v-3.46l-.48-.12v-.12h.71c.31 0
      .56.1.74.3.06.08.12.18.17.29.08-.11.18-.22.29-.31.26-.21.56-.32.88-.32.28
      0 .49.07.63.2.14.12.21.3.21.55 0 .18-.06.32-.17.43-.1.1-.24.16-.42.16-.07
      0-.15 0-.22-.02l-.1-.02v-1.1c-.26
      0-.5.1-.73.31-.1.1-.19.21-.25.32v2.91l.47.13v.11h-2.2ZM46.99
      23.08l.47-.12v-5.04l-.47-.12v-.12h.7c.32 0
      .56.1.74.3.07.08.12.18.17.29.07-.13.16-.25.26-.35.24-.24.53-.36.88-.36.31
      0 .59.09.83.25s.43.4.58.7c.14.32.21.7.21 1.14 0 .68-.16 1.2-.47 1.55a1.6
      1.6 0 0 1-1.26.54 1.36 1.36 0 0
      1-.91-.32v1.54l.47.12v.12h-2.2zm2.32-1.57c.22 0
      .4-.13.52-.4.12-.26.19-.75.19-1.46
      0-.68-.06-1.15-.17-1.41-.11-.27-.25-.4-.42-.4-.2
      0-.38.1-.54.31-.07.1-.13.2-.17.32v2.72l.17.16c.15.1.28.16.42.16zM54.08
      21.74c-.65 0-1.16-.19-1.54-.55a2.07 2.07 0 0
      1-.55-1.54c0-.65.18-1.16.55-1.53.38-.37.89-.56 1.54-.56s1.16.2
      1.53.56c.37.37.56.88.56 1.53s-.19 1.17-.56
      1.54c-.37.36-.88.55-1.53.55zm0-.16c.2 0
      .38-.14.53-.44.14-.3.22-.79.22-1.49s-.08-1.2-.22-1.49c-.15-.3-.32-.44-.53-.44-.21
      0-.4.15-.54.44-.14.3-.21.8-.21 1.5s.07 1.19.21
      1.48c.15.3.33.44.54.44zM58.65 21.74a1.8 1.8 0 0 1-.87-.21 1.29 1.29 0 0
      1-.31-.22 1.03 1.03 0 0 1-.44.35h-.2v-1.34h.32c.07.22.17.43.3.62.28.4.63.6
      1.04.6.22 0 .39-.05.5-.16.11-.1.17-.26.17-.47a.53.53 0 0
      0-.23-.43c-.16-.12-.4-.27-.73-.44a7.55 7.55 0 0 1-.69-.39 1.68 1.68 0 0
      1-.45-.42.98.98 0 0 1-.19-.6.9.9 0 0 1 .41-.77c.27-.2.69-.3 1.25-.3.28 0
      .54.08.77.23.12.06.21.13.3.2a1.18 1.18 0 0 1 .44-.35h.2v1.34h-.33a2.77
      2.77 0 0 0-.28-.6c-.27-.42-.58-.62-.94-.62a.49.49 0 0
      0-.37.16c-.1.1-.15.23-.15.39 0
      .2.09.37.26.5.16.14.42.3.76.46.28.14.5.27.67.38.16.1.3.24.42.4a.9.9 0 0 1
      .18.55c0 .76-.6 1.14-1.81 1.14zM63.18 21.74c-.67 0-1.2-.19-1.57-.55a2.07
      2.07 0 0 1-.56-1.54c0-.42.08-.8.24-1.1.17-.33.4-.57.69-.73a2 2 0 0 1
      1-.26c.38 0 .71.08 1 .22.28.14.5.33.66.57a1.42 1.42 0 0 1 .2
      1.13l-.05.13a12.03 12.03 0 0 1-2.4.24c0 .6.08 1.04.25
      1.3.17.27.4.4.7.4a1.18 1.18 0 0 0 .99-.53c.1-.17.15-.33.15-.5h.2c0
      .2-.07.41-.2.6-.12.19-.3.34-.52.45-.23.11-.49.17-.78.17zm-.79-2.09a6 6 0 0
      0 1.1-.08l.05-.17c.02-.13.03-.27.03-.42a2 2 0 0
      0-.17-.96c-.12-.2-.25-.3-.42-.3-.4 0-.59.64-.59 1.93zm0 0"
        />

      </g>
      <g fill="#fff">
        <path d="M32.82 28.89h-.34v-2.41h.77c.12 0
      .23.01.33.04.1.02.2.06.27.11.08.06.14.12.19.2.05.1.07.2.07.32 0
      .17-.05.32-.16.43a.77.77 0 0 1-.43.2l.68
      1.1h-.4l-.62-1.06h-.36Zm0-1.36h.38c.08 0 .16 0 .22-.02a.6.6 0 0 0
      .18-.06.4.4 0 0 0 .12-.12c.03-.05.05-.11.05-.18a.35.35 0 0 0-.05-.19.36.36
      0 0 0-.12-.11.48.48 0 0 0-.17-.06.95.95 0 0 0-.2-.03h-.41ZM35.24
      28.59h1.26v.3h-1.6v-2.41h1.55v.3h-1.2v.72h1.13v.28h-1.14ZM37.29
      26.48h.83c.15 0
      .3.02.46.07.15.04.3.12.42.21.12.1.22.23.3.38.08.15.12.33.12.55a1.13 1.13 0
      0 1-.42.91 1.4 1.4 0 0 1-.88.29h-.83Zm.33 2.11h.43c.14 0
      .27-.02.4-.06a.9.9 0 0 0 .32-.18c.09-.07.16-.17.2-.28a.91.91 0 0 0
      .08-.38.97.97 0 0 0-.07-.4.73.73 0 0 0-.21-.28.92.92 0 0 0-.32-.18 1.26
      1.26 0 0 0-.4-.06h-.43ZM40.55
      28.59h1.26v.3h-1.6v-2.41h1.55v.3h-1.2v.72h1.13v.28h-1.14ZM42.93
      28.89h-.33v-2.41h1.5v.3h-1.17v.75h1.1v.28h-1.1ZM45.2
      28.89h-.33v-2.41h.33ZM47.83
      28.4v-1.92h.34v2.4h-.43l-1.27-1.96v1.97h-.34v-2.41h.44zM49.43
      28.59h1.26v.3h-1.6v-2.41h1.55v.3h-1.21v.72h1.13v.28h-1.13ZM54.48
      26.97a.84.84 0 0 0-.28-.18.95.95 0 0 0-.37-.07.91.91 0 0 0-.67.28 1.02
      1.02 0 0 0-.25.68.98.98 0 0 0 .25.69.9.9 0 0 0 .68.27c.11 0 .22 0
      .31-.02.1-.02.18-.05.25-.1v-.66h-.53v-.29h.86v1.17a2.12 2.12 0 0 1-.9.2
      1.4 1.4 0 0 1-.52-.09 1.18 1.18 0 0
      1-.67-.66c-.07-.15-.1-.32-.1-.51s.03-.36.1-.51a1.17 1.17 0 0 1 .67-.66
      1.44 1.44 0 0 1 1.03 0c.15.05.28.13.38.22zM58.08 27.68a1.29 1.29 0 0
      1-.37.92 1.35 1.35 0 0 1-.91.35c-.18 0-.35-.03-.51-.1a1.18 1.18 0 0
      1-.67-.66c-.06-.15-.1-.32-.1-.51s.04-.36.1-.51a1.13 1.13 0 0 1 .67-.66
      1.38 1.38 0 0 1 1.02
      0c.16.06.3.14.4.25.11.12.2.25.27.4.07.16.1.33.1.52zm-.36 0a1.02 1.02 0 0
      0-.26-.68.88.88 0 0 0-.66-.29.91.91 0 0 0-.38.08.81.81 0 0 0-.29.21 1 1 0
      0 0-.24.68c0 .13.02.26.06.38a.91.91 0 0 0
      .47.51c.12.05.24.07.38.07s.26-.02.38-.07c.11-.05.2-.12.28-.2a.95.95 0 0 0
      .2-.31c.03-.12.06-.25.06-.38Zm0 0"
        />

      </g>
      <g fill="#fff">
        <path d="M58.91 28.89h-.37l1.05-2.41h.3l1.04
      2.4h-.39l-.24-.58h-1.14Zm.36-.89h.9l-.45-1.13ZM61.9
      28.59h1.08v.3h-1.4v-2.41h.33ZM64.84 26.92a.54.54 0 0 0-.19-.16.57.57 0 0
      0-.27-.06c-.06 0-.12 0-.17.02a.41.41 0 0 0-.26.2.35.35 0 0 0-.04.17.32.32
      0 0 0 .14.28l.15.08a10.34 10.34 0 0 0 .45.15c.08.03.16.07.22.12a.58.58 0 0
      1 .24.5.7.7 0 0 1-.26.55.75.75 0 0 1-.27.13c-.1.03-.2.05-.3.05-.16
      0-.3-.03-.45-.09a.85.85 0 0 1-.35-.26l.26-.23a.63.63 0 0 0 .7.26.5.5 0 0 0
      .16-.07.4.4 0 0 0 .12-.12.37.37 0 0 0 .04-.2.35.35 0 0 0-.04-.17.38.38 0 0
      0-.13-.13 7.15 7.15 0 0 0-.63-.25.84.84 0 0 1-.2-.11.57.57 0 0
      1-.2-.47.62.62 0 0 1 .27-.53c.08-.05.16-.1.26-.12.1-.03.2-.04.3-.04a1 1 0
      0 1 .7.27zm0 0"
        />

      </g>
      <g fill="#fff">
        <path d="M33.3 32.97h-.37l1.05-2.41h.3l1.04
      2.4h-.38l-.25-.58h-1.14Zm.36-.9h.9l-.44-1.12ZM37.66
      32.48v-1.92H38v2.4h-.42L36.3 31v1.97h-.34v-2.41h.44zM38.93 30.56h.82c.16 0
      .31.02.47.07.15.04.29.11.42.21.12.1.22.22.3.38.08.15.11.33.11.54 0
      .21-.03.39-.11.54-.08.15-.18.27-.3.37a1.4 1.4 0 0 1-.89.3h-.82Zm.33
      2.1h.43c.14 0 .27-.01.4-.05a.9.9 0 0 0 .31-.18c.1-.08.17-.17.21-.29a.91.91
      0 0 0 .08-.38.97.97 0 0 0-.08-.4.73.73 0 0 0-.2-.27.92.92 0 0 0-.33-.18
      1.26 1.26 0 0 0-.4-.06h-.42ZM45.47 31.76a1.29 1.29 0 0
      1-.37.91c-.1.11-.24.2-.4.26a1.38 1.38 0 0 1-1.02 0 1.18 1.18 0 0
      1-.67-.66c-.06-.15-.09-.32-.09-.51s.03-.36.1-.51a1.13 1.13 0 0 1 .66-.66
      1.38 1.38 0 0 1 1.02 0c.16.06.3.14.4.25a1.29 1.29 0 0 1 .37.92zm-.36
      0a1.02 1.02 0 0 0-.25-.68.88.88 0 0 0-.66-.29.91.91 0 0 0-.39.08.81.81 0 0
      0-.28.21 1 1 0 0 0-.24.68c0
      .13.01.26.06.38.04.12.1.22.18.3.08.1.18.16.29.21.11.05.23.07.37.07a.85.85
      0 0 0 .66-.28.95.95 0 0 0 .2-.3c.04-.12.06-.25.06-.38ZM47.19 33.03c-.16
      0-.3-.03-.42-.08a.87.87 0 0 1-.46-.52 1.14 1.14 0 0
      1-.06-.36v-1.51h.34v1.5c0 .08 0
      .16.03.24.02.08.05.15.1.21.04.07.1.12.18.16.08.04.17.05.29.05.1 0
      .2-.01.28-.05.08-.04.14-.1.18-.16a.57.57 0 0 0 .1-.21.91.91 0 0 0
      .03-.24v-1.5h.34v1.51c0 .13-.02.25-.05.36a.86.86 0 0
      1-.47.52c-.12.05-.25.08-.41.08ZM49.87
      32.97h-.33v-2.12h-.78v-.3h1.88v.3h-.77zm0 0"
        />

      </g>
      <path
        fill="#fff"
        d="M53.27 32.6a1.16 1.16 0 0 1-.92.44c-.19-.01-.36-.05-.52-.1a1.22 1.22 0
      0 1-.4-.27c-.1-.1-.2-.24-.26-.4-.06-.15-.1-.32-.1-.51s.04-.36.1-.51a1.17
      1.17 0 0 1 .67-.66 1.38 1.38 0 0 1 1 0 .9.9 0 0 1 .38.28l-.26.2a.66.66 0 0
      0-.25-.2.75.75 0 0 0-.35-.08.91.91 0 0 0-.38.08.81.81 0 0 0-.29.21 1.02
      1.02 0 0 0-.25.68.98.98 0 0 0 .24.69.96.96 0 0 0
      1.04.2c.11-.06.2-.14.28-.24zm0 0"
      />
      <g fill="#fff">
        <path d="M56.34 31.76a1.29 1.29 0 0 1-.37.91c-.11.11-.25.2-.4.26a1.38 1.38
      0 0 1-1.02 0 1.18 1.18 0 0
      1-.67-.66c-.07-.15-.1-.32-.1-.51s.03-.36.1-.51a1.13 1.13 0 0 1 .67-.66
      1.38 1.38 0 0 1 1.01
      0c.16.06.3.14.4.25.12.11.21.25.28.4.06.16.1.33.1.52zm-.37 0a1.02 1.02 0 0
      0-.25-.68.88.88 0 0 0-.66-.29.91.91 0 0 0-.38.08.81.81 0 0 0-.29.21 1 1 0
      0 0-.24.68c0
      .13.02.26.06.38.04.12.1.22.19.3.08.1.17.16.28.21.11.05.24.07.38.07a.85.85
      0 0 0 .66-.28.95.95 0 0 0 .19-.3c.04-.12.06-.25.06-.38ZM58.38
      32.4l.71-1.84h.53v2.4h-.34v-2l-.8 2h-.22l-.8-2v2h-.33v-2.4h.52ZM60.88
      32.67h1.25v.3h-1.59v-2.41h1.54v.3h-1.2v.72H62v.28h-1.13ZM64.12 31a.54.54 0
      0 0-.2-.16.57.57 0 0 0-.27-.06c-.05 0-.1 0-.16.02a.41.41 0 0 0-.26.2.35.35
      0 0 0-.05.17.32.32 0 0 0 .15.28l.15.08a10.34 10.34 0 0 0
      .44.15c.08.03.16.07.23.12.07.06.12.12.17.2.04.08.06.18.06.3a.7.7 0 0
      1-.26.55.75.75 0 0 1-.26.13c-.1.03-.2.05-.3.05-.16 0-.31-.03-.45-.1a.85.85
      0 0 1-.35-.25l.26-.23c.05.09.13.16.23.21a.63.63 0 0 0 .47.06.5.5 0 0 0
      .16-.08.4.4 0 0 0 .11-.13.37.37 0 0 0 .05-.18.35.35 0 0 0-.05-.19.38.38 0
      0 0-.12-.12 7.15 7.15 0 0 0-.63-.25.84.84 0 0 1-.2-.12.57.57 0 0
      1-.2-.46.62.62 0 0 1 .27-.53c.07-.06.16-.1.26-.12.1-.03.2-.04.3-.04a1 1 0
      0 1 .69.27zm0 0"
        />
      </g>
      <path
        fill="#fff"
        stroke="#1e3045"
        strokeMiterlimit="10"
        strokeWidth="1.273536"
        d="m66.26 62.01-11.08 6.4V81.2l11.08 6.4 11.08-6.4V68.4zm0 0"
      />
      <g fill="#fff">
        <path d="m103.53 79.92.47-.12v-5.04l-.47-.12v-.12h2.2c.88 0 1.5.14
      1.88.43.38.29.57.7.57 1.26s-.19.98-.57 1.27c-.38.29-1
      .43-1.87.43h-.44v1.9l.48.11v.12h-2.25zm2.2-2.2c.34 0
      .6-.13.8-.38.2-.26.31-.63.31-1.13 0-.49-.1-.86-.3-1.11a.97.97 0 0
      0-.8-.38h-.44v3zM110.7 80.15c-.65 0-1.16-.18-1.53-.55a2.07 2.07 0 0
      1-.56-1.53c0-.65.19-1.16.56-1.53.37-.37.88-.56 1.53-.56s1.16.19
      1.53.56c.38.37.56.88.56 1.53s-.18 1.16-.56
      1.53c-.37.37-.88.55-1.53.55zm0-.15c.21 0
      .39-.15.53-.44.15-.3.22-.8.22-1.49
      0-.7-.07-1.2-.22-1.5-.14-.29-.32-.43-.53-.43-.2
      0-.39.14-.53.44-.15.3-.22.79-.22 1.49s.07 1.2.22 1.49c.14.3.32.44.53.44zm0
      0"
        />

      </g>
      <g fill="#fff">
        <path d="m113.34 76.33-.4-.12v-.11h.92c.27 0
      .5.06.66.17.16.12.3.28.4.5l.86 1.8.46-1.14-.53-1.1-.4-.12v-.11h.91c.28 0
      .5.06.66.17.16.12.3.28.4.5l.87
      1.8.83-2.12-.6-.24v-.11h1.47v.11l-.64.24-1.45 3.67h-.24l-1.17-2.43-.96
      2.43h-.23ZM121.97 80.15c-.67 0-1.2-.18-1.57-.55a2.07 2.07 0 0
      1-.56-1.53c0-.43.08-.8.25-1.11.16-.32.39-.56.68-.73a2 2 0 0 1 1-.25c.38 0
      .72.07 1 .22.29.14.5.33.66.56a1.42 1.42 0 0 1 .2
      1.13l-.04.14-.74.12c-.55.07-1.1.11-1.67.11 0 .61.09 1.05.26
      1.3.16.27.4.4.69.4a1.18 1.18 0 0 0 .99-.53c.1-.16.15-.33.15-.5h.2c0
      .21-.07.42-.2.6-.12.2-.3.35-.52.46-.23.1-.49.16-.78.16zm-.79-2.08a6 6 0 0
      0 1.1-.08l.05-.17c.02-.14.04-.28.04-.42a2 2 0 0
      0-.18-.96c-.11-.2-.25-.3-.41-.3-.4 0-.6.64-.6 1.93zm0 0"
        />

      </g>
      <path
        fill="#fff"
        d="m124.26 79.92.47-.12v-3.47l-.47-.12v-.11h.7c.32 0
      .56.1.74.3.07.08.12.17.17.29.08-.12.18-.22.3-.32.26-.2.55-.31.87-.31.28 0
      .5.06.63.2.14.12.21.3.21.55 0 .17-.05.32-.16.43-.11.1-.25.16-.43.16-.07
      0-.14 0-.22-.02l-.1-.02v-1.1c-.26
      0-.5.1-.72.31-.11.1-.2.2-.26.31v2.92l.48.12v.12h-2.21Zm0 0"
      />
      <g fill="#fff">
        <path d="M101.82
      87h1.25v.3h-1.6v-2.4h1.55v.3h-1.2v.71h1.13v.3h-1.13ZM104.7
      85.8l.58-.9h.39l-.77 1.14.87 1.26h-.42l-.66-1.01-.65
      1.01h-.41l.86-1.27-.77-1.14h.4zM106.47 84.9h.74c.13 0 .25 0
      .35.03.11.03.2.07.27.12.08.06.13.13.17.21a.7.7 0 0 1 .07.3c0
      .13-.03.23-.07.32a.58.58 0 0 1-.18.2.77.77 0 0
      1-.28.13c-.11.02-.23.03-.35.03h-.38v1.06h-.34Zm.34 1.06h.36c.08 0 .15 0
      .22-.02a.52.52 0 0 0 .18-.07.28.28 0 0 0 .12-.12.36.36 0 0 0 .04-.18.35.35
      0 0 0-.16-.3.5.5 0 0 0-.18-.07 1.09 1.09 0 0 0-.22-.02h-.36Zm0 0"
        />

      </g>
      <g fill="#fff">
        <path d="M108.73 87.3h-.37l1.04-2.4h.3l1.04
      2.4h-.38l-.25-.59h-1.14Zm.36-.88h.9l-.45-1.13ZM113.09
      86.82v-1.93h.34v2.41H113l-1.27-1.96v1.96h-.34v-2.4h.44zM114.35
      84.9h.83c.15 0
      .3.02.46.07.16.04.3.11.42.21.12.1.23.22.3.38.08.15.12.33.12.54a1.13 1.13 0
      0 1-.42.91 1.4 1.4 0 0 1-.88.3h-.83Zm.34 2.1h.42c.15 0 .28-.01.4-.06a.9.9
      0 0 0 .32-.17c.09-.08.16-.17.2-.29a.91.91 0 0 0 .09-.38.97.97 0 0
      0-.08-.4.73.73 0 0 0-.21-.28.92.92 0 0 0-.32-.17 1.26 1.26 0 0
      0-.4-.06h-.42ZM119.2 86.87h.02l.72-1.98h.36l-.94 2.41h-.31l-.94-2.4h.37zm0
      0"
        />

      </g>
      <g fill="#fff">
        <path d="M123.32 86.1a1.29 1.29 0 0 1-.37.91c-.11.11-.25.2-.4.26a1.38 1.38
      0 0 1-1.02 0 1.18 1.18 0 0 1-.67-.66c-.06-.16-.1-.33-.1-.51
      0-.2.04-.36.1-.52a1.13 1.13 0 0 1 .67-.65 1.38 1.38 0 0 1 1.02
      0c.15.06.29.14.4.25a1.29 1.29 0 0 1 .37.92zm-.37 0a1.02 1.02 0 0
      0-.25-.68.88.88 0 0 0-.66-.29.91.91 0 0 0-.38.08.81.81 0 0 0-.29.2 1 1 0 0
      0-.24.69c0
      .13.02.26.06.38.04.11.1.22.19.3.08.1.17.16.28.2.11.06.24.08.38.08a.85.85 0
      0 0 .66-.28.95.95 0 0 0 .19-.3c.04-.12.06-.25.06-.38ZM124.45
      87.3h-.34v-2.4h.34ZM127.44 86.93c-.1.13-.22.23-.37.31a1.38 1.38 0 0
      1-1.06.03 1.22 1.22 0 0
      1-.4-.26c-.11-.11-.2-.25-.26-.4-.07-.16-.1-.33-.1-.51
      0-.2.03-.36.1-.52a1.17 1.17 0 0 1 .67-.66 1.38 1.38 0 0 1 1 .01.9.9 0 0 1
      .38.28l-.27.2a.66.66 0 0 0-.25-.2.75.75 0 0 0-.35-.08.91.91 0 0
      0-.38.08.81.81 0 0 0-.29.2 1.02 1.02 0 0 0-.25.68c0 .14.03.27.07.39a.89.89
      0 0 0 .47.52.96.96 0 0 0 .75-.02c.1-.05.2-.13.27-.23zM128.46
      87h1.25v.3h-1.59v-2.4h1.54v.3h-1.2v.71h1.13v.3h-1.13Zm0 0"
        />

      </g>
      <g fill="#fff">
        <path d="M103.15 91.38h-.38l1.05-2.4h.3l1.04
      2.4h-.38l-.25-.59h-1.14Zm.36-.89h.9l-.45-1.13ZM107.5
      90.9v-1.93h.35v2.41h-.43l-1.27-1.97v1.97h-.34v-2.4h.44zM108.77
      88.97h.83c.15 0
      .3.03.46.07.15.05.3.12.42.22s.22.22.3.37c.08.16.12.34.12.55a1.13 1.13 0 0
      1-.42.91 1.4 1.4 0 0 1-.88.3h-.83Zm.34 2.11h.42c.14 0 .28-.01.4-.06a.9.9 0
      0 0 .32-.17c.09-.08.16-.17.2-.29a.91.91 0 0 0 .08-.38.97.97 0 0
      0-.07-.4.73.73 0 0 0-.21-.28.92.92 0 0 0-.32-.17 1.26 1.26 0 0
      0-.4-.06h-.42ZM112.92 91.38h-.37l1.05-2.4h.3l1.04
      2.4h-.38l-.25-.59h-1.14Zm.36-.89h.9l-.45-1.13Zm0 0"
        />

      </g>
      <g fill="#fff">
        <path d="M117.34 89.47a.84.84 0 0 0-.28-.19.95.95 0 0 0-.38-.07.91.91 0 0
      0-.67.29 1.02 1.02 0 0 0-.25.68c0 .13.03.25.07.38.04.11.1.22.18.3a.9.9 0 0
      0 .68.28c.12 0 .22 0
      .32-.03.1-.02.18-.05.25-.09v-.67h-.54v-.29h.87v1.17c-.13.07-.27.12-.42.16-.15.03-.31.05-.49.05a1.4
      1.4 0 0 1-.51-.1 1.18 1.18 0 0 1-.67-.66c-.07-.15-.1-.32-.1-.5
      0-.2.03-.36.1-.52.06-.15.15-.29.26-.4.12-.11.25-.2.4-.25a1.44 1.44 0 0 1
      1.03 0c.16.05.28.12.38.22zM118.84
      91.08h1.26v.3h-1.6v-2.4h1.55v.3h-1.2v.71h1.13v.29h-1.14ZM122.59
      90.9v-1.93h.34v2.41h-.43l-1.27-1.97v1.97h-.34v-2.4h.44zM125.92
      91c-.1.14-.22.24-.38.32a1.38 1.38 0 0 1-1.06.03 1.22 1.22 0 0
      1-.4-.26c-.1-.11-.2-.25-.26-.4-.06-.16-.09-.33-.09-.52
      0-.18.03-.35.1-.5.06-.16.15-.3.26-.41.12-.11.25-.2.4-.25a1.38 1.38 0 0 1
      1.01 0 .9.9 0 0 1 .37.28l-.26.2a.66.66 0 0 0-.25-.2.75.75 0 0
      0-.35-.08.91.91 0 0 0-.38.08.81.81 0 0 0-.29.2 1.02 1.02 0 0
      0-.25.68.98.98 0 0 0 .24.69c.09.1.18.16.3.21a.96.96 0 0 0 .74
      0c.11-.06.2-.14.28-.24zm0 0"
        />

      </g>
      <path
        fill="#fff"
        d="M127.5 91.38h-.34v-1.04l-.9-1.37h.4l.67 1.1.68-1.1h.39l-.9 1.37zm0 0"
      />
      <path
        fill="#1e3045"
        d="M65.74 75.59c-.51 0-.95-.07-1.3-.21a1.94 1.94 0 0 1-.82-.54 1.1 1.1 0 0
      1-.27-.7c0-.25.07-.45.22-.6a.82.82 0 0 1 .6-.23 1 1 0 0 1
      .19.02l.12.01.14.02v1.78l.24.1c.19.05.4.07.65.07.52 0 .92-.16
      1.2-.47.27-.31.41-.79.41-1.42
      0-.64-.14-1.13-.4-1.45-.28-.33-.64-.5-1.1-.5h-.55v-.27h.55a1 1 0 0 0
      .83-.44c.23-.3.34-.73.34-1.28
      0-.56-.12-.98-.35-1.28-.23-.3-.52-.44-.87-.44-.2
      0-.38.03-.55.09l-.23.07v1.78l-.14.03a2.4 2.4 0 0 1-.3.02.82.82 0 0
      1-.6-.22.82.82 0 0 1-.23-.6c0-.24.08-.46.26-.68.18-.22.44-.4.78-.55a4.07
      4.07 0 0 1 2.81.06c.44.17.76.42.98.72.22.3.33.63.33 1 0 .42-.1.76-.32
      1-.22.25-.5.45-.85.6-.34.13-.73.2-1.17.23v.06c.5.02.94.1 1.34.25.89.36
      1.33.96 1.33 1.8 0 .65-.27 1.18-.8 1.58-.54.39-1.36.59-2.47.59zm0 0"
      />
      <g fill="#1e3045">
        <path d="M58.67 77.7h.83c.15 0
      .3.03.46.08.16.04.3.11.42.21.12.1.23.22.3.38.08.15.12.33.12.54a1.13 1.13 0
      0 1-.42.91 1.4 1.4 0 0 1-.88.3h-.83Zm.34 2.12h.42c.14 0 .28-.02.4-.06a.9.9
      0 0 0 .32-.18c.09-.08.16-.17.2-.29a.91.91 0 0 0 .08-.38.97.97 0 0
      0-.07-.4.73.73 0 0 0-.21-.27.92.92 0 0 0-.32-.18 1.26 1.26 0 0
      0-.4-.06h-.42ZM61.93 80.12h-.33V77.7h.77c.12 0
      .23.01.33.04.1.02.2.06.27.11.08.05.14.12.19.2.04.1.07.2.07.31 0
      .18-.06.32-.17.43a.77.77 0 0 1-.42.2l.68
      1.12h-.41l-.61-1.07h-.37Zm0-1.36h.39c.08 0 .15 0 .22-.02a.6.6 0 0 0
      .18-.06.4.4 0 0 0 .12-.12c.03-.05.05-.11.05-.19a.35.35 0 0 0-.05-.18.36.36
      0 0 0-.12-.11.48.48 0 0 0-.17-.06.95.95 0 0 0-.2-.03h-.42ZM64.36
      80.12h-.34V77.7h.34ZM66.08 79.68h.02l.72-1.97h.36l-.94
      2.4h-.32l-.93-2.4h.37zM68.14
      79.82h1.26v.3h-1.6V77.7h1.55v.3h-1.2v.72h1.13V79h-1.14ZM70.53
      80.12h-.34V77.7h.77c.12 0
      .23.01.33.04.1.02.2.06.27.11.08.05.15.12.19.2.05.1.07.2.07.31 0
      .18-.05.32-.16.43a.77.77 0 0 1-.43.2l.68
      1.12h-.4l-.62-1.07h-.36Zm0-1.36h.38c.08 0 .16 0 .22-.02a.6.6 0 0 0
      .18-.06.4.4 0 0 0 .13-.12c.02-.05.04-.11.04-.19a.35.35 0 0 0-.04-.18.36.36
      0 0 0-.13-.11.48.48 0 0 0-.17-.06.95.95 0 0 0-.2-.03h-.41ZM73.81
      78.15a.54.54 0 0 0-.19-.16.57.57 0 0 0-.28-.06c-.05 0-.1 0-.16.02a.41.41 0
      0 0-.26.2.35.35 0 0 0-.04.17.32.32 0 0 0 .14.28l.15.08a10.34 10.34 0 0 0
      .44.15c.09.03.16.07.23.12a.58.58 0 0 1 .24.5.7.7 0 0 1-.26.55.75.75 0 0
      1-.27.13c-.1.03-.2.05-.3.05-.16 0-.3-.03-.45-.1a.85.85 0 0
      1-.35-.25l.26-.23a.63.63 0 0 0 .7.26.5.5 0 0 0 .16-.07.4.4 0 0 0
      .12-.13.37.37 0 0 0 .04-.18.35.35 0 0 0-.04-.19.38.38 0 0 0-.13-.12 7.15
      7.15 0 0 0-.63-.25.84.84 0 0 1-.2-.12.57.57 0 0 1-.2-.46.62.62 0 0 1
      .27-.53c.08-.06.16-.1.26-.12.1-.03.2-.04.3-.04a1 1 0 0 1 .69.27zm0 0"
        />

      </g>
    </svg>
  );
};

export default PropellerWithLabels;
