import React from 'react';

import './references.scss';

const annotations = [
  {
    id: 1,
    text: 'OECD, Reimers, F., & Schleicher, A., 2020',
  },
  {
    id: 2,
    text: 'McGivney, E., & Winthrop, R., 2020',
  },
  {
    id: 3,
    text: 'At the time of writing (October 2021), while most education systems were reopening schools, in some parts of the world schools remained closed meaning that children were missing out on learning. Note to Big Chance from Alex Beard, Teach For All 2021',
  },
  {
    id: 4,
    text: 'World Bank, 2021',
  },
  {
    id: 5,
    text: 'Vishal Talreja, Dream a Dream, note to Big Change, 2021',
  },
  {
    id: 6,
    text: 'For example In the USA, K–12 student students were on average five months behind in mathematics and four months behind in reading by the end of the school year 2020-21. High School dropouts increased and progression to post secondary education decreased over previous years. McKinsey, 2021',
  },
  {
    id: 7,
    text: 'UNICEF, 2021',
  },
  {
    id: 8,
    text: 'Hundred, 2020',
  },
  {
    id: 9,
    text: 'Interview with Saku Tuominen, HundrED, 2021',
  },
  {
    id: 10,
    text: 'McGivney, E., & Winthrop, R., 2020',
  },
  {
    id: 11,
    text: 'McKinsey, 2021',
  },
  {
    id: 12,
    text: 'World Bank, 2021',
  },
  {
    id: 13,
    text: 'UNICEF, 2021',
  },
  {
    id: 14,
    text: 'OECD, Reimers, F., & Schleicher, A. (2020).',
  },
  {
    id: 15,
    text: 'OECD, 2020',
  },
  {
    id: 16,
    text: 'Big Change, 2019',
  },
  {
    id: 17,
    text: 'OECD, 2020',
  },
  {
    id: 18,
    text: 'Education Reimagined 2020',
  },
  {
    id: 19,
    text: 'Big Change, 2019',
  },
  {
    id: 20,
    text: 'Interview with Michael Stevenson, OECD, 2021',
  },
  {
    id: 21,
    text: 'OECD, 2021 forthcoming',
  },
  {
    id: 22,
    text: 'Forthcoming',
  },
  {
    id: 23,
    text: 'OECD, 2019',
  },
  {
    id: 24,
    text: 'G20 Interfaith Forum by G20 leaders, 2021',
  },
  {
    id: 25,
    text: 'Education Reimagined, 2020',
  },
  {
    id: 26,
    text: 'Big Change 2019, 2021',
  },
  {
    id: 27,
    text: 'HundrED, 2020',
  },
  {
    id: 28,
    text: 'OECD, 2020',
  },
  {
    id: 29,
    text: 'Big Change interview with Richard Culatta, International Society for Technology in Education, (2021)',
  },
  {
    id: 30,
    text: 'Edweek, 2020',
  },
  {
    id: 31,
    text: 'In Brief, Center of Developing Child, n.d.',
  },
  {
    id: 32,
    text: 'McGivney, E., & Winthrop, R., 2020',
  },
  {
    id: 33,
    text: 'IPPR, 2020',
  },
  {
    id: 34,
    text: 'RewirEd',
  },
  {
    id: 35,
    text: 'Meadows, 2008',
  },
  {
    id: 36,
    text: 'Populace, 2020',
  },
  {
    id: 37,
    text: 'McGivney, E., & Winthrop, R., 2016',
  },
  {
    id: 38,
    text: 'Education Reimagined, 2020',
  },
  {
    id: 39,
    text: 'McGivney, E., & Winthrop, R., 2016; Center for Universal Education, The Brookings Institution 2016',
  },
  {
    id: 40,
    text: 'Meadows, 2008',
  },
  {
    id: 41,
    text: 'Big Change interview with Alex Beard, Teach For All, 2021',
  },
  {
    id: 42,
    text: 'OECD, 2020',
  },
  {
    id: 43,
    text: 'Big Change Interview with Fred Swaniker, African Leadership Group, 2021',
  },
  {
    id: 44,
    text: 'Winthrop et al., 2021',
  },
  {
    id: 45,
    text: 'Big Change interview with Todd Rose, Populace, 2021',
  },
  {
    id: 46,
    text: 'Populace, 2019',
  },
  {
    id: 47,
    text: 'Ibid, 2019',
  },
  {
    id: 48,
    text: 'In particular developments in neuroscience have revealed the effects of environment, sleep, exercise and nutrition on the brain enabling it to adapt structurally and in terms of capacity and capability. Put simply, given the right conditions, everyone can become smarter.',
  },
  {
    id: 49,
    text: 'Ken Robinson, 2010',
  },
  {
    id: 50,
    text: 'Andreas Schleicher, 2018',
  },
  {
    id: 51,
    text: 'Big Change Interview with Stephen Farr, Teach For All Global Learning Lab, 2021',
  },
  {
    id: 52,
    text: 'Andreas Schleicher, 2018',
  },
  {
    id: 53,
    text: 'Education Reimagined, 2020',
  },
  {
    id: 54,
    text: 'Populace, 2019',
  },
  {
    id: 55,
    text: 'Big Change interview with Todd Rose, Populace, 2021',
  },
  {
    id: 56,
    text: 'Winthrop et al., 2021',
  },
  {
    id: 57,
    text: 'Ibid.',
  },
  {
    id: 58,
    text: 'Big Change focus group with Restless Development Youth Researchers',
  },
  {
    id: 59,
    text: 'Enseña Perú, 2020',
  },
  {
    id: 60,
    text: 'Teach For All, 2020',
  },
  {
    id: 61,
    text: 'Enseña Perú, 2020',
  },
  {
    id: 62,
    text: 'Teach For All, 2020',
  },
  {
    id: 63,
    text: 'Lavado and Guzmán, 2021',
  },
  {
    id: 64,
    text: 'Evan & Yuan, 2020',
  },
  {
    id: 65,
    text: 'Worldbank, 2916',
  },
  {
    id: 66,
    text: 'Dream a Dream, 2021a',
  },
  {
    id: 67,
    text: 'Dream a Dream 2021b',
  },
  {
    id: 68,
    text: 'Interview with Vishal Talreja, 2021',
  },
  {
    id: 69,
    text: 'Wise Qatar, 2019',
  },
  {
    id: 70,
    text: 'Care et al., 2020',
  },
  {
    id: 71,
    text: 'Ibid.',
  },
  {
    id: 72,
    text: 'Big Change interview with Alex Beard, 2021',
  },
  {
    id: 73,
    text: 'Note that agency can mean very different things in different cultural contexts. In some countries there is no equivalent translation for learner agency in the way it is defined here.',
  },
  {
    id: 74,
    text: 'OECD, 2019',
  },
  {
    id: 75,
    text: 'HundrED, 2020',
  },
  {
    id: 76,
    text: 'OECD, 2010',
  },
  {
    id: 77,
    text: 'Teach for All, 2021',
  },
  {
    id: 78,
    text: 'OECD, 2019',
  },
  {
    id: 79,
    text: 'Note from Andreas Schleicher, OECD, to Big Change, 2021',
  },
  {
    id: 80,
    text: 'Big Change interview with Saku Tuominen, HundrEd, 2021',
  },
  {
    id: 81,
    text: 'Note from Alex Beard, Teach For All, to Big Change, 2021',
  },
  {
    id: 82,
    text: 'McKinsey, 2018 and McGivney, E., & Winthrop, R., 2020',
  },
  {
    id: 83,
    text: 'Jaime Saavedra in an interview by Teach for All, Global Learning Lab 2021',
  },
  {
    id: 84,
    text: 'Big Change interview with Todd Rose, Populace, 2020',
  },
  {
    id: 85,
    text: 'OECD, 2020',
  },
  {
    id: 86,
    text: 'Ibid.',
  },
  {
    id: 87,
    text: 'HundrED, 2020',
  },
  {
    id: 88,
    text: 'Note from Alex Beard, Teach For All, to Big Change, 2021',
  },
  {
    id: 89,
    text: 'Ibid.',
  },
  {
    id: 90,
    text: 'Chapman, K Indus Case Study Global Learning Lab 2021',
  },
  {
    id: 91,
    text: 'Ibid.',
  },
  {
    id: 92,
    text: 'UNICEF 2020b',
  },
  {
    id: 93,
    text: 'Winthrop et al., 2021',
  },
  {
    id: 94,
    text: 'Big Change focus group with Restless Development Youth Researchers',
  },
  {
    id: 95,
    text: 'Winthrop et al., 2021',
  },
  {
    id: 96,
    text: 'Remake Learning, 2021',
  },
  {
    id: 97,
    text: 'Ibid.',
  },
  {
    id: 98,
    text: 'Winthrop et al., 2021',
  },
  {
    id: 99,
    text: 'Populace, 2019',
  },
  {
    id: 100,
    text: 'Winthrop, R., Barton, A., Ershadi, M., & Ziegler, L., 2021',
  },
  {
    id: 101,
    text: 'Big Change interview with Richard Culatta, International Society for Technology in Education, (2021)',
  },
  {
    id: 102,
    text: 'Big Change focus group with Restless Development Youth Researchers, 2021',
  },
  {
    id: 103,
    text: 'Luschei T.F., Soto-Peña M, 2019',
  },
  {
    id: 104,
    text: 'Colbert & Arboleda, 2016',
  },
  {
    id: 105,
    text: 'Psacharopoulos et al., 1992',
  },
  {
    id: 106,
    text: 'Kirp, 2015',
  },
  {
    id: 107,
    text: 'Casassus & Cusato, 2002',
  },
  {
    id: 108,
    text: 'Kids Education Revolution, 2020',
  },
  {
    id: 109,
    text: 'Ibid.',
  },
  {
    id: 110,
    text: 'Kids Education Revolution, 2020',
  },
  {
    id: 111,
    text: 'Ibid.​​',
  },
  {
    id: 112,
    text: 'Education Reimagined, 2020',
  },
  {
    id: 113,
    text: 'Teach For All, 2021',
  },
  {
    id: 114,
    text: 'Peterson, 2020',
  },
  {
    id: 115,
    text: 'HundrED, 2021',
  },
  {
    id: 116,
    text: 'Big Change interview with Richard Culatta, International Society for Technology in Education, (2021)',
  },
  {
    id: 117,
    text: 'OECD, 2020',
  },
  {
    id: 118,
    text: 'Ibid.',
  },
  {
    id: 119,
    text: 'OECD, 2020',
  },
  {
    id: 120,
    text: 'Science of Learning and Development Alliance, 2020',
  },
  {
    id: 121,
    text: 'National Research Council, 1999',
  },
  {
    id: 122,
    text: 'Yeager, D.S., Hanselman, P., Walton, G.M. et al., 2019',
  },
  {
    id: 123,
    text: 'Science of Learning and Development Alliance, 2020',
  },
  {
    id: 124,
    text: 'Treisman, 2019 as reported in The Atlantic, 2021',
  },
  {
    id: 125,
    text: 'OECD, 2020',
  },
  {
    id: 126,
    text: 'OECD, 2020',
  },
  {
    id: 127,
    text: 'Chick, 2010',
  },
  {
    id: 128,
    text: 'Ibid.',
  },
  {
    id: 129,
    text: 'Agora, 2019',
  },
  {
    id: 130,
    text: 'Taviani, 2017',
  },
  {
    id: 131,
    text: 'Ians, 2019',
  },
  {
    id: 132,
    text: 'Agora, 2019',
  },
  {
    id: 133,
    text: 'Ians, 2019',
  },
  {
    id: 134,
    text: 'Ibid.',
  },
  {
    id: 135,
    text: 'MUSE Global, 2020',
  },
  {
    id: 136,
    text: 'HundrED, 2017',
  },
  {
    id: 137,
    text: 'Ibid.',
  },
  {
    id: 138,
    text: 'Ibid.',
  },
  {
    id: 139,
    text: 'MUSE Global, 2020',
  },
];

export const Reference = ({ id } : {id: number}) => (
  <span
    data-tip={annotations[id - 1].text}
    className={
      `reference${annotations[id - 1].text.length > 20 ? ' reference-long' : ''}`
    }
    aria-label={`Note: ${annotations[id - 1].text}`}
  >
    {id}
&nbsp;
  </span>
);

const References = (
  <div className="references">
    <h2>References</h2>
    <div className="references-bibliography">
      <p>
        <a
          href="https://niekee.nl/agora-vmbo-havo-vwo"
          target="_blank"
          rel="noreferrer"
        >
          Agora - niekée roermond
        </a>
        {' '}
        (2019).
      </p>

      <p>
        <a
          href="https://algroup.org/"
          target="_blank"
          rel="noreferrer"
        >
          African leadership group
        </a>
        . (2021). African Leadership Group.
      </p>

      <p>
        Berger, R. (2021).
        {' '}
        <a
          href="https://www.theatlantic.com/ideas/archive/2021/03/how-to-get-our-kids-back-on-track/618269/"
          target="_blank"
          rel="noreferrer"
        >
          Our kids are not broken
        </a>
        . The Atlantic.
      </p>

      <p>
        Big Change &amp; Innovation Unit. (2019).
        {' '}
        <a
          href="https://www.big-change.org/wp-content/uploads/2021/04/Big-Change-Reimagining-Education-2019.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Reimagining education together
        </a>
        .
      </p>

      <p>
        Care, E., Talreja, V., Ravindranath, S., &amp; Sahin, A. G. (2020,
        August 14).
        {' '}
        <a
          href="https://www.brookings.edu/research/development-of-student-and-teacher-measures-of-happiness-curriculum-factors/"
          target="_blank"
          rel="noreferrer"
        >
          Development of student and teacher measures of Happiness Curriculum factors
        </a>
        . Brookings.
      </p>

      <p>
        Casassus, J., &amp; Cusato, S. (2002). First international comparative
        study: of language, mathematics, and associated factors for students in the
        third and fourth grade of primary school: second report. Latin American
        Laboratory for Assessment of Quality.
      </p>

      <p>
        Colbert V. &amp; Arboleda J. (2016) Bringing a student-centered
        participatory pedagogy to scale in Colombia. Journal of Educational Change.
        17. 1-26. 10.1007/s10833-016-9283-7.
      </p>

      <p>
        Dorn, E., Hancock, B., &amp; Sarakatsannis, J. (2021, July 27).
        <a
          href="https://www.mckinsey.com/industries/public-and-social-sector/our-insights/covid-19-and-education-the-lingering-effects-of-unfinished-learning"
          target="_blank"
          rel="noreferrer"
        >
          COVID-19 and education: The lingering effects of unfinished learning
        </a>
        .
        McKinsey &amp; Company.
        {' '}
      </p>

      <p>
        Dream a Dream. (2021a).
        {' '}
        <a
          href="https://dreamadream.org/impact/"
          target="_blank"
          rel="noreferrer"
        >
          Impact report 2020-2021
        </a>
        .
      </p>

      <p>
        <a
          href="https://dreamadream.org/"
          target="_blank"
          rel="noreferrer"
        >
          Dream a Dream
        </a>
        . (2021b). Thriving as the purpose of education.
      </p>

      <p>
        Education Reimagined. (2020). Catalyzing nationwide, systemic
        transformation in education.
      </p>

      <p>
        <a
          href="https://ensenaperu.org/"
          target="_blank"
          rel="noreferrer"
        >
          Enseña Perú
        </a>
        . (2020). Enseña Perú.
      </p>

      <p>
        High Resolves. (2019a).
        {' '}
        <a
          href="https://highresolves.org/wp-content/uploads/2019/09/High-Resolves-Orange-Paper-4-A4-v1.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Putting learning science at everyone&#39;s fingertips
        </a>
        .
      </p>

      <p>
        HundrED. (2017).
        {' '}
        <a
          href="https://hundred.org/en/articles/blueprints-for-success#719c11e"
          target="_blank"
          rel="noreferrer"
        >
          Blueprints for success
        </a>
        .
      </p>

      <p>
        HundrED. (2020).
        {' '}
        <a
          href="https://cdn.hundred.org/uploads/report/file/15/hundred_spotlight_covid-19_digital.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Spotlight: Quality education for all during Covid-19 crisis
        </a>
        .
      </p>

      <p>
        HundrED. (2021).
        {' '}
        <a
          href="https://hundred.org/en/collections/formative-assessment-improving-learning-for-the-21st-century"
          target="_blank"
          rel="noreferrer"
        >
          Formative Assessment: Improving Learning for Every Child.
        </a>
      </p>

      <p>
        Ians. (2019, May 8).
        {' '}
        <a
          href="https://www.business-standard.com/article/news-ians/netherlands-school-that-has-no-classes-or-curriculum-119050801149_1.html"
          target="_blank"
          rel="noreferrer"
        >
          Netherlands school that has no classes or curriculum
        </a>
        . Business Standard.
      </p>

      <p>
        Istance D., Paniagua A. (2019)
        {' '}
        <a href="https://www.brookings.edu/wp-content/uploads/2019/09/Learning-to-Leapfrog-InnovativePedagogiestoTransformEducation-Web.pdf">
          Learning to Leapfrog: Innovate Pedagogics to Transform education
        </a>
        . Center for Universal Education at Brookings.
      </p>

      <p>
        Kids Education Revolution. (2018, May 23).
        {' '}
        <a
          href="https://www.youtube.com/watch?v=62CqDjCW_Q4"
          target="_blank"
          rel="noreferrer"
        >
          KER week 2018: The highlights
        </a>
        . YouTube.
      </p>

      <p>
        <a
          href="https://www.kidseducationrevolution.org/"
          target="_blank"
          rel="noreferrer"
        >
          Kids education revolution
        </a>
        . (2020). KER.
      </p>

      <p>
        Kirp, D. L. (2015, 28. Februar).
        {' '}
        <a
          href="https://www.nytimes.com/2015/03/01/opinion/sunday/make-school-a-democracy.html"
          target="_blank"
          rel="noreferrer"
        >
          Make school a democracy
        </a>
        . The New York Times.
      </p>

      <p>
        Lister, J. (2016, Oktober).
        {' '}
        <a
          href="https://hundred.org/en/articles/it-s-time-to-assess-assessment#1bd0c3be"
          target="_blank"
          rel="noreferrer"
        >
          It&#39;s time to assess assessment
        </a>
        .
        HundrED.
      </p>
      <p>
        Luschei T.F., Soto-Peña M. (2019)
        {' '}
        <a
          href="https://doi.org/10.1007/978-3-319-53450-3_6"
          target="_blank"
          rel="noreferrer"
        >
          Beyond Achievement: Colombia’s Escuela Nueva and the Creation of
          Active Citizens
        </a>
        . In: Aman R., Ireland T.
        (eds) Educational Alternatives in Latin America. Palgrave Macmillan,
        Cham.
      </p>

      <p>
        National Research Council. (1999)
        {' '}
        <a
          href="https://doi.org/10.17226/9457"
          target="_blank"
          rel="noreferrer"
        >
          How people learn
        </a>
        . Washington, DC:
        National Academies Press.

      </p>
      <p>
        Newton, D. (2021, May 25).
        {' '}
        <a
          href="https://www.forbes.com/sites/dereknewton/2021/05/25/wise-award-finalists-announced/?sh=51710c5c5d0c"
          target="_blank"
          rel="noreferrer"
        >
          WISE awards finalists announced
        </a>
        . Forbes.
      </p>
      <p>
        McGivney, E., &amp; Winthrop, R. (2020).
        {' '}
        <a
          href="https://www.brookings.edu/research/skills-for-a-changing-world/"
          target="_blank"
          rel="noreferrer"
        >
          Skills for a changing world
        </a>
        .
        Center for Universal Education at Brookings.
      </p>
      <p>
        Meadows, D. (n.d..).
        {' '}
        <a
          href="https://donellameadows.org/archives/leverage-points-places-to-intervene-in-a-system/"
          target="_blank"
          rel="noreferrer"
        >
          Leverage points: Places to intervene in a system
        </a>
        .
      </p>

      <p>
        Meadows, D. H. (2008). Thinking in systems: A primer. Chelsea green
        publishing.
      </p>

      <p>
        McKinsey &amp; Company, Bughin, J., Hazan, E., Lund, S., Dahlström, P.,
        Wiesinger, A., &amp; Subramaniam, A. (2018, May 23). Skill shift:
        {' '}
        <a
          href="https://www.mckinsey.com/featured-insights/future-of-work/skill-shift-automation-and-the-future-of-the-workforce"
          target="_blank"
          rel="noreferrer"
        >
          Skill shift: Automation and the future of the workforce
        </a>
        .
      </p>

      <p>
        MUSE Global. (2020).
        {' '}
        <a
          href="https://museglobal.org/contact-us"
          target="_blank"
          rel="noreferrer"
        >
          About us
        </a>
        .
      </p>

      <p>
        OECD. (2010). The nature of learning - using research to inspire
        practice.
      </p>

      <p>
        OECD. (2019).
        {' '}
        <a
          href="https://www.oecd.org/education/2030-project/teaching-and-learning/learning/learning-compass-2030/OECD_Learning_Compass_2030_concept_note.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Conceptual learning framework - LEARNING COMPASS 2030
        </a>
        .
      </p>

      <p>
        OECD. (2019).
        {' '}
        <a
          href="https://www.oecd.org/education/2030-project/teaching-and-learning/learning/student-agency/"
          target="_blank"
          rel="noreferrer"
        >
          Student agency - OECD future of education and skills 2030
        </a>
        .
      </p>

      <p>OECD. (2020). Building the future of education.</p>

      <p>
        OECD. (n.d.).
        {' '}
        <a
          href="https://www.oecd.org/pisa/innovation/"
          target="_blank"
          rel="noreferrer"
        >
          Innovation in PISA
        </a>
        . Home page - OECD.
      </p>
      <p>
        OECD &amp; Schleicher, A. (2020).
        {' '}
        <a
          href="https://www.oecd.org/education/the-impact-of-covid-19-on-education-insights-education-at-a-glance-2020.pdf"
          target="_blank"
          rel="noreferrer"
        >
          The impact of covid-19 on education insights from education at a glance 2020
        </a>
        .
      </p>
      <p>
        OECD, Reimers, F., &amp; Schleicher, A. (2020).
        {' '}
        <a
          href="https://globaled.gse.harvard.edu/files/geii/files/education_continuity_v3.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Schooling disrupted, schooling rethought How the Covid-19 pandemic is
          changing education
        </a>
        .
      </p>

      <p>
        Peterson, A. (2021).
        {' '}
        <a
          href="https://rethinkingassessment.com/rethinking-blogs/what-the-dodo-got-wrong/"
          target="_blank"
          rel="noreferrer"
        >
          What the dodo got wrong - rethinking assessment
        </a>
        .
        Rethinking Assessment.
      </p>
      <p>
        Populace. (2021). Systems change - A leader&#39;s guide to leverage
        points.
      </p>

      <p>
        Prothero, A. (2020, September).
        {' '}
        <a
          href="https://www.edweek.org/leadership/how-to-build-relationships-with-students-during-covid-19/2020/09"
          target="_blank"
          rel="noreferrer"
        >
          How to build relationships with students during COVID-19
        </a>
        . edweek.
      </p>
      <p>
        Psacharopoulos G. &amp; Rojas C. &amp; Velez E. (1992) Achievement
        evaluation of Colombia&#39;s Escuela Nueva: Is multigrade the answer?
      </p>

      <p>
        <a
          href="https://remakelearningdays.org/"
          target="_blank"
          rel="noreferrer"
        >
          Remake learning days
        </a>
        . (2021).
      </p>

      <p>
        Rewired. (2021, May 14).
        {' '}
        <a
          href="https://rewired.kumu.io/rewiring-nodes-in-the-education-system"
          target="_blank"
          rel="noreferrer"
        >
          Rewiring nodes in the education system
        </a>
        .
        rewired.

      </p>
      <p>
        Robinson, S. K. (2010, August).
        {' '}
        <a
          href="https://www.ted.com/talks/sir_ken_robinson_changing_education_paradigms"
          target="_blank"
          rel="noreferrer"
        >
          Changing education paradigms [Video]
        </a>
        .
        TED Talks.

      </p>

      <p>
        Schleicher, A. (2020, December 14).
        {' '}
        <a
          href="https://oecdedutoday.com/lessons-from-covid-bring-deeper-change-education/"
          target="_blank"
          rel="noreferrer"
        >
          Acting on lessons from COVID to bring about deeper change in education
          - OECD Education and Skills Today
        </a>
        .
        OECD Education and Skills Today.

      </p>

      <p>
        Schleicher, A. (2018).
        {' '}
        <a
          href="https://doi.org/10.1787/9789264300002-en"
          target="_blank"
          rel="noreferrer"
        >
          World Class: How to Build a 21st-Century School System
        </a>
        . Strong Performers and Successful Reformers in Education. OECD
        Publishing. Paris.
      </p>

      <p>
        Stevenson, M. (2021), Education for Human Flourishing: Orientation and
        competencies, OECD, Paris.
      </p>

      <p>
        Taviani, D. (2017, January 27).
        {' '}
        <a
          href="https://medium.com/@helios/a-new-kind-of-school-in-roermond-agora-13c58815fbed"
          target="_blank"
          rel="noreferrer"
        >
          A new kind of school in Roermond: Agora
        </a>
        . Medium.
      </p>

      <p>
        Teach for All. (2019). Community impact stories: Enseña perú,
        ancash.
      </p>

      <p>
        Teach for All. (2020).
        {' '}
        <a
          href="https://globallearninglab.teachforall.org/who-we-are-page"
          target="_blank"
          rel="noreferrer"
        >
          Global learning lab
        </a>
        .
      </p>
      <p>
        Teach for All. (2020, February 6).
        {' '}
        <a
          href="https://teachforall.org/news/working-together-change-ecosystem-community-impact-áncash-peru"
          target="_blank"
          rel="noreferrer"
        >
          Working together to change an ecosystem: Community impact in áncash, peru
        </a>
        . Teach For All.

      </p>

      <p>
        Teach For All. (2021, August).
        {' '}
        <a
          href="https://teachforall.org/news/indonesias-minister-education-nadiem-makarim-conversation-indonesian-student-nazma-zahira"
          target="_blank"
          rel="noreferrer"
        >
          Indonesia&#39;s minister of education Nadiem Makarim in conversation
          with Indonesian student Nazma Zahira
        </a>
        .
      </p>

      <p>
        UNICEF. (2018).
        {' '}
        <a
          href="https://www.unicef-irc.org/publications/pdf/an-unfair-start-inequality-children-education_37049-RC15-EN-WEB.pdf"
          target="_blank"
          rel="noreferrer"
        >
          An unfair start - inequality in children’s education in rich countries
          (Innocenti Report Card 15)
        </a>
        .
      </p>

      <p>
        UNICEF, Brossard, M., cardoso, M., Kamei, A., Mishra, S., Mizunoya, S.,
        &amp; Reuge, N. (2020). Parental engagement in children’s learning.
        Innocenti Research Brief 2020-09.
      </p>

      <p>
        Valerie, S. (2020, 29. August).
        {' '}
        <a
          href="https://www.washingtonpost.com/education/2020/08/29/an-unusual-model-bring-virtual-learning-life/"
          target="_blank"
          rel="noreferrer"
        >
          An unusual model to bring virtual learning ‘to life’
        </a>
        . The Washington Post.
      </p>

      <p>
        Walton, G. (2019).
        {' '}
        <a
          href="https://www.nature.com/articles/s41586-019-1466-y"
          target="_blank"
          rel="noreferrer"
        >
          A national experiment reveals where a growth mindset improves achievement - Nature
        </a>
        . Nature.

      </p>
      <p>
        Webb, A. (2019, May 3).
        {' '}
        <a
          href="https://medium.com/pi-top/meet-the-school-with-no-classes-no-classrooms-and-no-curriculum-7cc7be517cef"
          target="_blank"
          rel="noreferrer"
        >
          Meet the school with no classes, no classrooms and no curriculum
        </a>
        . Medium.
      </p>

      <p>
        Winthrop, R. (2018). Leapfrogging inequality: Remaking education to help
        young people thrive. Brookings Institution Press.
      </p>

      <p>
        Winthrop, R., Barton, A., Ershadi, M., &amp; Ziegler, L. (2021).
        <a
          href="https://www.brookings.edu/wp-content/uploads/2021/10/Family_School_Engagement_Playbook_FINAL.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Collaborating to transform and improve education systems
        </a>
        . Brookings.
      </p>

      <p>
        Wise Qatar. (2019, April 24).
        {' '}
        <a
          href="https://www.wise-qatar.org/project/the-happiness-curriculum/"
          target="_blank"
          rel="noreferrer"
        >
          The Delhi government&#39;s happiness curriculum - WISE
        </a>
        .
      </p>

      <p>
        World Bank. (2016, October 3).
        {' '}
        <a
          href="https://www.worldbank.org/en/news/press-release/2016/10/03/nearly-385-million-children-living-extreme-poverty-joint-world-bank-group-unicef-study"
          target="_blank"
          rel="noreferrer"
        >
          Nearly 385 million children living in extreme poverty, says joint
          World Bank Group – UNICEF study
        </a>
        .
      </p>

      <p>
        World Bank. (2021, January 22).
        {' '}
        <a
          href="https://www.worldbank.org/en/news/immersive-story/2021/01/22/urgent-effective-action-required-to-quell-the-impact-of-covid-19-on-education-worldwide"
          target="_blank"
          rel="noreferrer"
        >
          Urgent, effective action required to quell the impact of COVID-19 on education worldwide
        </a>
        .
      </p>
      <p>
        Yeager, D.S., Hanselman, P., Walton, G.M. et al., (2019)
        {' '}
        <a
          href="https://www.nature.com/articles/s41586-019-1466-y"
          target="_blank"
          rel="noreferrer"
        >
          A national experiment reveals where a growth mindset improves
          achievement

        </a>
      </p>
    </div>
    <div className="footnotes-section">
      <h3>Footnotes</h3>
      <ol className="references-notes">
        {annotations.map(({ id, text }) => (
          <li key={id}>
            {text}
          </li>
        ))}
      </ol>
    </div>
  </div>
);

export default References;
