import React from 'react';

import './share-icons.scss';

export const TwitterIcon = (
  <svg viewBox="0 0 33.1 26.9" className="share-icon">
    <path className="share-icon-fillable" d="M10.4 27A19.2 19.2 0 0029.7 7.5v-.9a13.8 13.8 0 003.4-3.5 13.8 13.8 0 01-3.9 1 6.8 6.8 0 003-3.7 13.5 13.5 0 01-4.3 1.6A6.8 6.8 0 0016 6.8a7.5 7.5 0 00.2 1.6 19.3 19.3 0 01-14-7.1 6.8 6.8 0 002.1 9 6.9 6.9 0 01-3-.8 6.8 6.8 0 005.4 6.7 6.8 6.8 0 01-3 .2A6.8 6.8 0 0010 21 13.6 13.6 0 011.6 24a13 13 0 01-1.6-.1 19.3 19.3 0 0010.4 3" />
  </svg>
);

export const FacebookIcon = (
  <svg viewBox="0 0 27.3 27.3" className="share-icon">
    <g>
      <path className="share-icon-fillable" d="M25.8 0H1.5A1.5 1.5 0 000 1.5v24.3a1.5 1.5 0 001.5 1.5h13V16.7H11v-4h3.6V9.5C14.6 6 16.7 4 19.9 4a30.2 30.2 0 013.2.2V8h-2.2c-1.7 0-2 .8-2 2v2.6h4l-.5 4.1h-3.6v10.6h7a1.5 1.5 0 001.5-1.5V1.5A1.5 1.5 0 0025.8 0z" data-name="Path 203" />
      <path fill="none" d="M18.8 27.3V16.7h3.6l.5-4h-4V10c0-1.2.3-2 2-2H23V4.3a30.2 30.2 0 00-3.2-.2c-3.2 0-5.3 2-5.3 5.5v3H11v4.1h3.6v10.6z" />
    </g>
  </svg>
);

export const WhatsAppIcon = (
  <svg viewBox="0 0 29.3 29.5" className="share-icon">
    <path
      className="share-icon-fillable"
      fillRule="evenodd"
      d="M25 4.3A14.6 14.6 0 002 21.9l-2 7.6 7.7-2a14.6 14.6 0 007 1.7 14.6 14.6 0 0010.4-25zM14.8 26.7a12.1 12.1 0 01-6.2-1.6l-.4-.3L3.5 26l1.2-4.5-.3-.4a12.1 12.1 0 1110.3 5.6zm6.7-9l-2.5-1.2c-.3-.1-.6-.2-.8.1l-1.2 1.5c-.2.3-.4.3-.8 0a10 10 0 01-3-1.7 11 11 0 01-2-2.6c-.2-.3 0-.5.2-.7l.6-.7a2.5 2.5 0 00.3-.6.7.7 0 000-.6l-1.1-2.7c-.3-.7-.6-.6-.8-.6h-.7a1.3 1.3 0 00-1 .4 4 4 0 00-1.3 3 7.1 7.1 0 001.5 3.8 16.3 16.3 0 006.2 5.5 21.1 21.1 0 002.1.8 5 5 0 002.3.1 3.8 3.8 0 002.5-1.7 3 3 0 00.2-1.7c-.1-.2-.4-.3-.7-.4z"
    />
  </svg>
);

export const LinkedInIcon = (
  <svg viewBox="0 0 27.9 27.9">
    <path
      className="share-icon-fillable"
      d="M22.1 0H5.8A5.8 5.8 0 000 5.8v16.3a5.8 5.8 0 005.8 5.8h16.3a5.8 5.8 0 005.8-5.8V5.8A5.8 5.8 0 0022.1 0zM9.3 22.1H5.8V9.3h3.5zM7.6 7.8a2 2 0 112-2 2 2 0 01-2 2zm15.7 14.3h-3.5v-6.5c0-4-4.7-3.6-4.7 0V22h-3.5V9.3h3.5v2c1.6-3 8.2-3.2 8.2 3z"
    />

  </svg>
);

export default { TwitterIcon, FacebookIcon, WhatsAppIcon };
