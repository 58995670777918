import React from 'react';
import PropellerWithLabels from '../propeller-with-labels';

import '../drivers-explainer/drivers-explainer.scss';

const DRIVERS = [
  {
    title: 'Purpose',
    actions: [
      'Engage stakeholders in defining the purpose of education',
      'Reveal and challenge the shared assumptions blocking transformation',
      'Discover, connect and amplify new alliances'],
  },
  {
    title: 'Power',
    actions: [
      'Liberate agency and co - agency of educators and learners',
      'Enable a wide range of stakeholders to take part in decision making',
      'Make data and information more accessible, useful and interesting to stakeholders',
    ],
  },
  {
    title: 'Practice',
    actions: [
      'Make learning more learner centred',
      'Integrate new ways to assess and recognise learning',
      'Strengthen the link between learning science and teaching practice',
    ],
  },
];

const ActionsExplainer = () => (
  <div className="drivers-explainer actions-explainer">
    <PropellerWithLabels />
    <div className="drivers-explainer-content">
      { DRIVERS.map(({ title, actions }) => (
        <div className="drivers-explainer-content-item" key={title}>
          <div className="drivers-explainer-content-item-header">
            Actions
          </div>
          <div className="drivers-explainer-content-item-title">
            {title}
          </div>
          {actions.map((action) => <p key={action}>{action}</p>)}
        </div>
      )) }
    </div>
  </div>
);

export default ActionsExplainer;
