import React from 'react';

interface MainTitleProps {
  fill?: string;
}

const MainTitle = ({ fill } : MainTitleProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 564 306"
    className="main-title"
  >
    <path
      fill={fill}
      d="m71.8 79.1 9.1-3.5L112.3 0h3.5l32.4 77.5 5.8 1.6v1.7h-13.7c-3.9
      0-7.2-.8-9.7-2.7a16.9 16.9 0 0 1-5.5-7l-5.8-13.6H92l-7.4 18.1 8.5
      3.5v1.7H71.8v-1.7Zm21.3-24.5h25l-12.6-29.9-12.4 30ZM199.6 20.4v55.2l9
      3.5v1.7H187v-1.7l9-3.5V5.2l-8-2.3V1.2h12.2c4.1 0 7.2.9 9.5 2.1a24 24 0 0 1
      6.4 5.8l36.5 42.6V6.2l-9-3.3V1.2h21.6V3l-9.3 3.3v76.2h-3.5l-52.8-62ZM276.1
      74.4c-5.3-5.4-8-12.8-8-22 0-6.2 1.2-11.6 3.5-16.1 2.3-4.6 5.6-8
      9.9-10.5a28 28 0 0 1 14.4-3.7c5.6 0 10.4 1 14.5 3a22 22 0 0 1 9.5 8.3 20.4
      20.4 0 0 1 2.7 16.5l-.7 1.8a158.5 158.5 0 0 1-34.7 3.3c0 8.9 1.3 15 3.8
      18.8 2.5 3.7 5.8 5.6 10 5.6 3 0 5.9-.7 8.3-2.1 2.5-1.4 4.6-3.3 6-5.6
      1.5-2.2 2.3-4.7 2.3-7.2h2.9c0 3.1-.8 6-2.7 8.7a20 20 0 0 1-7.6 6.6c-3.3
      1.6-7 2.4-11.4 2.4-9.9.2-17.3-2.4-22.7-7.8Zm23.1-22.7c1.3 0 2.7-.2
      4.2-.6l.6-2.5c.4-1.8.6-3.9.6-6
      0-6.3-.8-10.9-2.5-13.7-1.6-3-3.7-4.4-6-4.4-5.7 0-8.4 9.3-8.4 27.9 4.5 0
      8.4-.3 11.5-.7ZM330.6 27.4l-5.8-1.6V24H338c4.2 0 7.3.8 9.5 2.5 2.3 1.6 4.4
      4.1 5.8 7.2L365.9 60l6.4-16.7-7.6-15.9-5.8-1.6V24H372c4.1 0 7.2.8 9.5 2.5
      2.2 1.6 4.3 4.1 5.8 7.2L399.9 60l12-30.7-8.4-3.5V24h21v1.7l-9 3.5-21.1
      53h-3.5l-17-35.1-13.8 35h-3.5l-26-54.8ZM0 177.9l6.8-1.7v-73L0
      101.7V100h60.9v20.4h-3.5a18.8 18.8 0 0 1-3.7-4.4 25.2 25.2 0 0
      1-3.8-13.4H25.6v36h13.2a26.7 26.7 0 0 1
      5-11.4h3.5v25.5h-3.5c-.9-1-1.7-2-2.1-3.3a22.1 22.1 0 0
      1-2.9-8H25.6v35.2h26.8c0-4.7 1.3-9.2 3.7-13.4 1-1.6 2.3-3
      3.7-4.3h3.6v20.4H0V178ZM83.8 177.7a23.3 23.3 0 0
      1-8.3-10.3c-2-4.6-3-10.1-3-16.5 0-9.7 2.2-17.1 6.7-22.5a23.1 23.1 0 0 1
      18.4-7.8 19.6 19.6 0 0 1 13 4.5v-21.8l-6.8-1.7V100h14.9c6.8 0 10.3 3.5
      10.3 10.3v66l6.8 1.6v1.6h-10.3c-4.6
      0-8-1.4-10.6-4.1-1-1.2-1.8-2.7-2.4-4.3-1 1.8-2.3 3.7-3.7 5.1a17.1 17.1 0 0
      1-12.8 5.2c-4.8-.2-8.7-1.3-12.2-3.7Zm24.1-5c.9-1.2 1.7-2.7
      2.7-4.5v-39.4c-.8-.8-1.6-1.6-2.7-2.2-2-1.5-4.1-2.3-6-2.3-3.3 0-5.7 1.8-7.6
      5.8a53.7 53.7 0 0 0-2.7 21c0 9.9.9 16.7 2.5 20.4 1.7 3.9 3.7 5.8 6.2 5.8
      2.9 0 5.4-1.5 7.6-4.6ZM156.6
      178.5c-3.3-1.9-6-4.1-7.8-7-1.9-2.9-2.9-6-2.9-9v-36.6l-6.8-1.6v-1.7H154c6.8
      0 10.3 3.5 10.3 10.3v29.7c0 4.8.8 8.5 2.4 11 1.7 2.4 3.8 3.9 6 3.9 3 0
      5.4-1.5 7.7-4.6a67 67 0 0 0 2.7-4.5v-42.5l-6.9-1.6v-1.7h32v1.7l-6.8
      1.6v54h-1.2c-5.6 0-9.7-1.4-12.6-4.5a11.5 11.5 0 0 1-2.7-4.5c-1 1.8-2.3
      3.7-3.7 5.1a17.1 17.1 0 0 1-12.8 5.2c-4.5 0-8.3-.8-11.8-2.7ZM222.9
      173.1c-5.4-5.3-8-12.7-8-22s2.6-16.7 8-22c5.3-5.4 13-8.1 22.7-8.1a27 27 0 0
      1 15.7 3.7c3.5 2.5 5.3 5.4 5.3 8.9 0 2.6-.8 4.7-2.2 6.1a8.2 8.2 0 0 1-6.2
      2.3c-1 0-2.1 0-3.1-.2l-1.5-.4v-17.1l-1.6-.6c-1.3-.4-2.5-.6-4.2-.6-4.3
      0-7.6 2-10 6.1a45.6 45.6 0 0 0-3.6 21.7c0 10 1.3 17 3.5 21.2a11 11 0 0 0
      10.1 6.2c3 0 5.8-.6 8.3-2 2.5-1.5 4.5-3.4 6-5.6 1.4-2.3 2.3-4.8
      2.3-7.3h2.8c0 3.1-.8 6-2.6 8.7a20 20 0 0 1-7.7 6.6c-3.3 1.7-7 2.5-11.3
      2.5-9.7 0-17.4-2.7-22.7-8ZM281.9 176.8a13.5 13.5 0 0 1-5.6-11 22.8 22.8 0
      0 1 4-12.7 158.6 158.6 0 0 1
      31.8-4.5v-3.5c0-8.4-1.1-14.2-3.2-17.5-2-3.1-5.1-4.8-9.5-4.8-1.6 0-2.8.3-4
      .7l-1.7.6v17l-1.5.5c-1.2.2-2.3.2-3 .2-2.8 0-4.8-.8-6.3-2.3a8.2 8.2 0 0
      1-2.2-6.1c0-3.5 1.8-6.4 5.3-9 3.5-2.4 8.9-3.6 15.7-3.6 6 0 11 1 15.3 3 4.3
      2.1 7.6 5 9.9 8.7 2.3 3.7 3.3 7.9 3.3 12.8V176l6.8 1.7v1.6h-10.3c-4.6
      0-8-1.4-10.5-4.1-1-1.2-1.9-2.7-2.5-4.3-1 1.8-2.3 3.7-3.7 5.1a17.1 17.1 0 0
      1-12.8 5.2c-6.4 0-11.6-1.5-15.3-4.4Zm27.9-4 2.6-4.6v-16.5a80.8 80.8 0 0
      0-14.8 1.2l-1.3 3.5a31 31 0 0 0-1.2 8.5c0 4.3.6 7.4 1.9 9.5 1.2 2 2.9 3 5
      3 2.8-.1 5.5-1.6 7.8-4.7ZM356.2 178.5a20 20 0 0 1-8.5-7.4 19 19 0 0
      1-2.9-10.7v-34.9h-8v-2.9h8V117c0-6.8 3.6-10.3
      10.4-10.3h8v15.8h15.9v3h-15.9v34.5c0 7 .6 11.8 2 14.5 1.5 2.7 3.6 3.9 6.5
      3.9 2.7 0 5.1-1.2 7.2-4 2-2.6 3-6.1 3-10.6h3c0 5.5-1.5 9.8-4.1 13-3 3-6.7
      4.5-11.2 4.5-5.4 0-9.7-.8-13.4-2.7ZM390.9
      177.9l6.8-1.7V126l-6.8-1.6v-1.7h14.8c6.8 0 10.3 3.5 10.3 10.3v43.3l6.9
      1.7v1.6h-32V178Zm9.3-64.3a8.5 8.5 0 0 1 0-11.5 8.5 8.5 0 0 1 11.6 0 8.5
      8.5 0 0 1 0 11.5 8.1 8.1 0 0 1-5.9 2.2c-2.2 0-4.3-.8-5.8-2.2ZM437.7
      173.1c-5.4-5.3-8-12.7-8-22s2.6-16.7 8-22a30 30 0 0 1 22-8.1c9.6 0 16.8 2.7
      22.2 8 5.3 5.4 8 12.8 8 22 0 9.4-2.7 16.8-8 22.1a30 30 0 0 1-22.1 8 30 30
      0 0 1-22-8Zm30-.6c2-4.3 3-11.3 3-21.4
      0-10.1-1-17.3-3-21.5-2.1-4.3-4.8-6.3-7.7-6.3-3.1 0-5.6 2-7.6 6.3-2.1
      4.4-3.1 11.4-3.1 21.5 0 10 1 17.3 3 21.4 2.1 4.3 4.8 6.4 7.7 6.4 2.9 0
      5.6-2 7.6-6.4ZM494.7 177.9l6.8-1.7V126l-6.8-1.6v-1.7H505a13.1 13.1 0 0 1
      13 8.5c1-2 2.3-3.7 3.7-5.2 3.5-3.5 7.6-5.1 12.8-5.1 4.3 0 8 .8 11.6 2.7
      3.3 1.8 6 4 7.8 7 1.9 2.9 2.9 6 2.9 9V176l6.8 1.7v1.6h-14.9c-6.8
      0-10.3-3.5-10.3-10.3v-29.3a20 20 0 0 0-2.5-10.9c-1.6-2.5-3.7-3.9-6-3.9-2.8
      0-5.5 1.4-7.8 4.5a16 16 0 0 0-2.5 4.6v42l6.8 1.7v1.6h-32V178h.3ZM229.9
      279.5c-2-1-4-2.3-5.4-3.7-1 1.6-2.4 3.3-3.9 4.7l-2.3 1.7h-2.9v-21.7h4.6c1.2
      3.7 2.7 7 4.7 10.1a21.1 21.1 0 0 0 18 10c4.3 0 7.8-1.3 10.3-4a13.7 13.7 0
      0 0
      1.7-17.1c-1.5-2-3.3-3.7-5.6-5.2-2.3-1.4-5.4-3.5-9.3-5.7-5-3-9-5.4-12.2-7.7-3-2.2-5.8-5.1-8-8.6a22
      22 0 0 1-3.3-12.2c0-3.7 1-7 3-10 2.1-3.2 5.2-5.4 9.6-7.3 4-1.8 9.3-2.7
      15.4-2.7a26.8 26.8 0 0 1 18.2 7.5 24 24 0 0 1
      4-4.8l2.2-1.6h2.9v21.6H267a31 31 0 0 0-11.3-17.1 16 16 0 0 0-9.3-2.9c-2.9
      0-5.6 1.3-7.6 3.5a13 13 0 0 0-3.1 9c0 3 .8 5.4 2.5 7.7 1.6 2.3 3.7 4.1 6
      5.8 2.4 1.6 5.5 3.7 9.6 6 4.8 2.6 8.7 5.1 11.6 7.2a27 27 0 0 1 7.4 7.8c1.9
      3.1 3.1 6.8 3.1 11 0 4-1.2 7.8-3.5 11-2.3 3.4-6 6-10.5 8.1-4.8 2-10.5
      2.9-17.1 2.9-5.2.2-10.4-.8-15-3.3ZM300.3 280.3a20 20 0 0 1-8.5-7.4 19 19 0
      0 1-2.9-10.7v-34.6h-8v-3h8V219c0-6.8 3.5-10.3
      10.3-10.3h8v15.9h16v2.8h-16V262c0 7 .7 11.7 2.4 14.4 1.4 2.7 3.5 4 6.4 4
      2.6 0 5.1-1.3 7.2-4a17 17 0 0 0 3-10.7h3c0 5.5-1.5 9.9-4.2 13-2.9 3-6.6
      4.5-11.1 4.5-5.6-.2-10-1-13.6-2.9ZM343 275c-5.4-5.4-8-12.8-8-22.1s2.6-16.7
      8-22a30 30 0 0 1 22-8c9.6 0 16.8 2.6 22.1 8 5.4 5.3 8.1 12.7 8.1 22s-2.7
      16.7-8 22A30 30 0 0 1 365 283a30 30 0 0 1-22.1-8Zm30-.7c2-4.3 3-11.3
      3-21.4 0-10.1-1-17.3-3-21.4-2.2-4.2-4.8-6.4-7.7-6.4-3.1 0-5.6 2-7.7 6.4-2
      4.3-3 11.3-3 21.4 0 10.1 1 17.3 3 21.4 2.1 4.4 4.8 6.4 7.7 6.4 3 0 5.5-2
      7.6-6.4ZM402.2 279.7l6.8-1.7v-50l-6.8-1.7v-1.6h10.3a13.1 13.1 0 0 1 13
      8.4c1.3-1.6 2.5-3 4.2-4.5 3.7-3.1 8-4.6 12.8-4.6 4 0 7 1 9 3 2.1 1.8 3.1
      4.5 3.1 8 0 2.7-.8 4.7-2.2 6.2a8.2 8.2 0 0 1-6.2 2.2c-1 0-2
      0-3.1-.2l-1.5-.4v-16c-3.9 0-7.4 1.4-10.5 4.5a36 36 0 0 0-3.7 4.5v42l6.8
      1.7v1.6h-32v-1.4ZM470.1 303.2a10 10 0 0 1-2.9-7.4c0-3 .8-5.2 2.3-6.8a8.4
      8.4 0 0 1 6.2-2.5c.6 0 1.2 0 1.8.2l1.3.2 1.4.2v15.5a9.4 9.4 0 0 0
      5.8-3.7c1.7-2 3.5-5.6 5.6-10.5l2.2-5.8-29.5-54.6-5.7-1.7v-1.6h14.2c4 0 7
      .8 9.5 2.4a19 19 0 0 1 6 7.3l14.2 26.3 12-30.9-8.5-3.5v-1.6h21v1.6l-9
      3.5-22.7 58.6c-2.5 6.1-5 10.7-7.9 13.4a12.9 12.9 0 0 1-9.2 4c-3.6
      0-6.2-1-8-2.6Z"
    />
  </svg>
);

MainTitle.defaultProps = {
  fill: '#F07F1A',
};

export default MainTitle;
