import { withPrefix } from 'gatsby';
import React from 'react';
import Logos from '../logos';

import './contributors.scss';

const contributorList = [
  'Alex Beard, Teach For All',
  'Andreas Schleicher, OECD',
  'Annina Mattsson, RewirEd',
  'Emily Liebtag, Education Reimagined',
  'Emma Dorn, McKinsey & Company',
  'Essie North, Big Change',
  'Franco Mosso, Enseña Perú',
  'Fred Swaniker, African Leadership Group',
  'Joann McPike, THINK Global School',
  'Kelly Young, Education Reimagined',
  'Michael Stevenson, OECD',
];

const contributorList2 = [
  'Rebecca Winthrop, Center for Universal Education, The Brookings Institution',
  'Richard Culatta, International Society for Technology in Education',
  'Saku Tuominen, HundrED',
  'Sofie Yung, RewirEd',
  'Steven Farr, Teach For All',
  'Todd Rose, Populace',
  'Vicky Colbert, Fundación Escuela Nueva',
  'Vishal Talreja, Dream a Dream',
  'Wendy Kopp, Teach For All',
];

const Contributors = () => (
  <div className="contributors">
    <h2 className="contributors-title section">Partners and Contributors</h2>
    <div className="section contributor-lists">
      <ul className="contributors-list">
        { contributorList.map((person) => {
          const [name, company] = person.split(', ');
          return (
            <li key={name}>
              <b>{name}</b>
              ,
              {' '}
              {company}
            </li>
          );
        }) }
      </ul>
      <ul className="contributors-list">
        {contributorList2.map((person) => {
          const [name, company] = person.split(', ');
          return (
            <li key={name}>
              <b>{name}</b>
              ,
              {' '}
              {company}
            </li>
          );
        })}
      </ul>
      <div className="contributors-list">
        <h2 className="contributors-title contributors-title-authors">Authors</h2>
        <p>
          <b>
            Caireen Goddard, Connie K. Chung, Eva&nbsp;Keiffenheim and Julie
            Temperley
          </b>
        </p>
      </div>
      <div className="top-download-section">
        <div>
          <a
            className="top-download-button top-resource-button"
            href="https://big-change.org/wp-content/new-education-story/news-resources/"
          >
            <svg viewBox="0 0 45 46">
              <path
                fill="none"
                strokeWidth="2"
                stroke="white"
                d="M44 13.3a.9.9 0 00-.4-.7L26 1.2a1 1 0 00-1 0 1 1 0 00-.5.8v3.8h-.1l-1 .4a31.9 31.9 0 00-6.3 3 33.8 33.8 0 00-9.8 9.6 36.2 36.2 0 00-5.4 12.4 36.6 36.6 0 00-.5 13 1 1 0 00.9.8 1 1 0 001-.8 31 31 0 013.5-10.8 25.5 25.5 0 016.7-7.7 20.3 20.3 0 018.1-3.8 18.3 18.3 0 013-.3v3.3a1 1 0 001.5.8L43.6 14a1 1 0 00.4-.8z"
              />
            </svg>
            News and resources
          </a>
        </div>
        <div className="top-download-section-group">
          <a
            className="top-download-button"
            href={withPrefix('/New_Education_Story_summary_v9.pdf')}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 45 59">
              <path
                fill="currentColor"
                d="M35 13.5a4.2 4.2 0 0 1-4.2-4.2V0H3.7C1.7 0 0 1.7 0 3.7v51.6c0 2 1.7 3.7
 3.7 3.7h36.9c2 0 3.7-1.7 3.7-3.7V13.5H35ZM22 44.5l-7.6-7.7 1.8-1.7 4.6
 4.6V23.3h2.5v16.4l4.6-4.6 1.8 1.7-7.7 7.7Z"
              />

            </svg>
            The summary
          </a>
          <a
            className="top-download-button"
            href={withPrefix('/NES_Two_Pager.pdf')}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 45 59">
              <path
                fill="currentColor"
                d="M35 13.5a4.2 4.2 0 0 1-4.2-4.2V0H3.7C1.7 0 0 1.7 0 3.7v51.6c0 2 1.7 3.7
 3.7 3.7h36.9c2 0 3.7-1.7 3.7-3.7V13.5H35ZM22 44.5l-7.6-7.7 1.8-1.7 4.6
 4.6V23.3h2.5v16.4l4.6-4.6 1.8 1.7-7.7 7.7Z"
              />

            </svg>
            Two pager
          </a>
        </div>
      </div>
    </div>
    <div className="section">
      <Logos dark />
    </div>
  </div>
);

export default Contributors;
