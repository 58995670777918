import React, {
  useEffect, useState, useRef,
} from 'react';
import useScrollPosition from '@react-hook/window-scroll';
import { useWindowWidth } from '@react-hook/window-size';
import createSlugFromText from '../../utils/slug';
import './navigation.scss';
import Hexagon from '../hexagon';
import YouTubePlayer from '../youtube-player';

const Navigation = () => {
  const scrollPosition = useScrollPosition(20);
  const windowWidth = useWindowWidth();
  const selfRef = useRef<HTMLElement>();

  const [sectionData, setSectionData] = useState([]);
  const [isMenuFixed, setIsMenuFixed] = useState(false);
  const [menuItemsHidden, setMenuItemsHidden] = useState(false);

  useEffect(() => {
    setIsMenuFixed(selfRef.current.getBoundingClientRect().y <= 0);
    setMenuItemsHidden(selfRef.current.getBoundingClientRect().y <= -100);
    setSectionData(sectionData.map((section) => {
      const isComplete = section.node.getBoundingClientRect().y <= 0;
      return { ...section, isComplete };
    }));
  }, [windowWidth, scrollPosition]);

  const prepareSectionData = () => {
    // Sections will never change, so we can load them on mount
    const domSections = Array.from(
      document.querySelectorAll('[data-section-title]'),
    );
    setSectionData(domSections.map((node) => {
      const htmlNode = node as HTMLElement;
      const title = htmlNode.dataset.sectionTitle;
      return { title, slug: createSlugFromText(title), node: htmlNode };
    }));
  };

  useEffect(prepareSectionData, []);

  const getColorByIndex = (index: number) => {
    switch (index) {
      case 3:
        return '#0086B5';
      case 4:
        return '#F07F1A';
      case 5:
        return '#82027E';
      default:
        return '#51B38A';
    }
  };

  return (
    <div className="navigation-wrapper">
      <h2>Contents</h2>
      <div className="section">
        <div className="navigation-image">
          <YouTubePlayer id="X2eTtWlyBqU" />
          <YouTubePlayer id="z_YJYAnQ5Y8" />
        </div>
      </div>
      <nav className={`navigation${isMenuFixed ? ' is-hidden' : ''}`} ref={selfRef}>
        <ul className={`navigation-section-list${
          isMenuFixed ? ' is-fixed' : ''}${
          menuItemsHidden ? ' is-menu-hidden' : ''}`}
        >
          {sectionData.map(({
            title, isComplete, slug, node,
          }, index) => (
            <li
              key={slug}
              className="navigation-section-list-item"
            >
              <a
                href={`#${slug}`}
                className="navigation-section-list-item-link"
                onClick={(ev) => {
                  ev.preventDefault();
                  node.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }}
              >
                <span
                  className="navigation-section-list-item-inner"
                  style={{ backgroundColor: getColorByIndex(index) }}
                >
                  {title}

                </span>
                <Hexagon
                  stroke={isComplete && (
                    (sectionData.length > index + 1 && !sectionData[index + 1].isComplete)
                    || sectionData.length === index + 1) ? 'white' : getColorByIndex(index)}
                  fill={isComplete && (
                    (sectionData.length > index + 1 && !sectionData[index + 1].isComplete)
                    || sectionData.length === index + 1) ? getColorByIndex(index) : 'transparent'}
                />
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
