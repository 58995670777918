import React from 'react';
import YouTube from 'react-youtube';

import './player.scss';

const YouTubePlayer = ({ id } : {id: string}) => (
  <YouTube
    videoId={id}
    className="video-player"
    containerClassName="video-player-wrapper"
  />
);

export default YouTubePlayer;
