import React, { useState, useEffect } from 'react';

import './questions.scss';

interface QuestionsCarouselProps {
  questions: string[]
}

const QuestionsCarousel = ({ questions }: QuestionsCarouselProps) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => setIsMounted(true), []);
  return (
    <div className="questions-carousel-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 60 70"
        className="questions-carousel-symbol"
      >
        <path
          fill="#000"
          d="M30 0 15 8.8 0 17.5v35l15 8.7L30 70l15-8.8 15-8.7v-35L45 8.8Zm-1.5
          23c3.4 0 6 .6 7.7 2 1.7 1.3 2.6 3 2.6 5.2 0 2-.8 3.6-2.2 4.9a12 12 0 0
          1-6.5 2.6l-.2 2.8h-1.4l-.1-3.3a6.9 6.9 0 0 0 3.2-2.5 8 8 0 0 0
          1.1-4.5c0-2.3-.4-3.9-1.2-4.9-.8-1-2-1.5-3.7-1.5l-2 .3-.7.3V30h-.5l-1
          .2c-.7 0-1.3-.3-1.8-.8-.5-.4-.7-1-.7-1.9 0-.7.3-1.4.8-2.1.6-.7 1.4-1.3
          2.6-1.8 1-.4 2.4-.6 4-.6zm.6 19.8c.7 0 1.3.2 1.8.8.5.5.8 1.2.8 2 0
          .7-.3 1.4-.8 2-.5.5-1.1.7-1.8.7s-1.3-.2-1.9-.8c-.5-.5-.7-1.2-.7-2
          0-.7.2-1.4.7-2 .6-.5 1.2-.8 1.9-.8z"
        />
      </svg>
      <h3 className="questions-carousel-lead">Questions to consider</h3>
      <div className="questions-carousel">
        {isMounted ? (
          <div className="questions-carousel-fake-items">
            {questions.map((question) => (
              <div
                className="questions-carousel-fake-item"
                key={`f${question}`}
              >
                {question}
              </div>
            ))}
          </div>
        ) : ''}
        <div className="questions-carousel-items">
          {questions.map((question, index) => (
            <div
              className={`questions-carousel-item${
                index === currentQuestion ? ' is-active' : ''}`}
              key={`p${question}`}
            >
              {question}
            </div>
          ))}
        </div>
        <div className="questions-carousel-pips">
          {questions.map((question, index) => (
            <button
              key={question}
              type="button"
              className={`questions-carousel-pip${
                index === currentQuestion ? ' is-active' : ''}`}
              onClick={() => setCurrentQuestion(index)}
              title={`See question ${index + 1}`}
            >
              Go to question
              {' '}
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuestionsCarousel;
