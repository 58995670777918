import React from 'react';

import './logos.scss';

interface LogosProps {
  dark?: boolean
}

const BIG_CHANGE = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 82 46"
    className="logos-big-change"
  >
    <path
      fill="#fff"
      d="M8.5 14a2 2 0 0 0-.4-1.1c-.4-.3-.8-.4-1.3-.4H4.4v5.1a9.6 9.6 0 0 0
          3.2-.1l.5-.4.4-.5a1 1 0 0 0 0-.7V14ZM4.3 5.6V9h2.4c.5-.1.9-.3
          1.3-.7V5.3l-.2-1c-.6-.8-2.2-.6-3.5-.5v1.8ZM2.5
          3v-.4c0-.2.2-.3.4-.3h.7l3 .1a6 6 0 0 1 2 .5l1 1.5v.2l.1.1.2 1.6v1a5.8
          5.8 0 0 1-1.1 2.5h-.1l-.6.6-.2.1c-.3.1-.5.2-.8.1H2.5V3Zm7.7
          12.7v.6l-.4.9a.3.3 0 0 1 0
          .2l-.2.4h-.1l-.1.2-.5.4-.2.1h-.2v.1h-.2c-.3.2-.7.2-1
          .3l-2.4.1H2.5v-4.5a32.9 32.9 0 0 1
          0-3.4h4.8l.6-.1h.2l.7.4.1.1v.1h.2v.2h.2c0 .3.2.6.4.8V12.8a2 2 0 0 1
          .3.6V13.7l.1.2v.2l.1.2v1.2Zm.5-1.4V14l-.1-.2v-.2l-.2-.3a3 3 0 0
          0-.3-1l-.1-.1-.2-.4-.3-.3-.1-.2h-.1a2 2 0 0
          0-.8-.7l.7-.9.2-.3.4-.7c.4-.8.5-1.7.5-2.7 0-.6-.2-1.2-.4-1.8V4a7 7 0 0
          0-1-1.4l-.3-.1C8 2 7.1 1.9 6.3 1.9H6c-.6-.1-1.1-.2-1.7-.1h-2a.5.5 0 0
          0 0 .5V2.6l-.2 1V19a.1.1 0 0 0 .1.2.5.5 0 0 0 .4.1H5c1 0 2 0
          3-.2l.5-.2h.1l.1-.1a.3.3 0 0 0 .2-.1 2 2 0 0 0
          1-.9l.1-.1v-.2c.2-.1.2-.3.3-.4v-.3l.3-.9v-1.7ZM1 21.1.6
          21V.3h3.7L5.9.2a6.4 6.4 0 0 1 4.3 1.3l.4.5.6 1 .3.7.2 1.8c.2 1 0 2-.3
          2.8-.2.8-.6 1.5-1.2 2.1l.7.6c.2.2.5.4.6.7l.3.5.3.8.1.6v.6l.1
          1v.6l-.1.4c0 .7-.2 1.3-.5 1.9l-.8 1.4a6 6 0 0 1-1
          .9c-.1.2-.4.3-.7.3-.3.3-.6.2-1 .3a7 7 0 0 1-1 .1H3.8a20.4 20.4 0 0 1-3
          0M15.3 14.3V20h.1l.2.1v-7a61.3 61.3 0 0
          1-.1-4V1.5h-.1c-.2.1-.1.3-.1.5v4.6l-.1 2.2V13l.1
          1.3Zm-1.7-8.6V.5l.2-.2a19.4 19.4 0 0 1 3.4 0v4l.1 3.4V9c.1.1.1.3 0
          .4v.4l.1 1V12c0 1.2-.2 2.6-.1 4l.1 1.3V21c-.2.2-.6.2-1
          .2h-1.8l-.9-.1V5.7M28.6 19.2v-.4l-.2-.3c0-.2 0-.3-.2-.4-.4-.7 0-1.6
          0-2.4V11.6a.8.8 0 0 0-.6-.2h-1.8c-.2 0-.7-.1-.7.2 0
          .2.4.1.7.1h1l.7.1h.3v4.1c-.1 1 .1 1.9-.7
          2.4l-.3.3-.2.2-.7.4c-.6.2-1.2.2-1.9.1a3 3 0 0 1-.5
          0h-.2l-.6-.1-.5-.2a1 1 0 0
          1-.4-.3l-.4-.2-.3-.3c-.3-.5-.4-1-.4-1.6V11c0-.6-.2-1-.1-1.6V5.6l.3-.8v-.6l.2-.6.3-.5c.3-.6
          1.1-.8 1.7-1.2h.2l1-.2a5 5 0 0 1 2 .6c.3.1.4.3.5.5l.3.3.2.3c.4.6.6
          1.3.6 2V6.1c.3 0 .3-.3.3-.4v-.1a4 4 0 0
          0-.6-2.6c-.3-.4-.6-.8-1-1-.7-.5-1.5-.7-2.4-.7h-.1a2 2 0 0 0-.7
          0l-.6.3a4 4 0 0 0-1.4.8l-.2.3-.5.8-.2 1.3-.1.4-.1.8-.1.8v8.5l.1.4v.5c0
          .7.2 1.5.6 2.1l.3.4.4.2.5.3.5.2.6.1h.5a4.7 4.7 0 0 0
          2.7-.2l.8-.6.3-.4.3-.4.3.5.1.6c.2.3 0 1 .5 1v-.5l-.1-.4Zm-2.4-12c0-1.3
          0-2.6-.6-3.3-.4-.2-.7-.4-1.1-.4l-1 .2-.6.5a1.2 1.2 0 0 0-.5 1v1.5l-.1
          1.5V13l.1 3.4c.1.4.4.8.8 1 .3.2.6.2 1 .2h.4c.5-.1 1-.4
          1.3-.8l.4-1-.1-1.3V13h-1.8c-.2-1-.2-2.1.1-3.1l.2-.2h5.2v.2c0 1.5-.2
          2.8 0 4.6V21l-.2.2c-.6.1-1.2 0-1.8-.2 0 0 0 .2-.2.1-.2-.2
          0-.7-.3-1-.2.3-.6.4-1 .5l-1.2.6-1.6.2c-.6 0-1.1-.1-1.7-.4 0 0 .1 0 0
          0l-.5-.2a6 6 0 0
          1-1.3-1.2c-.5-.5-.8-1-1-1.6-.4-.8-.1-1.6-.3-2.1V10l-.2-4.6c0-.5
          0-1.1.2-1.7l.2-.5.1-.4.4-.6.4-.5.3-.3C21.1.9 21.8.3 22.8
          0H26l.4.2.8.2.5.2c.5.3 1 .8 1.3 1.4l.7 1.6.3.8v2.7c-1.2.2-2.6 0-3.7
          0M11.3 39v1.6c0 .4-.2 1-.4 1.4l-.5.9c-.3.6-.9.8-1.4 1.3l-1.1.5a21 21 0
          0 1-2 .4L4.2 45l-.7-.1a3 3 0 0
          1-1.3-.6l-.5-.4-.8-1-.4-.7-.2-.5-.2-.4v-.8l-.1-1v-3.8L0 33.4v-1.5a20
          20 0 0 1 .2-3.6l.3-1.2c0-.5.3-1 .6-1.4 0-.5.5-.7 1-1l1-.5
          1-.4h2.7c.4.2.7.1 1 .2l.8.3.6.4.1.2.2.1.3.3.2.3.6.8c.6 1.2.6 2.9.6
          4.5a1 1 0 0 1-.6.1l-1.4.1a4 4 0 0 1-1 0c-.3-.1-.5-.1-.6-.3 0-.8
          0-1.6-.2-2.4l-.1-.4c-.3-.5-1.3-.8-2-.6l-.8.2-.5.5c-.5.8-.2 2-.2
          3v7.2l.1 1.9a1.5 1.5 0 0 0 1 1.1c.4.2 1 .2 1.4
          0l.4-.2.8-.5.1-.4c0-.6.3-1.3 0-2l.1-.4c.8-.3 1.6-.4
          2.4-.3l.7.2h.5V39M16 27.2a62.5 62.5 0 0 0 .3 5.5h4.3v-8.5c1.2-.3
          2.4-.3 3.6 0a.5.5 0 0 1 0 .4V39l.1 1v.3l.1.6v4c-.3.2-.6 0-1
          0h-2.6l-.2-.1v-8.4h-4.1c-.1.5 0 1.2-.2 1.8V44.4a.6.6 0 0 1 0
          .5v.1a21.9 21.9 0 0
          1-3.6-.1c-.1-2-.2-3.7-.1-5.4V27.2l.1-.8v-2.3h.4c.1-.1.2 0 .2 0
          0-.2.4-.1.6-.2h1.3c.4 0 .7.2 1 .3 0 1-.2 2-.1 3M33.4
          37.1c-.2-1.2-.4-2.4-.8-3.7l-.5-2.2-1.3 6 2.6-.1Zm5.3 7.3c0 .2.1.3 0
          .4a23.3 23.3 0 0 1-3.7 0l-.2-1.4c-.3-.8-.5-1.7-.6-2.6l-4 .1-1
          4h-3.5c-.1 0 0-.2 0-.4l.3-2.3.2-1 .4-2 .3-1.2c0-.2
          0-.5.2-.7l.2-2.2.3-.9v-.5c.2-.2.3-.4.2-.6l.1-.1.5-2.8.2-.8c0-.2
          0-.5.2-.7l.1-.6.3-1.6V26l.3-1.3.3-.9c1.5-.2 3-.2 4.4 0h.2l.5 2.9.2 1
          .2.8.6 2.8 1.1 5.3c.2.5.3 1 .3 1.6l.5 1.4c.2 1 .3 2 .6
          2.9l.1.7.1.7v.4ZM52.3 44.6l-.1.2h-3.6L48 44 47 41a7 7 0 0
          1-.5-1.5l-.6-1.5-1.7-3.7a3 3 0 0 1-.2-.7l-.2-.3v6.2l.1
          1v4.2h-.3c-1.1.3-2.3.3-3.4 0V42l-.1-1v-6.8l.1-3v-7c1-.3 2.2-.3
          3.3-.2h.1c.3.6.6 1.2.7 1.8.5.9 1 1.8 1.3 2.8l1 2.4c.6 1.6 1.3 3.2 2.1
          4.7l.1-1.8V24c1.2-.3 2.4-.3 3.5 0v20.5M61
          30.9c.1-1.3.1-2.5-.6-3.3l-1-.4c-.4 0-.7.2-1 .3l-.7.4a1.2 1.2 0 0 0-.5
          1v8c-.1 1 0 2.2.1 3.3.1.4.4.8.7 1.1l1 .2h.5c.5-.2 1-.5
          1.3-.9l.3-1v-2.7h-1.9c-.2-1-.1-2.2.2-3.2l.1-.2h5.2l.1.2c0 1.5-.2
          2.8-.1 4.6l.1 4.7v1.7l-.2.2c-.7.1-1.3 0-1.9-.1 0 0 0 .1-.1
          0-.2-.2-.1-.7-.3-1-.2.3-.6.4-1 .5-.4.3-.8.5-1.3.6-.5.2-1 .2-1.6.2-.5
          0-1-.1-1.6-.4l-.5-.1c-.6-.4-1-.8-1.4-1.3-.4-.5-.8-1-1-1.6-.3-.8
          0-1.5-.3-2.1v-5.8l-.1-4.6.1-1.8.2-.4.2-.5.4-.5.4-.6.3-.3c.8-.5 1.6-1
          2.6-1.2.8-.2 1.7-.2 2.5-.1l.5.1h.4l.9.3.4.2c.6.4 1 .8 1.3 1.4.3.5.6 1
          .7 1.6l.3.9v2.6c-1.2.3-2.5 0-3.6 0M77.4 43.8l-.1 1a.2.2 0 0 1-.3
          0h-3.7l-3.1.1h-4V28.5H66a216.8 216.8 0 0 0 .2-4.6c2.6-.2 5.2 0 7.9
          0h3.2v.4l.1.5v2l-.2.9h-7.4V29c-.1 1.3-.3 2.6 0 3.6h5.8c.2 1.2.2 2.3 0
          3.5H70a35 35 0 0 0 0 5c2 0 4.2.2 6 .1h.4c.3 0 .6-.1.8 0 0-.1.2-.2.3 0
          .2.7.2 1.6 0 2.5M81.6 45c-1 0-2 0-3-.2l-.1-1.6v-1.7c1.1-.2 2.2-.2 3.3
          0 .2.4.2 1 .1 1.5l-.1 1.7-.2.3Z"
    />
  </svg>
);

const HUNDRED = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 106 21"
    aria-label="HundrED"
    className="logos-hundred"
  >
    <path
      fill="#fff"
      d="M17.47 20.35c-1.58-.63-1.83-1.75-1.83-8.36V5.67h5.08v5.14c0 2.83.17
          5.4.37 5.7.2.31.8.5 1.36.42 1-.14 1.01-.2
          1.13-5.7l.12-5.56h5.05V19.9l-1.59.25c-3.7.57-3.49.63-3.49-.9
          0-1.72-.28-1.74-1.86-.14-.68.68-1.47 1.25-1.76 1.25-.3
          0-.79.08-1.1.18-.3.1-.98.02-1.48-.19Zm31.6.02c-1.87-.45-2.76-2.24-2.93-5.93-.2-4.25.13-5.92
          1.47-7.5 1.48-1.74 3.54-1.75 5.27-.04l1.23 1.22V.7L59.6
          0V19.88l-5.47.5s-.02.03-.02-.98v-1.3l-1.16 1.07c-1.33 1.22-2.41
          1.56-3.87
          1.2Zm4.6-3.76c.48-.88.6-5.83.19-6.92-.36-.92-1.74-.83-2.27.15-.55
          1.03-.57 5.08-.02 6.5.48 1.28 1.5 1.4 2.1.27ZM0 .69 2.03.43l3.44-.4.03
          8.68 1.69-1.52c1.22-1.1 2.07-1.52 3.06-1.51 2.89.01 3.28 1.04 3.28
          8.56v6.12H8.46v-5c0-4.07-.13-5.14-.7-5.7-.37-.37-.96-.58-1.3-.45-.33.13-.69.24-.79.24-.1
          0-.17 2.45-.17 5.45v5.46H0V.69Zm30.86
          12.53c0-7.11.01-7.1.01-7.1l5.06-.56V8.67l1.77-1.5c2.12-1.8 4.37-2.05
          5.67-.62.7.77.83 1.7.95 7.35l.15
          6.46h-5.58v-5.2c0-5.55-.26-6.31-2.03-5.86-.9.24-.93.44-.93
          5.65v5.4h-5.07v-7.13Zm30.84-6.9 5.1-.76v2.55c0 1.4.16 2.65.36
          2.77.2.12 1.14-2.16 2.28-3.34a8.05 8.05 0 0 1 2.73-1.81s.12 1.14.12
          2.61v2.68l-1.7.22c-.94.13-2.18.53-2.75.9-.98.64-1.04.91-1.04
          4.45v3.77h-5.08c-.05-4.68-.02-14.04-.02-14.04Zm10.16 13.9
          4.7-19.16h12.98l-.51 1.99-.52 2-3.83.11-3.83.12-.26 1.3c-.15.71-.38
          2.05-.38 2.05l4.86-.02-.8 3.46h-5.1l-.91 4.1 3.9-.01h4l-.52 2-.5
          1.98-13.28.08Zm15.33-.39c.1-.29 1.22-4.63 2.47-9.65l2.27-9.12h4.6c5.28
          0 7.09.59 8.2 2.68 1.7 3.17.1 10.7-2.97 13.85-2.24 2.32-3.83 2.76-9.96
          2.76-3.66 0-4.76-.12-4.6-.52Zm10.02-4.58c1.43-1.52 2.71-6.95
          2.35-8.73-.32-1.63-2.8-1.77-2.8-1.77L93.9 16.01s2.38.23 3.3-.76Z"
    />
  </svg>
);

const REWIRED = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 115 34"
    className="logos-rewired"
  >
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="m81.2 25.2 4.4 8.6h-4.4v-8.6ZM96 33.3l6.7-3.6-1.8-3.3-7 3.6-2.8
            1.3c-.2 0-3.6-6.5-3.6-6.8l3.6-2 4.2-2.2-1.8-3.2-1.9.9-5.9
            3-3.1-5.8c0-.2 1.6-1.1 4.9-2.8l3.3-1.7 1.2-.6-.8-1.8-1-1.7a494.6
            494.6 0 0 0-8.9 4.5l-.1-5.5V0h20A36.5 36.5 0 0 0 94 4.4l-1.8 1.3 2
            3.8a645.4 645.4 0 0 0 10.4 19.5 33 33 0 0 0
            8.7-4l1.7-1v9.9H95.3l.7-.5Zm9.5-12.7L100
            9.8c-1.4-2.6-1.5-2.3.6-3.3a9.9 9.9 0 0 1 5-1.5c2.2 0 3.2.4 4.8 2 2
            2.1 3.4 5.2 3.2 7.7-.1 2.3-1.4 4.2-3.8 5.7-1 .7-3 1.7-3.3
            1.7l-.9-1.5Zm8.3-18.4L111.5.8a5 5 0 0 1-1.3-.7l2.3-.1c1.8 0 2.3 0
            2.4.2V3.1l-1-.9Z"
      />

    </g>
    <g clipPath="url(#b)">
      <path
        fill="#fff"
        d="M0 20.6v-8.4l.5-.2a15.6 15.6 0 0 1 8.9-.5l-.1 1c-.3 1-.3
            1-.6.9a13 13 0 0 0-5.7 0l-.7.2V29H0v-8.4Zm17.9
            8.2c-1.5-.3-2.8-1-3.8-2-1.7-1.8-2.4-4-2.3-7.5a8.6 8.6 0 0 1 4.3-8
            7.3 7.3 0 0 1 8.3 1.2c1.4 1.4 2 3 2.1 6v1.8h-6.1l-6.2.1v.7c.1 2 1 4
            2.3 4.8 1.2.8 2 1 4 1s2.1 0 3.2-.4l1.2-.4.2.7.2 1c0 .2 0 .3-.7.5a14
            14 0 0 1-6.7.5Zm6.3-11.2c-.4-3.3-2.2-5.1-5-5-1.3.1-2.3.6-3.3 1.6l-1
            1.5c-.3.7-.7 2-.7 2.4v.3h10v-.8ZM35 28.8l-.9-2.2a87.2 87.2 0 0
            1-4.7-15c0-.2.3-.2 1.1-.2h1.2l.7 2.7a77.7 77.7 0 0 0 4 12.2c.3.3
            3.2-9.2 4.4-14.5.1-.4.1-.4 1.1-.4h1l.2.9c.5 2.6 2 8.2 3.3 11.6 1 2.7
            1 2.6 1.3 1.8a105 105 0 0 0 4.2-14c.1-.3.1-.3 1.2-.3h1.2l-.2.7c-1.7
            6.7-3.5 12-5.3 16l-.4 1h-2.1l-.8-1.8c-1-2.7-2.4-6.7-3-9l-.7-2.3-.5
            1.6c-.9 3.3-2.7 8.4-3.7 11l-.2.4h-1.2c-1 0-1.1
            0-1.2-.2Zm22.8-8.6v-8.8h2.3V29h-2.3v-8.8Zm8.2.4V12l1.1-.3c2-.6
            2.6-.7 4.6-.7 1.7 0 3.1.2 3.6.4l-.1 1c-.2 1-.2 1-.6.9a14 14 0 0
            0-5.6 0l-.7.1V29H66v-8.4Z"
      />

    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          d="M0 0h33.8v33.8H0z"
          transform="translate(81.2)"
        />

      </clipPath>
      <clipPath id="b">
        <path
          fill="#fff"
          d="M0 0h75.3v18.4H0z"
          transform="translate(0 10.6)"
        />

      </clipPath>
    </defs>
  </svg>
);

const TFA = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 63 62"
    className="logos-tfa"
  >
    <ellipse cx="31.4" cy="30.8" fill="#fff" rx="31.4" ry="30.8" />
    <path
      fill="#1F3247"
      d="M17.1 38.9h.8v-2.2h-7.2v.7l.8.3c.2.1.3.3.3.6v5.9c0
          .3-.2.6-.5.6l-.7.2v.8h4.8V45l-1.4-.3c-.3
          0-.3-.3-.3-.5V42h3.6V41h-3.6v-3.2l2.2.1c.7 0 1 .3 1.2 1Zm4.5 7c2 0
          3.7-1.8 3.7-3.7 0-2.4-1.2-3.6-3.6-3.6-2 0-3.6 1.9-3.6 3.7 0 2.5 1.3
          3.6 3.5 3.6Zm0-6.2c1.5 0 1.7 1.4 1.7 2.5 0 1.4-.2 2.5-1.5 2.5-1.4
          0-1.8-1.1-1.8-2.5 0-1 .2-2.5 1.6-2.5Zm10.2-.8c-.3-.2-.7-.3-1.3-.3-.8
          0-1.5.5-1.9 1v-.5c0-.4-.3-.5-.6-.5l-2.3.3v.8H27v4.5c0
          .4-.2.5-.6.6l-.5.3v.7h4.6V45l-1.5-.4c-.1 0-.2 0-.2-.3v-3.8c.2-.2.8-.4
          1.5-.4l1.1.3.5-1.5ZM43 45.8V45c-.3-.3-.6-.6-.7-1l-3-7.6-1.7.4-3.2
          7.3-.7.9v.7h3.4l.1-.8-.8-.2c-.3-.1-.6-.3-.6-.7l.1-.3.6-1.6h3l.6
          1.6v.4c0 .3 0 .5-.4.6l-.7.2v.8h4Zm-3.8-4.5H37l.9-2.3.3-1h.1l.3 1 .7
          2.3Zm8.4 4.5V45l-1-.2-.1-.5v-7.8c0-.4-.3-.5-.7-.5-.6 0-1.8
          0-2.4.2v.9h1.2v7.1c0 .4-.1.6-.4.7l-.8.2.1.7h4Zm4.5 0 .1-.8-1-.2c-.2
          0-.2-.2-.2-.5v-7.8c0-.4-.2-.5-.6-.5-.6 0-1.8 0-2.4.2v.9h1.2v7.1c0
          .4-.2.6-.4.7l-.8.2v.7h4.1ZM19.5
          26.5h-.8c-.2-.8-.5-1.3-1.2-1.3H16v6.5c0 .3 0
          .5.3.5l1.2.2-.1.8h-4.7v-.8l.8-.2c.3 0 .5-.5.6-.9v-6.1h-1.6c-.7 0-1
          .6-1.1 1.3h-.8v-2.4h8.8v2.4Zm6 3.3v-.6c0-1.9-.8-3.2-3-3.2S18.9 28 18.9
          30c0 2.8 1.4 3.4 3.8 3.4.8 0 2-.3 2.7-.7l-.1-.5v-.4l-2.3.2c-1.2
          0-2.1-.4-2.2-2.1h4.7ZM22.4 27c.8 0 1.2.7 1.2 2h-2.9c0-1.2.6-2 1.7-2Zm9
          6.3c.6 0 1.4-.3 1.8-.7V32H32l-.1-2.3V28c0-2-1.9-2-3-2-.8
          0-1.6.1-2.5.5l.3 1.2 1.9-.3c1.2 0 1.5.2 1.5
          1v.8l-1.4.2c-1.4.2-2.8.7-2.8 2.3 0 1 .8 1.6 2 1.6 1 0 1.8-.7 2.2-1.1 0
          .7.3 1 1.3 1Zm-1.2-1.7-1.5.3c-.6 0-.8-.3-.8-.6 0-.6.4-.9
          1.2-1l1.1-.2v1.5Zm8.5-3.2c.5-.3.8-.6.8-1.3 0-.8-1.4-1-2.5-1a3.6 3.6 0
          0 0-3.7 3.6c0 2.6 1 3.7 3.5 3.7a5 5 0 0 0 2.6-.7l-.3-1-1.8.3c-1.5
          0-2.1-1-2.1-2.3 0-2 .6-2.6 1.8-2.6.5 0 1.3.8 1.7 1.3Zm9.8
          4.8v-.8l-.7-.1c-.2 0-.3-.2-.3-.4v-3.6c0-1.3 0-2.3-2.3-2.3a4 4 0 0
          0-2.5.9v-3c0-.4-.2-.5-.5-.5-.7 0-1.7.1-2.4.3v.8h1.1v7.2c0
          .3-.2.5-.4.6l-.6.2v.7h3.7l.1-.8-.7-.2c-.2 0-.3-.2-.3-.5v-4c.3-.2
          1.2-.4 1.9-.4 1 0 1.1.6 1.1 1.5v2.9c0 .2-.1.5-.3.5l-.5.3v.7h3.6Z"
    />
  </svg>
);

const Logos = ({ dark } : LogosProps) => (
  <div className={`logos${dark ? ' logos-dark' : ''}`}>
    <div className="logos-group">
      <a href="https://big-change.org" target="_blank" rel="noreferrer">
        {BIG_CHANGE}
      </a>
      <a href="https://www.rewired2021.com/" target="_blank" rel="noreferrer">
        {REWIRED}
      </a>
    </div>
    <div className="logos-group">
      <a href="https://teachforall.org/" target="_blank" rel="noreferrer">
        {TFA}
      </a>
      <a href="https://hundred.org/en" target="_blank" rel="noreferrer">
        {HUNDRED}
      </a>
    </div>
  </div>
);

Logos.defaultProps = {
  dark: false,
};

export default Logos;
