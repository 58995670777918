import React from 'react';
import { withPrefix } from 'gatsby';

import {
  DownloadIcon, TwitterIcon, LinkedInIcon, FacebookIcon, MailIcon,
} from '../icons';
import ShowWhenRevealed from '../show-when-revealed';

import './take-action.scss';

const SHARE_URL = 'https://big-change.org/new-education-story/';

const TakeActionBox = () => (
  <section className="section">
    <div className="take-action-box">

      <h2 className="take-action-box-title">Take action</h2>
      <p className="take-action-box-cta">Share the report and join the conversation #neweducationstory</p>
      <div className="take-action-columns">
        <div>
          <p className="take-action-box-cta">Share the report</p>
          <ShowWhenRevealed threshold={0.9}>
            <div className="take-action-box-share">
              <a
                href={`https://www.linkedin.com/cws/share?url=${SHARE_URL}`}
                target="_blank"
                rel="noreferrer"
              >
                {LinkedInIcon}

              </a>
              <a
                href={`https://twitter.com/intent/tweet?url=${SHARE_URL}`}
                target="_blank"
                rel="noreferrer"
              >
                {TwitterIcon}

              </a>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${SHARE_URL}`}
                target="_blank"
                rel="noreferrer"
              >
                {FacebookIcon}
              </a>
            </div>
          </ShowWhenRevealed>
        </div>
        <div>
          <ShowWhenRevealed threshold={0.9}>
            <p className="take-action-box-cta">Download the report summary</p>
            <a href={withPrefix('/New_Education_Story_summary_v8.pdf')}>
              {DownloadIcon}
              <br />
              A New Education Story (PDF)
            </a>
          </ShowWhenRevealed>
        </div>
        <div>
          <ShowWhenRevealed threshold={0.9}>
            <p className="take-action-box-cta">Get in touch</p>
            <a href="mailto:info@big-change.org">
              {MailIcon}
              <br />
              info@big-change.org
            </a>
          </ShowWhenRevealed>
        </div>
      </div>

    </div>
  </section>
);

export default TakeActionBox;
