import React from 'react';
import PropellerWithLabels from '../propeller-with-labels';

import './drivers-explainer.scss';

const DRIVERS = [
  {
    title: 'Purpose',
    description: `Redefine the goals and outcomes for the education system to
    reflect the challenges and opportunities of the future and the values and
    priorities of stakeholders`,
  },
  {
    title: 'Power',
    description: `Expand who has voice and agency by investing in stakeholders
    so they can make confident decisions about learning and education`,
  },
  {
    title: 'Practice',
    description: 'Unlock innovation by enabling those working in education to create and share learning innovations with the greatest potential to transform the system',
  },
];

const DriversExplainer = () => (
  <div className="drivers-explainer">
    <PropellerWithLabels />
    <div className="drivers-explainer-content">
      { DRIVERS.map(({ title, description }, index) => (
        <div className="drivers-explainer-content-item" key={title}>
          <div className="drivers-explainer-content-item-header">
            {`Driver ${index + 1}`}
          </div>
          <div className="drivers-explainer-content-item-title">
            {title}
          </div>
          <p>{description}</p>
        </div>
      )) }
    </div>
  </div>
);

export default DriversExplainer;
