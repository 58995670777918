import React from 'react';

export const DownloadIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 45 59">
    <path
      fill="currentColor"
      d="M35 13.5a4.2 4.2 0 0 1-4.2-4.2V0H3.7C1.7 0 0 1.7 0 3.7v51.6c0 2 1.7 3.7
      3.7 3.7h36.9c2 0 3.7-1.7 3.7-3.7V13.5H35ZM22 44.5l-7.6-7.7 1.8-1.7 4.6
      4.6V23.3h2.5v16.4l4.6-4.6 1.8 1.7-7.7 7.7Z"
    />
  </svg>
);

export const LinkedInIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 53 52"
  >
    <path
      fill="currentColor"
      d="M42 0H11C8.1 0 5.3 1.1 3.2 3.2c-2 2-3.2 4.7-3.2 7.6v30.4c0 2.9 1.2 5.6
      3.2 7.6S8.1 52 11 52h31c2.9 0 5.7-1.1 7.8-3.2 2-2 3.2-4.7
      3.2-7.6V10.8c0-2.9-1.2-5.6-3.2-7.6S44.9 0 42 0ZM17.7
      41.2H11V17.3h6.7v23.9Zm-3.3-26.7a3.9 3.9 0 0 1-3.5-2.3 3.7 3.7 0 0 1 .9-4
      3.8 3.8 0 0 1 4-.8c.8.2 1.4.7 1.8 1.3a3.7 3.7 0 0 1-.5 4.7c-.7.7-1.7
      1.1-2.7 1.1Zm29.9 26.7h-6.7V29c0-7.5-9-6.7-9 0V41H22V17.3h6.7v3.8c3-5.6
      15.6-6 15.6 5.6v14.5Z"
    />

  </svg>
);

export const TwitterIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 62 51"
  >
    <path
      fill="currentColor"
      d="M19.5 51a36 36 0 0 0 36.1-36.4v-1.7c2.5-1.8 4.7-4 6.4-6.5a26 26 0 0
      1-7.3 1.8c2.6-1.5 4.6-4 5.6-6.9a25.3 25.3 0 0 1-8 3 12.7 12.7 0 0 0-22.3
      9l.3 3A36.2 36.2 0 0 1 4.1 3a12.7 12.7 0 0 0 4 16.8c-2 0-4-.6-5.7-1.5a12.7
      12.7 0 0 0 10.1 12.5c-1.8.6-3.7.7-5.6.4a12.7 12.7 0 0 0 11.8 8.6A25.5 25.5
      0 0 1 0 45.2a36.2 36.2 0 0 0 19.5 5.6"
    />

  </svg>
);

export const FacebookIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 52 51"
  >
    <path
      fill="currentColor"
      d="M49.1 0H3A2.8 2.8 0 0 0 0 2.8v45.4c0 .7.3 1.5.8 2 .6.5 1.3.8 2
      .8h24.8V31.2H21v-7.5h6.8v-6c0-6.5 4-10.2 10.1-10.2l6.1.3V15h-4.2c-3.2
      0-3.8 1.5-3.8 3.8v4.8h7.6l-1 7.7h-6.8V51h13.3a2.8 2.8 0 0 0
      2.9-2.8V2.8c0-.7-.3-1.5-.8-2-.6-.5-1.3-.8-2-.8Z"
    />

  </svg>
);

export const MailIcon = (
  <svg width="63" height="38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M.7 0C.3 0 0 .3 0 .7v3.7l31.3 22.1 31.3-22V.6c0-.4-.3-.7-.7-.7H.7ZM0
         8v27.3l16.7-15.4L0 8Zm62.6 0L46 20l16.7 15.4V8ZM19.2 21.7l-17
         15.7h58.3l-17-15.7-11.3 8c-.5.3-1.2.3-1.7 0l-11.3-8Z"
      fill="currentColor"
    />
  </svg>
);

export default {
  DownloadIcon, LinkedInIcon, TwitterIcon, FacebookIcon,
};
