import React, { useState, useEffect } from 'react';
import useScrollPosition from '@react-hook/window-scroll';
import { TwitterIcon, FacebookIcon } from '../share-icons';

import './share-widget.scss';

const SHARE_URL = 'https://big-change.org/new-education-story/';

const ShareWidget = ({ text }: {text: string}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const scrollPosition = useScrollPosition(10);

  useEffect(() => setIsExpanded(false), [scrollPosition]);

  const quote = encodeURIComponent(text);
  const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${
    SHARE_URL}&quote=${quote}`;
  const twitterURL = `https://twitter.com/intent/tweet?text=${
    quote}&url=${SHARE_URL}&hashtags=neweducationstory"`;

  return (
    <div className="share-widget">
      <button
        type="button"
        className="share-widget-button"
        onClick={() => setIsExpanded(!isExpanded)}
        data-tip="Share"
      >
        <svg viewBox="0 0 45 46" className="share-widget-image">
          <path
            fill="none"
            strokeWidth="2"
            d="M44 13.3a.9.9 0 00-.4-.7L26 1.2a1 1 0 00-1 0 1 1 0 00-.5.8v3.8h-.1l-1 .4a31.9 31.9 0 00-6.3 3 33.8 33.8 0 00-9.8 9.6 36.2 36.2 0 00-5.4 12.4 36.6 36.6 0 00-.5 13 1 1 0 00.9.8 1 1 0 001-.8 31 31 0 013.5-10.8 25.5 25.5 0 016.7-7.7 20.3 20.3 0 018.1-3.8 18.3 18.3 0 013-.3v3.3a1 1 0 001.5.8L43.6 14a1 1 0 00.4-.8z"
          />
        </svg>
      </button>
      <div
        className={`share-widget-social-buttons${isExpanded ? ' is-visible' : ''}`}
      >
        <a
          className="inline-share-link"
          href={twitterURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          { TwitterIcon }

        </a>
        <a
          className="inline-share-link"
          href={facebookURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          { FacebookIcon }
        </a>
      </div>
    </div>
  );
};

export default ShareWidget;
