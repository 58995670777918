import React from 'react';

import './action.scss';

interface ActionBoxProps {
  type: 'purpose' | 'power' | 'practice'
  children: React.ReactNode
}

const ActionBox = ({ type, children }: ActionBoxProps) => (
  <div className={`action-box action-box-type-${type}`}>
    <div className="action-box-label">Action</div>
    <div className="action-box-content">{children}</div>
  </div>
);

export default ActionBox;
